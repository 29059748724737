<mat-form-field class="w-full flex-auto">
    <mat-label>{{ this.label | transloco }}</mat-label>
    <input
        matInput
        [placeholder]="this.label | transloco"
        [value]="this.value"
        readonly
        [disabled]="disabled"
        [required]="required"
    />
    <mat-icon *ngIf="!disabled" matSuffix class="cursor-pointer" (click)="openFileExplorerDialog()">
        attach_file
    </mat-icon>
    <mat-icon *ngIf="this.value && !disabled" matSuffix class="cursor-pointer" (click)="clearInput()">clear</mat-icon>
    <mat-error *ngIf="required">
        {{ "error.required" | transloco }}
    </mat-error>
</mat-form-field>

<ng-template #dialogFileExplorer>
    <div mat-dialog-title>
        <h2 class="title-text">{{ "fileExplorer.titleDialog" | transloco }}</h2>

        <button mat-icon-button (click)="dialogRef.close()">
            <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <form class="cp-form" #cpForm (ngSubmit)="upload($event)">
        <mat-dialog-content>
            <file-explorer
                [model]="this.model"
                [endpoint]="this.endpoint"
                (fileSelected)="fileSelected = $event"
            ></file-explorer>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button (click)="dialogRef.close()">
                {{ "dialog.close" | transloco }}
            </button>

            <button mat-raised-button color="primary" [disabled]="!fileSelected" type="submit">
                {{ "dialog.save" | transloco }}
            </button>
        </mat-dialog-actions>
    </form>
</ng-template>
