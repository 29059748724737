import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import momentTZ from "moment-timezone";
import { TimezoneSelector } from "../../../core/models/timezone-selector.model";

@Component({
    selector: "app-timezone-selector",
    templateUrl: "./timezone-selector.component.html",
    styleUrls: ["./timezone-selector.component.scss"],
})
export class TimezoneSelectorComponent implements OnChanges {
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() timezone: string;
    @Input() timezoneOffset: number;
    @Input() showOnlyTzExactHours: boolean;

    @Output() changeTimezone: EventEmitter<TimezoneSelector> = new EventEmitter<TimezoneSelector>();

    timezoneList: string[];
    filteredOptionsTimezoneList: string[];

    constructor() {}

    ngOnChanges(): void {
        if (!this.timezoneList) {
            this.timezoneList = this.getListTimezone();
            this.filteredOptionsTimezoneList = this.timezoneList;
        }

        //Hay que comparar el offset de esta forma porque el valor 0 es valido para buscar
        if (this.timezone != null && this.timezone != "") {
            this.searchTimezoneSelectedByName();
        } else if (this.timezoneOffset != null && this.timezoneOffset != undefined) {
            this.searchTimezoneSelectedByOffset();
        }
    }

    onKeyUp(event: KeyboardEvent): void {
        const valueSearch = event.target["value"];
        this.filteredOptionsTimezoneList = this.timezoneList.filter((tz: string) =>
            tz.toLowerCase().includes(valueSearch.toLowerCase())
        );
    }

    selectTimezone(timezone: string): void {
        const timezoneSelector = new TimezoneSelector();
        if (timezone) {
            this.filteredOptionsTimezoneList = [timezone];
            const timezoneSplit = timezone.split(")");
            const timeSplit = timezoneSplit[0].split("C")[1].split(":");
            timezoneSelector.name = timezoneSplit[1].trim();
            timezoneSelector.offset = Number(timeSplit[0]);
        }
        this.changeTimezone.emit(timezoneSelector);
    }

    private getListTimezone(): string[] {
        const moment = momentTZ;
        const timeZones = moment.tz.names();
        const timezoneListPositive: string[] = [];
        const timezoneListNegative: string[] = [];
        const timezoneList: string[] = [];

        for (let i in timeZones) {
            if (moment.tz(timeZones[i]).utcOffset() > -1) {
                timezoneListPositive.push("(UTC" + moment.tz(timeZones[i]).format("Z") + ") " + timeZones[i]);
            } else {
                timezoneListNegative.push("(UTC" + moment.tz(timeZones[i]).format("Z") + ") " + timeZones[i]);
            }
        }
        const sortByZonePositive = (a, b): number => {
            const [ahh, amm] = a.split("UTC")[1].split(")")[0].split(":");
            const [bhh, bmm] = b.split("UTC")[1].split(")")[0].split(":");
            return +ahh * 60 + amm - (+bhh * 60 + bmm);
        };

        const sortByZoneNegative = (a, b): number => {
            const [ahh, amm] = a.split("UTC")[1].split(")")[0].split(":");
            const [bhh, bmm] = b.split("UTC")[1].split(")")[0].split(":");
            return +bhh * 60 + bmm - (+ahh * 60 + amm);
        };

        timezoneListPositive.sort(sortByZonePositive).forEach(tz => {
            if (this.showOnlyTzExactHours) {
                if (tz.includes(":00")) {
                    timezoneList.push(tz);
                }
            } else {
                timezoneList.push(tz);
            }
        });

        timezoneListNegative.sort(sortByZoneNegative).forEach(tz => {
            if (this.showOnlyTzExactHours) {
                if (tz.includes(":00")) {
                    timezoneList.push(tz);
                }
            } else {
                timezoneList.push(tz);
            }
        });

        return timezoneList;
    }

    private searchTimezoneSelectedByName(): void {
        const list = this.timezoneList.filter((tz: string) => tz.toLowerCase().includes(this.timezone.toLowerCase()));
        if (list.length > 0) {
            this.timezone = list[0];
        } else {
            this.timezone = "";
        }
    }

    private searchTimezoneSelectedByOffset(): void {
        let list = this.timezoneList
            .filter((tz: string) => tz.toLowerCase().startsWith(this.formatOffset().toLowerCase()))
            .filter((tz: string) => tz.includes("Etc/GMT"));

        if (list.length > 0) {
            this.timezone = list[0];
        } else {
            this.timezone = "";
        }
    }

    private formatOffset(): string {
        let strFormat;
        if (this.timezoneOffset < 0 && this.timezoneOffset > -10) {
            strFormat = "(UTC-0" + this.timezoneOffset.toString().slice(1);
        } else if (this.timezoneOffset <= -10) {
            strFormat = "(UTC" + this.timezoneOffset.toString();
        } else if (this.timezoneOffset >= 0 && this.timezoneOffset < 10) {
            strFormat = "(UTC+0" + this.timezoneOffset.toString();
        } else {
            strFormat = "(UTC+" + this.timezoneOffset.toString();
        }
        return strFormat;
    }
}
