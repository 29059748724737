import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config";
import { environment } from "environments/environment";
import { cloneDeep, merge } from "lodash";
import { NGXLogger, NgxLoggerLevel } from "ngx-logger";
import { firstValueFrom } from "rxjs";
import { IcosaedroConfig } from "../models/icosaedro-config.model";
import { appConfig } from "./app.config";

@Injectable({
    providedIn: "root",
})
export class ConfigurationService {
    private readonly MAX_SESSION_TIMEOUT_SECS = 120;
    private config!: IcosaedroConfig;

    constructor(
        private httpClient: HttpClient,
        private fuseConfigService: FuseConfigService,
        private logger: NGXLogger
    ) {}

    public load(): Promise<IcosaedroConfig> {
        const jsonFile = environment.configFile;

        return new Promise((resolve, reject) => {
            firstValueFrom(this.httpClient.get<IcosaedroConfig>(jsonFile))
                .then((configuration: IcosaedroConfig) => {
                    this.config = this.adaptConfiguration(configuration);

                    this.initLogger(this.getLogLevelByKey(this.config.logLevel ?? ""));

                    this.configureFuse();

                    resolve(this.config);
                })
                .catch((error: any) => {
                    console.error("Cannot load configuration", error);
                    reject(error);
                });
        });
    }

    public getConfig(): IcosaedroConfig {
        return cloneDeep(this.config);
    }

    private adaptConfiguration(configuration: IcosaedroConfig): IcosaedroConfig {
        const config = cloneDeep(configuration);
        config.langDefault = config.langDefault ?? "en";
        config.showSettings = config.showSettings ?? false;
        config.features = new Map<string, boolean>();

        for (const key in configuration.features) {
            const value = configuration.features[key];
            config.features.set(key, value);
        }

        config.maxSessionTimeoutSeconds = configuration.maxSessionTimeoutSeconds ?? this.MAX_SESSION_TIMEOUT_SECS;

        if (config.maxSessionTimeoutSeconds < 0) {
            config.maxSessionTimeoutSeconds = this.MAX_SESSION_TIMEOUT_SECS;
        }

        return config;
    }

    /**
     * FIXME: cambiar switch por algo mas generico
     *
     * @param value
     * @returns
     */
    private getLogLevelByKey(value: string): NgxLoggerLevel {
        let level: NgxLoggerLevel;

        switch (value.toUpperCase()) {
            case "TRACE":
                level = NgxLoggerLevel.TRACE;
                break;
            case "DEBUG":
                level = NgxLoggerLevel.DEBUG;
                break;
            case "INFO":
                level = NgxLoggerLevel.INFO;
                break;
            case "LOG":
                level = NgxLoggerLevel.LOG;
                break;
            case "WARN":
                level = NgxLoggerLevel.WARN;
                break;
            case "ERROR":
                level = NgxLoggerLevel.ERROR;
                break;
            case "FATAL":
                level = NgxLoggerLevel.FATAL;
                break;
            case "OFF":
                level = NgxLoggerLevel.OFF;
                break;

            default:
                level = NgxLoggerLevel.INFO;
        }

        return level;
    }

    private initLogger(level: NgxLoggerLevel): void {
        const config = this.logger.getConfigSnapshot();
        config.level = level;

        this.logger.updateConfig(config);

        this.logger.log(`Logger configured with level ${level} ${NgxLoggerLevel[level]}`);
    }

    private configureFuse(): void {
        if (this.config.fuse) {
            //this.fuseConfigService.config = {...appConfig, ...this.config.fuse}; //merge properties with spread operator
            this.fuseConfigService.config = merge({}, appConfig, this.config.fuse);
        } else {
            this.fuseConfigService.config = appConfig;
        }
    }
}
