<div class="flex flex-col flex-auto p-6 md:p-8">
    <!-- Close button -->
    <div class="flex items-center justify-end">
        <button mat-icon-button type="button" (click)="sendOperation(detailOperations.CLOSE)">
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Preview -->
    <div class="aspect-w-9 aspect-h-6 mt-8">
        <div class="flex items-center justify-center border rounded-lg bg-gray-50 dark:bg-card">
            <ng-container *ngIf="file.folder">
                <mat-icon class="icon-size-14 text-hint" [svgIcon]="'heroicons_outline:folder'"></mat-icon>
            </ng-container>
            <ng-container *ngIf="!file.folder">
                <mat-icon
                    *ngIf="!isMultimediaFile()"
                    class="icon-size-14 text-hint"
                    [svgIcon]="'heroicons_outline:document'"
                ></mat-icon>
                <img class="icon-size-20" *ngIf="isMultimediaFile()" src="{{ image }}" />
            </ng-container>
        </div>
    </div>

    <!-- Name & Type -->
    <div class="flex flex-col items-start mt-8">
        <div class="text-xl font-medium">{{ file.name }}</div>
        <div
            class="mt-1 px-1.5 rounded text-sm font-semibold leading-5 text-white"
            [class.bg-indigo-600]="file.folder"
            [class.bg-red-600]="file.mimeType?.toLowerCase().includes(fileTypes.pdf)"
            [class.bg-blue-600]="file.mimeType?.toLowerCase().includes(fileTypes.doc)"
            [class.bg-green-600]="file.mimeType?.toLowerCase().includes(fileTypes.xls)"
            [class.bg-gray-600]="file.mimeType?.toLowerCase().includes(fileTypes.txt)"
            [class.bg-amber-600]="file.mimeType?.toLowerCase().includes(fileTypes.image)"
        >
            {{
                file.mimeType
                    ? file.mimeType.slice(file.mimeType.indexOf("/") + 1).toUpperCase()
                    : ("fileExplorer.typeFolder" | transloco)
            }}
        </div>
    </div>

    <!-- Name -->
    <div class="flex items-center justify-between mt-8">
        <div class="text-lg font-medium">{{ "fileExplorer.nameFile" | transloco }}</div>
    </div>
    <div class="w-full flex-auto mt-2 border-t">
        <div class="py-3">
            <ng-container *ngIf="file.name">
                <div class="w-full flex-auto inline-flex">
                    <input matInput #inputName [disabled]="!updateName" [value]="getNameWithoutExt(file)" />
                    <mat-icon
                        *ngIf="!updateName"
                        matSuffix
                        class="icon-size-5"
                        type="button"
                        [matTooltip]="'common.edit' | transloco"
                        [svgIcon]="'heroicons_solid:pencil'"
                        (click)="updateName = true"
                    ></mat-icon>
                    <mat-icon
                        *ngIf="updateName"
                        matSuffix
                        class="icon-size-5"
                        type="button"
                        [matTooltip]="'module.save' | transloco"
                        (click)="
                            updateName = false;
                            fileElement.name = inputName.value + fileExtension;
                            fileElement.name !== file.name ? sendOperation(detailOperations.UPDATE) : null
                        "
                    >
                        save
                    </mat-icon>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- Information of path -->
    <div class="text-lg font-medium mt-8">{{ "common.actions" | transloco }}</div>
    <div class="flex flex-col mt-4 border-t border-b divide-y font-medium">
        <div class="flex items-center justify-between py-3">
            <div class="text-secondary flex-auto">{{ "fileExplorer.path" | transloco }}</div>
            <div class="truncate flex-auto mx-1">{{ "fileExplorer.home" | transloco }}{{ file.relativePath }}</div>
            <button
                *ngIf="!isMenuEmpty()"
                mat-icon-button
                type="button"
                aria-label="actions"
                [matMenuTriggerFor]="actionsMenu"
            >
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:dots-horizontal'"></mat-icon>
            </button>
        </div>
    </div>

    <!-- Actions -->
    <div class="grid grid-cols-2 gap-4 w-full mt-8">
        <button mat-flat-button type="button" [color]="'primary'" (click)="sendOperation(detailOperations.DOWNLOAD)">
            <mat-icon class="hidden xs:block" [svgIcon]="'heroicons_outline:download'"></mat-icon>
            <span class="ml-2 mr-1">{{ "fileExplorer.actions.download" | transloco }}</span>
        </button>
        <button mat-flat-button type="button" [color]="'warn'" (click)="sendOperation(detailOperations.DELETE)">
            <mat-icon class="hidden xs:block" [svgIcon]="'heroicons_outline:trash'"></mat-icon>
            <span class="ml-2 mr-1">{{ "common.delete" | transloco }}</span>
        </button>
    </div>
</div>

<mat-menu #actionsMenu="matMenu" [overlapTrigger]="false" class="mat-primary actions-column-toolbar">
    <ng-template matMenuContent>
        <button
            *ngIf="!file.folder"
            class="flex-auto"
            mat-menu-item
            type="button"
            (click)="sendOperation(detailOperations.COPYLINK)"
        >
            <mat-icon>link</mat-icon>
            <span>{{ "fileExplorer.actions.copyLink" | transloco }}</span>
        </button>
        <button
            *ngIf="file.relativePath.length > 1"
            class="flex-auto"
            mat-menu-item
            type="button"
            (click)="sendOperation(detailOperations.MOVETOPARENT)"
        >
            <mat-icon>arrow_upward</mat-icon>
            <span>{{ "fileExplorer.actions.moveToParent" | transloco }}</span>
        </button>
        <button
            *ngIf="folders.length > 1 || (folders.length === 1 && !file.folder)"
            mat-menu-item
            aria-label="actions"
            type="button"
            [matMenuTriggerFor]="foldersMenu"
        >
            <mat-icon class="icon-size-5">drive_folder_upload</mat-icon>
            <span>{{ "fileExplorer.actions.moveToFolder" | transloco }}</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #foldersMenu="matMenu" class="mat-primary actions-column-toolbar">
    <ng-container matMenuContent *ngFor="let folder of folders">
        <button
            *ngIf="folder.name !== file.name"
            class="flex-auto"
            mat-menu-item
            type="button"
            (click)="fileElement = folder; sendOperation(detailOperations.MOVETOFOLDER)"
        >
            <mat-icon [svgIcon]="'heroicons_outline:folder'"></mat-icon>
            <span>{{ folder.name }}</span>
        </button>
    </ng-container>
</mat-menu>
