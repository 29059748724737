import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Brand } from "app/core/models/brand.model";
import { Company } from "app/core/models/company.model";
import { Hotel } from "app/core/models/hotel.model";
import { InstanceActiveService } from "app/core/services/instance-active.service";
import { LoggerService } from "app/core/services/logger.service";
import { UserDetailsService } from "app/core/services/user-details.service";
import { take } from "rxjs";
import { MatSelectFilterComponent } from "mat-select-filter";

@UntilDestroy()
@Component({
    selector: "instance-selector",
    templateUrl: "./instance-selector.component.html",
    styleUrls: ["./instance-selector.component.scss"] /*,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush*/,
})
export class InstanceSelectorComponent implements OnInit {
    @ViewChild("matSelectFilterHotel") matSelectFilterHotel: MatSelectFilterComponent;
    companies: Array<Company> = [];
    hotels: Array<Hotel> = [];
    hotelsFiltered: Array<Hotel> = [];
    brands: Array<Brand> = [];

    selectedCompany: Company;
    selectedHotel: Hotel;
    selectedBrand: Brand;

    /**
     * Constructor
     */
    constructor(
        private _userDetailsService: UserDetailsService,
        private _instanceActiveService: InstanceActiveService,
        private _router: Router,
        private _logger: LoggerService
    ) { }

    ngOnInit(): void {
        this.hotels.length = 0;
        this.brands.length = 0;

        this._instanceActiveService
            .findCompanies()
            .pipe(untilDestroyed(this), take(1))
            .subscribe(companies => {
                this.companies = companies;

                const instanceSelector = this._instanceActiveService.currentInstanceSelector;

                if (instanceSelector) {
                    //this._logger.debug("[InstanceSelectorComponent] Recibido selector", instanceSelector);

                    if (instanceSelector.company) {
                        this.selectedCompany = instanceSelector.company;
                        this.onCompanyChange(this.selectedCompany, false);
                    }

                    if (instanceSelector.brand) {
                        this.selectedBrand = instanceSelector.brand;
                        this.onBrandChange(this.selectedBrand, false);
                    }

                    if (instanceSelector.hotel) {
                        this.selectedHotel = instanceSelector.hotel;
                        this.onHotelChange(this.selectedHotel, false);
                    }
                }
            });
    }

    onCompanyChange(company: Company, saveContext: boolean = true): void {
        this.selectedCompany = company;
        this.hotels.length = 0;
        this.brands.length = 0;
        this.selectedBrand = null;
        this.selectedHotel = null;
        this.resetFilterHotel();

        this._instanceActiveService
            .findBrandsByIdCompany(company.id)
            .pipe(untilDestroyed(this), take(1))
            .subscribe(data => {
                this.brands = data;
            });

        if (saveContext) {
            this._instanceActiveService.currentCompany = company;
        }
    }

    onBrandChange(brand: Brand, saveContext: boolean = true): void {
        this.selectedBrand = brand;
        this.hotels.length = 0;
        this.selectedHotel = null;
        this.resetFilterHotel();

        if (brand) {
            this._instanceActiveService
                .findHotelsByIdBrand(brand.id)
                .pipe(untilDestroyed(this), take(1))
                .subscribe(data => {
                    this.hotels = data;
                    this.hotelsFiltered = data;
                });
        }

        if (saveContext) {
            this._instanceActiveService.currentBrand = brand;
        }
    }

    onHotelChange(hotel: Hotel, saveContext: boolean = true): void {
        this.selectedHotel = hotel;

        if (saveContext) {
            this._instanceActiveService.currentHotel = hotel;
        }
    }

    disableCompaniesSelect(): boolean {
        return !this._userDetailsService.hasAnyPermission("ROLE_NOC");
    }

    disableBrandsSelect(): boolean {
        return !(
            this._userDetailsService.hasAnyPermission("ROLE_ADC", "ROLE_ADB", "ROLE_NOC") || this.brands.length > 1
        );
    }

    disableHotelsSelect(): boolean {
        return !(
            (
                this._userDetailsService.hasAnyPermission("ROLE_ADC", "ROLE_ADB", "ROLE_NOC", "ROLE_RST", "ROLE_HSP") ||
                this.hotels.length > 1
            ) /*(this.userDetailSessionService.userDetailsDto && this.userDetailSessionService.hotels.length > 1)*/
        );
    }

    compareEntities(e1: any, e2: any): boolean {
        if ((!e1 && e2) || (e1 && !e2)) {
            return false;
        }

        return e1.id === e2.id;
    }

    resetFilterHotel(): void {
        this.matSelectFilterHotel.array = [];
        this.matSelectFilterHotel.filteredItems = [];
        this.matSelectFilterHotel.noResults = false;

        if (this.matSelectFilterHotel.input?.nativeElement) {
            this.matSelectFilterHotel.input.nativeElement.value = "";
        }
    }

    openSelectHotel(): void {
        this.matSelectFilterHotel.input?.nativeElement?.focus();
    }
}
