import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { FuseConfirmationConfig, FuseConfirmationService } from "@fuse/services/confirmation";
import { FuseConfirmationDialogComponent } from "@fuse/services/confirmation/dialog/dialog.component";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({
    providedIn: "root",
})
export class DialogService {
    constructor(
        private _confirmationService: FuseConfirmationService,
        private _translocoService: TranslocoService,
        private _dialog: MatDialog
    ) {}

    openSimpleCancellableDialog(
        message: string,
        title?: string,
        icon?: {
            name?: string;
            color?: "primary" | "accent" | "warn" | "basic" | "info" | "success" | "warning" | "error";
        },
        confirmColor: "primary" | "accent" | "warn" = "primary"
    ): MatDialogRef<FuseConfirmationDialogComponent, any> {
        const config: FuseConfirmationConfig = {
            title: title,
            message: message,
            icon: {
                show: !!icon,
                name: icon?.name,
                color: icon?.color,
            },
            actions: {
                confirm: {
                    show: true,
                    label: this._translocoService.translate("dialog.confirm"),
                    color: confirmColor,
                },
                cancel: {
                    show: true,
                    label: this._translocoService.translate("dialog.cancel"),
                },
            },
            dismissible: false,
        };

        return this._confirmationService.open(config);
    }

    openSimpleCancellableDialogInfo(
        message: string,
        title?: string
    ): MatDialogRef<FuseConfirmationDialogComponent, any> {
        return this.openSimpleCancellableDialog(message, title, {
            name: "heroicons_outline:information-circle",
            color: "info",
        });
    }

    openSimpleCancellableDialogWarning(
        message: string,
        title?: string
    ): MatDialogRef<FuseConfirmationDialogComponent, any> {
        return this.openSimpleCancellableDialog(
            message,
            title,
            {
                name: "heroicons_outline:exclamation",
                color: "warn",
            },
            "warn"
        );
    }

    openComponentDialog<T, D = any, R = any>(
        component: ComponentType<T>,
        config?: MatDialogConfig<D>
    ): MatDialogRef<T, R> {
        config = config || {};

        config.panelClass = "flat-dialog-container";

        return this._dialog.open(component, config);
    }
}
