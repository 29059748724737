import { NgModule } from "@angular/core";
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OwlDateTimeIntl, OwlDateTimeModule } from "ng-pick-datetime-ex";
import { CustomDateTimeAdapter, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from "./custom-datetime-adapter";
import { DateTimeIntl } from "./datetime-i18n";

const CUSTOM_DATE_TIME_FORMATS = {
    parseInput: "LL LT",
    fullPickerInput: "DD/MM/YYYY HH:mm:ss",
    datePickerInput: "DD/MM/YYYY",
    timePickerInput: "LT",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MM YYYY",
};

@NgModule({
    providers: [
        // use french locale
        //{ provide: OWL_DATE_TIME_LOCALE, useValue: "es" },
        { provide: OwlDateTimeIntl, useClass: DateTimeIntl },
        { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: false } },
        { provide: DateTimeAdapter, useClass: CustomDateTimeAdapter },
        { provide: OWL_DATE_TIME_FORMATS, useValue: CUSTOM_DATE_TIME_FORMATS },
    ],
    imports: [OwlDateTimeModule],
    exports: [OwlDateTimeModule],
})
export class DateTimeModule {}
