export const enum RouteConstants {
    LANDING_HOME = "/home",
    MODULE_HOME = "/module/home",
    MODULE_HOME_NOC = "/module/home-noc",
    MODULE_HOTSPOT = "/module/hotspot",
    ROUTE_HOTSPOT = "hotspot",
    MODULE_ROOM_XPERIENCE = "/module/room-xperience",
    ROUTE_ROOM_XPERIENCE = "room-xperience",
    MODULE_SRVMGM = "/module/srvmgm",
    ROUTE_SRVMGM = "srvmgm",
    MODULE_INTERACTIVE_TV = "/module/interactive-tv",
    ROUTE_INTERACTIVE_TV = "interactive-tv",
    MODULE_CAST = "/module/cast",
    ROUTE_CAST = "cast",
    MODULE_NETFLOW = "/module/netflow",
    ROUTE_NETFLOW = "netflow",
    ROUTE_BOOKING = "booking",

    //SPECIAL PAGES
    ROUTE_MODULE = "module",
    ROUTE_HOTEL_STRUCTURE = "hotel-structure",
    ROUTE_ANALYTICS = "analytics",
    ROUTE_CONFIG_EMAIL_TEMPLATE = "email-template",
    ROUTE_CONFIG_CURRENCY = "currency",
    ROUTE_CONFIG_FAQ = "faq",
    ROUTE_CONFIG_MANUAL = "manual",
    ROUTE_CONFIG_PMS = "pms",
    ROUTE_CONFIG_UPDATER = "updater",
    ROUTE_CONFIG_UTILS = "utils",
}

export enum WelcomeRouteConstants {
    COMPANY = "/welcome/company",
    BRAND = "/welcome/brand",
    HOTEL = "/welcome/hotel",
    NOC = "/module/home-noc",
    CARD = "/module/hotspot/card",
    ANALYTIC_TRAFFIC = "/analytics/hotspot/traffic-bi",
    ANALYTIC_STAT_SALES = "/analytics/hotspot/stat-sales",
    ROOM_STATUS = "/module/room-xperience/room-status",
    ROOM_STATUS_MQTT = "/module/room-xperience/room-status-mqtt",
    ROOM_DND_MUR = "/module/room-xperience/room-service",
}
