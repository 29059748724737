import { Brand } from "./brand.model";
import { Company } from "./company.model";
import { Hotel } from "./hotel.model";

export class CurrentInstanceSelection {
    private _company: Company;

    private _brand: Brand;

    private _hotel: Hotel;

    set company(value: Company) {
        this._company = value;
    }

    get company(): Company {
        return this._company;
    }

    set brand(value: Brand) {
        this._brand = value;
    }

    get brand(): Brand {
        return this._brand;
    }

    set hotel(value: Hotel) {
        this._hotel = value;
    }

    get hotel(): Hotel {
        return this._hotel;
    }
}
