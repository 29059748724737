import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route } from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthService } from "./core/services/auth.service";
import { UserDetailsService } from "./core/services/user-details.service";

/**
 * Esto sustituye a canLoad del app.routing, si se cambia la estrategia, habria que cambiar el app.routing y añadir el canLoad.
 * Se debe especificar el canLoad para los hijos que contengan un loadChildren.
 */
@Injectable({
    providedIn: "root",
})
export class RoleBasedPreloader implements PreloadingStrategy {
    constructor(private userDetailsService: UserDetailsService, private authService: AuthService) {}

    preload(route: Route, fn: () => Observable<any>): Observable<any> {
        const path = route.data?.fullPath ? route.data.fullPath : route.path;
        if (path) {
            const permissions = this.authService.getPermissionsForUrl(path);
            if (permissions.length && this.userDetailsService.hasAnyPermission(...permissions)) {
                return fn();
            }

            return of(null); //ignore loading
        }

        return fn();
    }
}
