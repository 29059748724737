<div class="flex flex-col p-4 items-center">
    <mat-label class="text-xl">{{ title | transloco }}</mat-label>
    <div [matTooltipDisabled]="enabledChecked" matTooltip="{{ tooltipDisabled | transloco }}" class="my-4">
        <button
            class="rounded-md w-32 h-32"
            routerLink="{{ routerLinkUrl }}"
            [ngClass]="{ 'bg-primary': enabledChecked, 'bg-accent-200': !enabledChecked }"
            matTooltip="{{ tooltip | transloco }}"
            [disabled]="!enabledChecked"
        >
            <mat-icon
                [inline]="true"
                class="text-7xl"
                [ngClass]="{ 'text-white': enabledChecked, 'text-gray-400': !enabledChecked }"
            >
                {{ icon }}
            </mat-icon>
        </button>
    </div>
    <ng-container *ngIf="showToggle">
        <mat-label class="mt-2">{{ "module.module.active" | transloco }}</mat-label>
        <mat-slide-toggle
            #slideToggle
            class="mat-primary"
            (change)="onChangeToggle($event)"
            [checked]="enabledChecked"
        ></mat-slide-toggle>
    </ng-container>
</div>
