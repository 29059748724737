import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "app/core/config/configuration.service";
import { catchError, Observable, throwError } from "rxjs";
import { HttpClientGrafanaApi } from "../http/http-client-grafana-api";
import { LoggerService } from "./logger.service";

@Injectable({
    providedIn: "root",
})
export class GrafanaService {
    constructor(
        private httpClient: HttpClient,
        private httpClientGrafanaApi: HttpClientGrafanaApi,
        private configService: ConfigurationService,
        private logger: LoggerService
    ) {}

    logout(): Observable<any> {
        //https://keycloak-server/auth/realms/{realm-name}/protocol/openid-connect/logout

        const grafanaConfig = this.configService.getConfig().grafana;

        return this.httpClient.get(grafanaConfig.url + "/logout").pipe(
            catchError(err => {
                this.logger.warn("No se pudo realizar el logout de grafana", err);
                return throwError(() => err);
            })
        );
    }

    getDashboardByUid(uid: string): Observable<any> {
        const grafanaConfig = this.configService.getConfig().grafana;

        return this.httpClientGrafanaApi.get(grafanaConfig.url + "/api/dashboards/uid/" + uid);
    }
}
