import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, ReplaySubject, Subject, take } from "rxjs";
import { ConfigurationService } from "../config/configuration.service";
import { HttpClientMaster } from "../http/http-client-master";
import { Brand } from "../models/brand.model";
import { Company } from "../models/company.model";
import { CurrentInstanceSelection } from "../models/current-instance-selection.model";
import { Hotel } from "../models/hotel.model";
import { InstanceActive } from "../models/instance-active.model";
import { ResponseForm } from "../models/response-form.model";
import { UriUtils } from "../utils/uri.utils";
import { LoggerService } from "./logger.service";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: "root",
})
export class InstanceActiveService {
    private currentInstanceActive: InstanceActive;

    /**
     * _instanceActive se utiliza cuando necesitas estar subscrito a los cambios y ademas conocer instantaneamente el valor actual de la instancia activa
     *
     * @private
     */
    private _instanceActive: ReplaySubject<InstanceActive> = new ReplaySubject<InstanceActive>(1);

    /**
     * _instanceActiveChanges se utiliza cuando necesitas estar subscrito a los cambios y no conocer el valor actual de la instancia activa
     *
     * @private
     */
    private _instanceActiveChanges: Subject<InstanceActive> = new Subject<InstanceActive>();

    private _currentInstanceSelection: BehaviorSubject<CurrentInstanceSelection> =
        new BehaviorSubject<CurrentInstanceSelection>(null);

    constructor(
        private _httpClient: HttpClientMaster,
        private _configService: ConfigurationService,
        private _storageService: StorageService,
        private _logger: LoggerService
    ) {
        this.currentInstanceSelector$.subscribe(is => {
            if (is) {
                this.loadInstanceActive();
            }
        });
    }

    findCompanies(): Observable<Company[]> {
        return this._httpClient
            .get<ResponseForm<Company[]>>(this._configService.getConfig().rootUrl + "/instanceActive/getCompanies")
            .pipe(map(responseForm => responseForm.data));
    }

    findBrandsByIdCompany(idCompany: number): Observable<Brand[]> {
        let url = new URL(this._configService.getConfig().rootUrl + "/instanceActive/getBrands");
        url = UriUtils.appendParameter(url, "idCompany", idCompany.toString());

        return this._httpClient.get<ResponseForm<Brand[]>>(url.toString()).pipe(map(responseForm => responseForm.data));
    }

    findHotelsByIdBrand(idBrand: number): Observable<Hotel[]> {
        let url = new URL(this._configService.getConfig().rootUrl + "/instanceActive/getHotels");
        url = UriUtils.appendParameter(url, "idBrand", idBrand.toString());

        return this._httpClient.get<ResponseForm<Hotel[]>>(url.toString()).pipe(map(responseForm => responseForm.data));
    }

    loadInstanceFromStorage(removeAfterLoad: boolean = true): InstanceActive {
        const instanceAct = this._storageService.getInstanceActive();

        if (instanceAct) {
            this.instanceActive = instanceAct;
        }

        if (removeAfterLoad) {
            this._storageService.removeInstanceActive();
        }

        return instanceAct;
    }

    loadCurrentSelectionFromStorage(removeAfterLoad: boolean = true): void {
        const currSel = this._storageService.getCurrentInstanceSelection();

        if (currSel) {
            this._currentInstanceSelection.next(currSel);
        }

        if (removeAfterLoad) {
            this._storageService.removeCurrentInstanceSelection();
        }
    }

    persistCurrentSelection(): void {
        const currSel = this._currentInstanceSelection.getValue();

        if (currSel) {
            this._storageService.saveCurrentInstanceSelection(currSel);
        }
    }

    clear(): void {
        this._logger.debug("[InstanceActiveService] Clearing context");
        this._storageService.removeInstanceActive();
        this._storageService.removeCurrentInstanceSelection();

        this.instanceActive = null;
        this._currentInstanceSelection.next(null);
    }

    loadInstanceActive(): void {
        this._logger.debug("[InstanceActiveService] Loading active instance");

        let url = new URL(this._configService.getConfig().rootUrl + "/instanceActive/getInstanceActive");

        const currentInsSel = this._currentInstanceSelection.getValue();

        if (currentInsSel) {
            if (currentInsSel.company) {
                url = UriUtils.appendParameter(url, "idCompany", currentInsSel.company.id.toString());
            }

            if (currentInsSel.brand) {
                url = UriUtils.appendParameter(url, "idBrand", currentInsSel.brand.id.toString());
            }

            if (currentInsSel.hotel) {
                url = UriUtils.appendParameter(url, "idHotel", currentInsSel.hotel.id.toString());
            }
        }

        this._httpClient
            .get<ResponseForm<InstanceActive>>(url.toString())
            .pipe(
                map(responseForm => responseForm.data),
                take(1)
            )
            .subscribe((instanceActive: InstanceActive) => {
                this.instanceActive = instanceActive;
            });
    }

    setInstanceFromUser(instanceContext: any): void {
        let url = new URL(this._configService.getConfig().rootUrl + "/instanceActive/getInstanceActive");

        let idCompany = null;
        let idBrand = null;
        let idHotel = null;

        if (Object.keys(instanceContext).length === 1) {
            idCompany = Number(Object.keys(instanceContext)[0]); // Get first company

            idBrand = Number(Object.keys(instanceContext[idCompany])[0]); // Get first brand of first company

            idHotel = Number(instanceContext[idCompany][idBrand][0]); // Get first hotel of first brand of first company

            url = UriUtils.appendParameters(url, [
                { name: "idCompany", value: idCompany },
                { name: "idBrand", value: idBrand },
                { name: "idHotel", value: idHotel },
            ]);

            this._httpClient
                .get<ResponseForm<InstanceActive>>(url.toString())
                .pipe(
                    map(responseForm => responseForm.data),
                    take(1)
                )
                .subscribe(instance => {
                    if (!this.instanceActive) {
                        this.currentInstanceActive = instance;

                        const currentInsSel =
                            this._currentInstanceSelection.getValue() ?? new CurrentInstanceSelection();

                        if (instance && currentInsSel) {
                            currentInsSel.company = instance.companyActive;
                            currentInsSel.brand = instance.brandActive;
                            currentInsSel.hotel = instance.hotelActive;

                            this._currentInstanceSelection.next(currentInsSel);
                        }
                    }
                });
        }
    }

    /**
     * Observable para subscribirse a los cambios de la instancia activa.
     * Hay que tener en cuenta que esta subscripción no devuelve la instancia activa actual.
     * Para obtener la instancia activa actual y subscribirse además a los cambios, usar {@link instanceActive$}
     * @returns {Observable<InstanceActive>} instancia activa
     */
    get instanceActiveChanges$(): Observable<InstanceActive> {
        return this._instanceActiveChanges.asObservable();
    }

    /**
     * Observable para subscribirse a los cambios de la instancia activa.
     * Hay que tener en cuenta que esta subscripción devuelve la instancia activa actual tras subscribirse.
     * Si solo se desean observar los cambios, usar {@link instanceActiveChanges$}
     * @returns {Observable<InstanceActive>} instancia activa
     */
    get instanceActive$(): Observable<InstanceActive> {
        return this._instanceActive.asObservable();
    }

    /**
     * Obtiene la instancia activa.
     * Puede ser nula.
     * @returns {InstanceActive} instancia activa
     */
    get instanceActive(): InstanceActive {
        return this.currentInstanceActive;
    }

    set instanceActive(value: InstanceActive) {
        this.currentInstanceActive = value;
        this._instanceActive.next(value);
        this._instanceActiveChanges.next(value);
    }

    set currentCompany(value: Company) {
        const cis = this._currentInstanceSelection.getValue() ?? new CurrentInstanceSelection();
        cis.company = value;
        cis.brand = null;
        cis.hotel = null;
        this._currentInstanceSelection.next(cis);
    }

    set currentBrand(value: Brand) {
        const cis = this._currentInstanceSelection.getValue() ?? new CurrentInstanceSelection();
        cis.brand = value;
        cis.hotel = null;
        this._currentInstanceSelection.next(cis);
    }

    set currentHotel(value: Hotel) {
        const cis = this._currentInstanceSelection.getValue() ?? new CurrentInstanceSelection();
        cis.hotel = value;
        this._currentInstanceSelection.next(cis);
    }

    get currentInstanceSelector$(): Observable<CurrentInstanceSelection> {
        return this._currentInstanceSelection.asObservable();
    }

    get currentInstanceSelector(): CurrentInstanceSelection {
        return this._currentInstanceSelection.getValue();
    }
}
