<div class="flex justify-center">
    <div class="hidden lg:flex instance-selector-container mt-5">
        <mat-form-field class="flex-auto pr-6 selector">
            <mat-select
                [placeholder]="'common.company' | transloco"
                (selectionChange)="onCompanyChange($event.value)"
                [compareWith]="compareEntities"
                [value]="selectedCompany"
                panelClass="instance-selector"
                [disabled]="disableCompaniesSelect()"
                matTooltip="{{ selectedCompany?.name }}"
                matTooltipPosition="below"
                [matTooltipDisabled]="!selectedCompany"
            >
                <mat-option *ngFor="let company of companies" [value]="company">
                    {{ company.name }}
                </mat-option>
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'business_center'"></mat-icon>
        </mat-form-field>

        <mat-form-field class="flex-auto pr-6 selector">
            <mat-select
                [placeholder]="'common.brand' | transloco"
                [compareWith]="compareEntities"
                [disabled]="disableBrandsSelect()"
                (selectionChange)="onBrandChange($event.value)"
                [value]="selectedBrand"
                panelClass="instance-selector"
                matTooltip="{{ selectedBrand?.name }}"
                matTooltipPosition="below"
                [matTooltipDisabled]="!selectedBrand"
            >
                <mat-option *hasAnyPermission="['BRAND_LIST', 'ROLE_NOC']" [value]="null">
                    {{ "toolbar.brand" | transloco }}
                </mat-option>

                <mat-option *ngFor="let brand of brands" [value]="brand">
                    {{ brand.name }}
                </mat-option>
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'layers'"></mat-icon>
        </mat-form-field>

        <mat-form-field class="flex-auto selector">
            <mat-select
                [placeholder]="'common.hotel' | transloco"
                [compareWith]="compareEntities"
                [disabled]="disableHotelsSelect()"
                (selectionChange)="onHotelChange($event.value)"
                [value]="selectedHotel"
                panelClass="instance-selector"
                matTooltip="{{ selectedHotel?.name }}"
                matTooltipPosition="below"
                [matTooltipDisabled]="!selectedHotel"
                (opened)="openSelectHotel()"
            >
                <mat-select-filter
                    [array]="hotels"
                    [displayMember]="'name'"
                    #matSelectFilterHotel
                    (filteredReturn)="hotelsFiltered = $event"
                    [noResultsMessage]="'common.emptyOption' | transloco"
                ></mat-select-filter>
                <mat-option *hasAnyPermission="['HOTEL_LIST', 'ROLE_NOC']" [value]="null">
                    {{ "toolbar.hotel" | transloco }}
                </mat-option>

                <mat-option *ngFor="let hotel of hotelsFiltered" [value]="hotel">
                    {{ hotel.name }}
                </mat-option>
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'domain'"></mat-icon>
        </mat-form-field>
    </div>

    <!--Buttons for small screens-->
    <div class="flex lg:hidden">
        <button mat-icon-button aria-label="Companies" class="flex-auto mr-6" [matMenuTriggerFor]="companyMenu">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'business_center'"></mat-icon>
        </button>
        <button
            mat-icon-button
            aria-label="Brands"
            class="flex-auto mr-6"
            [disabled]="brands.length === 0"
            [matMenuTriggerFor]="brandMenu"
        >
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'layers'"></mat-icon>
        </button>
        <button
            mat-icon-button
            aria-label="Hotels"
            class="flex-auto mr-6"
            [disabled]="hotels.length === 0"
            [matMenuTriggerFor]="hotelMenu"
        >
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'domain'"></mat-icon>
        </button>
    </div>

    <mat-menu
        #companyMenu="matMenu"
        [overlapTrigger]="true"
        class="select-menu-toolbar mat-primary"
        xPosition="after"
        yPosition="below"
    >
        <button
            mat-menu-item
            *ngFor="let company of companies"
            (click)="onCompanyChange(company)"
            class="mat-option"
            [ngClass]="{ 'mat-selected': compareEntities(selectedCompany, company) }"
        >
            {{ company.name }}
        </button>
    </mat-menu>

    <mat-menu
        #brandMenu="matMenu"
        [overlapTrigger]="true"
        class="select-menu-toolbar mat-primary"
        xPosition="after"
        yPosition="below"
    >
        <button
            mat-menu-item
            *ngFor="let brand of brands"
            (click)="onBrandChange(brand)"
            class="mat-option"
            [ngClass]="{ 'mat-selected': compareEntities(selectedBrand, brand) }"
        >
            {{ brand.name }}
        </button>
    </mat-menu>

    <mat-menu
        #hotelMenu="matMenu"
        [overlapTrigger]="true"
        class="select-menu-toolbar mat-primary"
        xPosition="after"
        yPosition="below"
    >
        <button
            mat-menu-item
            *ngFor="let hotel of hotels"
            (click)="onHotelChange(hotel)"
            class="mat-option"
            [ngClass]="{ 'mat-selected': compareEntities(selectedHotel, hotel) }"
        >
            {{ hotel.name }}
        </button>
    </mat-menu>
</div>
