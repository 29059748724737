import { Brand } from "./brand.model";
import { Company } from "./company.model";
import { HotelResort } from "./hotel-resort.model";
import { Hotel } from "./hotel.model";

export class InstanceActive {
    token: string;
    companyActive: Company;
    brandActive: Brand;
    hotelActive: Hotel;
    resort: boolean;
    hotelResorts: HotelResort[];
    name: string;
    iptv: boolean;
    hotspot: boolean;
    smartroom: boolean;
    service: boolean;
    cast: boolean;
    networkOperation: boolean;
    booking: boolean;
    netflow: boolean;
    hardwareMonitor: boolean;
}
