import { ActivatedRouteSnapshot, DetachedRouteHandle } from "@angular/router";

export class RouteStorageObject {
    route: ActivatedRouteSnapshot;
    handle: DetachedRouteHandle;
    notified: boolean = false;

    constructor(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle) {
        this.route = route;
        this.handle = handle;
    }

    getRoute(): ActivatedRouteSnapshot {
        return this.route;
    }

    getHandle(): DetachedRouteHandle {
        return this.handle;
    }

    getNotified(): boolean {
        return this.notified;
    }

    setNotified(notified: boolean): void {
        this.notified = notified;
    }
}
