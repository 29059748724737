import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlSegment } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { UserDetailsService } from "app/core/services/user-details.service";
import { KeycloakService } from "keycloak-angular";
import { cloneDeep } from "lodash";
import { firstValueFrom, Observable, of, switchMap, take } from "rxjs";
import { ConfigurationService } from "../config/configuration.service";
import { AppPermissions } from "../data/app-permissions";
import { AppPermissionItem } from "../models/app-permission-item.model";
import { UriUtils } from "../utils/uri.utils";
import { InstanceActiveService } from "./instance-active.service";
import { LoggerService } from "./logger.service";
import { StorageService } from "./storage.service";
import { UserDetails } from "../models/auth/user-details.model";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userDetailsService: UserDetailsService,
        private _instanceActiveService: InstanceActiveService,
        private _configurationService: ConfigurationService,
        private _logger: LoggerService,
        private _storageService: StorageService,
        private _translocoService: TranslocoService,
        private _keycloakService: KeycloakService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    async loadCurrentUser(): Promise<boolean> {
        const isLoggedIn = await this._keycloakService.isLoggedIn();

        if (isLoggedIn) {
            return firstValueFrom(
                this._userDetailsService.get().pipe(
                    switchMap(userDetails => {
                        this._userDetailsService.user = userDetails;

                        this._instanceActiveService.setInstanceFromUser(userDetails.instanceContext);

                        this.setActiveLang(userDetails);

                        return of(!!userDetails);
                    }),
                    take(1)
                )
            );
        }

        return Promise.resolve(false);
    }

    getPermissionsForUrl(url: string, segments?: UrlSegment[]): string[] {
        let permissions = [];

        try {
            const paths = UriUtils.extractPaths(url, segments);
            const lastPath = paths.slice(-1)[0];

            let parents = cloneDeep(AppPermissions);

            for (let path of paths) {
                let current = parents.find(permissionItem => permissionItem.segment === path);

                if (!current) {
                    break;
                }

                if (lastPath === path || !current.children?.length) {
                    if (current.permissions) {
                        permissions.push(...current.permissions);
                    } else if (current.children) {
                        permissions.push(...this.childPermission(current.children));
                    }
                }
                parents = current.children;
            }

            permissions = Array.from(new Set(permissions)); //quitamos duplicados
        } catch (e) {
            this._logger.error(
                `getPermissionFromUrl => Error getting permission for route ${url}. Something it's wrong, please review`,
                e
            );
        }

        return permissions;
    }

    canAccessUrl(url: string): Observable<boolean> {
        const permissions = this.getPermissionsForUrl(url);

        if (permissions.length) {
            return of(this._userDetailsService.hasAnyPermission(...permissions));
        } else {
            return of(true);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private childPermission(children: AppPermissionItem[]): string[] {
        let permissions = [];
        children.forEach(child => {
            if (child.permissions && child.permissions.length) {
                permissions.push(...child.permissions);
            }
            if (child.children) {
                permissions.push(...this.childPermission(child.children));
            }
        });

        return permissions;
    }

    private setActiveLang(userDetails: UserDetails): void {
        const lang =
            this._storageService.getLanguage() ??
            userDetails.lang ??
            this._configurationService.getConfig().langDefault;
        this._translocoService.setActiveLang(lang);
    }
}
