import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { KeycloakService } from "keycloak-angular";
import { catchError, from, mergeMap, Observable, throwError } from "rxjs";

const CONTENT_TYPE_KEY = "Content-Type";
const ACCEPT_LANGUAGE_HEADER_KEY = "Accept-Language";
const APPLICATION_JSON = "application/json;charset=UTF-8";

@Injectable()
export class AuthMasterInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(private translocoService: TranslocoService, private keycloakService: KeycloakService) { }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        return from(this.keycloakService.isLoggedIn()).pipe(
            mergeMap((loggedIn: boolean) =>
                loggedIn ? this.handleRequestWithCustomFields(req, next) : next.handle(req)
            )
        );
    }

    /**
     * Adds the token of the current user to the Authorization header
     *
     * @param req
     * @param next
     */
    private handleRequestWithCustomFields(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Clone the request object
        let headers = req.headers.set(ACCEPT_LANGUAGE_HEADER_KEY, this.translocoService.getActiveLang());

        if (!(req.body instanceof FormData)) {
            headers = headers.set(CONTENT_TYPE_KEY, APPLICATION_JSON);
        }

        const newReq = req.clone({
            headers: headers,
        });

        return next.handle(newReq).pipe(
            catchError(error => {
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401/* || error.status === 0*/) {
                        // Sign out
                        this.keycloakService.logout();

                        // Reload the app
                        location.reload();
                    }
                }

                return throwError(() => error);
            })
        );
    }
}
