export enum FileTypesConstants {
    image = "image",
    video = "video",
    pdf = "pdf",
    doc = "doc",
    xls = "xls",
    txt = "txt",
    ogg = "ogg",
    jpeg = "jpeg",
    svg = "svg+xml",
}
