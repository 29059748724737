export class CodecUtils {
    private static BASE64_REGEX = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    /**
     * Encoding UTF8 ⇢ base64
     * @param str
     */
    static b64EncodeUnicode(str: string): string {
        return window.btoa(
            encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                return String.fromCharCode(parseInt(p1, 16));
            })
        );
    }

    /**
     * Decoding base64 ⇢ UTF8
     */
    static b64DecodeUnicode(str: string): string {
        return decodeURIComponent(
            Array.prototype.map
                .call(window.atob(str), function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
    }

    static isBase64(value: string): boolean {
        return CodecUtils.BASE64_REGEX.test(value);
    }

    static encodeBase64(str: string): string {
        return window.btoa(str);
    }

    static decodeBase64(str: string): string {
        return window.atob(str);
    }
}
