<div class="table-autorefresh">
    <p-splitButton
        [icon]="iconClass"
        styleClass="p-button-sm p-button-text"
        (onClick)="sendRefresh()"
        [model]="menuItems"
        [disabled]="disabled"
    ></p-splitButton>
    <span>{{ selectedTrigger }}</span>
</div>
