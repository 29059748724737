import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { FileElement } from "app/core/models/file-element.model";
import { DetailOperationsConstants } from "../../constants/detail-operations-constants";
import { FileTypesConstants } from "../../constants/file-types-constants";

@Component({
    selector: "file-explorer-detail",
    templateUrl: "./file-explorer-detail.component.html",
    styleUrls: ["./file-explorer-detail.component.scss"],
    animations: fuseAnimations,
})
export class FileExplorerDetailComponent implements OnInit {
    @Input() file: FileElement;
    @Input() image: string;
    @Input() folders: FileElement[];
    @Output() applyOperation: EventEmitter<{ operation: string; fileElement: FileElement }> = new EventEmitter();

    updateName: boolean = false;

    fileElement: FileElement;

    fileExtension: string = "";

    fileTypes = FileTypesConstants;
    detailOperations = DetailOperationsConstants;

    constructor() {}

    /**
     * On Init
     */
    ngOnInit(): void {
        this.fileElement = new FileElement();
    }

    sendOperation(operation: string): void {
        const fileElement = this.fileElement;
        this.fileElement = new FileElement();
        if (operation === DetailOperationsConstants.CLOSE) {
            this.updateName = false;
        }
        this.applyOperation.emit({ operation, fileElement });
    }

    isMultimediaFile(): boolean {
        return (
            this.file.mimeType?.includes(FileTypesConstants.image) ||
            this.file.mimeType?.includes(FileTypesConstants.video)
        );
    }

    isMenuEmpty(): boolean {
        if (this.file.folder) {
            if (this.folders.length === 1 && this.file.relativePath.length === 1) {
                return true;
            }
        }
        return false;
    }

    getNameWithoutExt(file: FileElement): string {
        if (!file.folder) {
            this.fileExtension = file.name.slice(
                file.name.indexOf(
                    ".".concat(this.getExtensionByMime(file.mimeType.slice(file.mimeType.indexOf("/") + 1)))
                )
            );
        } else {
            this.fileExtension = "";
        }
        return !file.folder ? file.name.slice(0, file.name.indexOf(this.fileExtension)) : file.name;
    }

    private getExtensionByMime(mimeType: string): string {
        switch (mimeType) {
            case FileTypesConstants.ogg:
                return "ogv";
            case FileTypesConstants.jpeg:
                return "jp";
            case FileTypesConstants.svg:
                return "svg";
            default:
                return mimeType;
        }
    }
}
