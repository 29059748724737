import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { take } from "rxjs";
import { MatDrawer } from "@angular/material/sidenav";
import { FileElement } from "app/core/models/file-element.model";
import { FileExplorerService } from "app/core/services/file-explorer.service";
import { InstanceActiveService } from "app/core/services/instance-active.service";
import { TranslocoService } from "@ngneat/transloco";
import { ToastrService } from "ngx-toastr";
import { NgxFileDropEntry } from "ngx-file-drop";
import { ResponseDialogConstants } from "app/core/constants/response-dialog-constants";
import { DialogService } from "app/core/services/dialog.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { FileTypesConstants } from "../../constants/file-types-constants";
import { ResponseForm } from "app/core/models/response-form.model";
import { ResponseUtils } from "app/core/utils/response.utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DetailOperationsConstants } from "../../constants/detail-operations-constants";

@UntilDestroy()
@Component({
    selector: "file-explorer",
    templateUrl: "./file-explorer.component.html",
    styleUrls: ["./file-explorer.component.scss"],
})
export class FileExplorerComponent implements OnInit {
    readonly Models = {
        company: "company",
        brand: "brand",
        manual: "manual",
    };

    readonly Sizes = {
        communSize: "10MB",
        videoSize: "10MB",
    };

    @ViewChild("matDrawer") matDrawer: MatDrawer;

    @Input() model: any;
    @Input() endpoint: string;
    @Output() fileSelected: EventEmitter<FileElement> = new EventEmitter();

    private fileElement: FileElement = new FileElement();

    fileElementDetail: FileElement = new FileElement();

    fileTypes = FileTypesConstants;

    fileElements: FileElement[];
    folderElements: FileElement[];

    currentRoot: FileElement;
    currentPath: string;

    canNavigate: boolean = false;
    selectedIndex: number;

    addingFile: boolean = false;

    constructor(
        public fileExplorerService: FileExplorerService,
        private instanceActiveService: InstanceActiveService,
        private translocoService: TranslocoService,
        private responseUtils: ResponseUtils,
        private dialogService: DialogService,
        private toastrService: ToastrService,
        private clipboard: Clipboard,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.fileElements = [];
        this.folderElements = [];
    }

    ngOnInit(): void {
        if (
            typeof this.model === "object" &&
            (this.model.constructor.name.toLowerCase().includes(this.Models.company) ||
                this.model.constructor.name.toLowerCase().includes(this.Models.brand))
        ) {
            if (!this.model.id) {
                this.toastrService.error(this.translocoService.translate("module.fileManager.error.idHotel"));
                return;
            } else {
                this.fileElement.idEntity = this.model.id;
            }
        } else {
            if (
                this.instanceActiveService.instanceActive != null &&
                this.instanceActiveService.instanceActive.hotelActive
            ) {
                this.fileElement.idHotel = this.instanceActiveService.instanceActive.hotelActive.id;
            } else if (
                this.instanceActiveService.instanceActive != null &&
                this.instanceActiveService.instanceActive.brandActive
            ) {
                this.fileElement.idBrand = this.instanceActiveService.instanceActive.brandActive.id;
            } else {
                if (!this.document.URL.toLowerCase().includes(this.Models.manual)) {
                    this.toastrService.error(this.translocoService.translate("module.fileManager.error.idHotelBrand"));
                    return;
                }
            }
        }
        this.initCurrentRoot();
        this.findFileElements();
    }

    initCurrentRoot(): void {
        this.currentRoot = new FileElement();
        this.currentRoot.idEntity = this.fileElement.idEntity;
        this.currentRoot.idBrand = this.fileElement.idBrand;
        this.currentRoot.idHotel = this.fileElement.idHotel;

        this.currentRoot.relativePath = "/";
        this.currentRoot.folder = true;
        this.canNavigate = false;
        this.currentPath = "/";
    }

    getParentElement(fileElement: FileElement): FileElement {
        let parent = new FileElement();
        parent.idEntity = this.fileElement.idEntity;
        parent.idBrand = this.fileElement.idBrand;
        parent.idHotel = this.fileElement.idHotel;
        parent.folder = true;
        parent.name = fileElement.relativePath
            .split("/")
            .filter(path => path)
            .pop();
        parent.relativePath = fileElement.relativePath.slice(0, fileElement.relativePath.indexOf(parent.name));
        return parent;
    }

    getCurrentPath(): string[] {
        return this.currentPath.split("/").filter(path => path);
    }

    navigateTo(path: string, name?: string): void {
        const calculateCurrentPath = this.currentPath.slice(0, this.currentPath.indexOf(path) + path.length);

        if (this.currentPath !== calculateCurrentPath) {
            const navigateRoot = new FileElement();
            navigateRoot.idEntity = this.fileElement.idEntity;
            navigateRoot.idBrand = this.fileElement.idBrand;
            navigateRoot.idHotel = this.fileElement.idHotel;

            navigateRoot.name = name;
            navigateRoot.relativePath = calculateCurrentPath.slice(0, calculateCurrentPath.indexOf(name));
            navigateRoot.folder = true;

            this.currentRoot = navigateRoot;
            this.currentPath = calculateCurrentPath;
            this.findFileElements();

            if (path.length === 1) {
                this.canNavigate = false;
            }
            this.closeDetail();
        }
    }

    findFileElements(): void {
        this.fileExplorerService
            .findFileElementsByElement(this.endpoint, this.currentRoot)
            .pipe(untilDestroyed(this), take(1))
            .subscribe((responseForm: ResponseForm<FileElement[]>) => {
                this.responseUtils.handleReponseOnlyError(responseForm);
                if (this.responseUtils.isSuccess(responseForm)) {
                    const data: FileElement[] = responseForm.data;
                    this.fileElements = data.filter((file: FileElement) => !file.folder);
                    this.folderElements = data.filter((file: FileElement) => file.folder);
                }
            });
    }

    /** Operaciones del componente: */

    addFile(file: File): void {
        if (!this.fileExplorerService.isAllowType(file.type)) {
            this.toastrService.error(this.translocoService.translate("module.fileManager.error.allowType"));
            return;
        } else if (!this.fileExplorerService.isAllowSize(file.type, file.size)) {
            const size: string = file.type.startsWith(FileTypesConstants.video)
                ? this.Sizes.videoSize
                : this.Sizes.communSize;
            this.toastrService.error(
                this.translocoService.translate("module.fileManager.error.allowSize", { value: size })
            );
            return;
        }

        const fileElement = new FileElement();
        fileElement.idEntity = this.fileElement.idEntity;
        fileElement.idBrand = this.fileElement.idBrand;
        fileElement.idHotel = this.fileElement.idHotel;
        fileElement.name = file.name;
        fileElement.relativePath = this.currentPath;
        fileElement.folder = false;

        this.addingFile = true;

        this.fileExplorerService
            .addFile(this.endpoint, fileElement, file)
            .pipe(take(1))
            .subscribe((responseForm: ResponseForm<void>) => {
                this.addingFile = false;
                this.responseUtils.handleReponseOnlyError(responseForm);

                if (this.responseUtils.isSuccess(responseForm)) {
                    this.findFileElements();
                }
            });
    }

    addFolder(name?: string): void {
        this.closeDetail();
        let dto = new FileElement();
        dto.idEntity = this.fileElement.idEntity;
        dto.idBrand = this.fileElement.idBrand;
        dto.idHotel = this.fileElement.idHotel;
        dto.name = name ?? this.translocoService.translate("fileExplorer.newFolder");
        dto.name = this.setNameFolder(dto.name);

        dto.folder = true;
        dto.relativePath = this.currentPath;

        this.fileExplorerService
            .addFolder(this.endpoint, dto)
            .pipe(take(1))
            .subscribe((responseForm: ResponseForm<void>) => {
                this.responseUtils.handleReponseOnlyError(responseForm, "module.fileManager.error.create");
                if (this.responseUtils.isSuccess(responseForm)) {
                    this.findFileElements();
                }
            });
    }

    private setNameFolder(initialName: string): string {
        const namesUsed = this.folderElements
            .filter(folder => folder.name.includes(initialName))
            .map(folder => folder.name);
        if (namesUsed.length) {
            let cont = 0;
            namesUsed.some(name => {
                if (name.includes("(")) {
                    const valueUsed = Number(name.charAt(name.indexOf("(") + 1));
                    cont = cont > valueUsed ? cont : valueUsed;
                }
            });
            return initialName.concat("(" + (cont + 1) + ")");
        }
        return initialName;
    }

    selectFile($event: any, file: FileElement, index: number): void {
        if (index !== this.selectedIndex && !this.matDrawer.opened && !this.addingFile) {
            for (let element of $event.currentTarget.parentElement.children) {
                element.classList.remove("fileSelected");
            }
            $event.currentTarget.classList.add("fileSelected");
            this.selectedIndex = index;
        } else {
            $event.currentTarget.classList.remove("fileSelected");
            this.selectedIndex = -1;
        }

        this.fileSelected.emit(this.selectedIndex >= 0 ? file : null);
    }

    navigateToFolder(folder: FileElement): void {
        this.currentRoot.idEntity = this.fileElement.idEntity;
        this.currentRoot.idBrand = this.fileElement.idBrand;
        this.currentRoot.idHotel = this.fileElement.idHotel;
        this.currentRoot = folder;
        const relative = this.currentRoot.relativePath ? this.currentRoot.relativePath : "";
        this.currentPath = relative + folder.name;
        this.findFileElements();
        this.canNavigate = true;
        this.closeDetail();
    }

    /** Operaciones sobre el fichero: */

    processOperationDetail(operation: string, fileElement: FileElement): void {
        switch (operation) {
            case DetailOperationsConstants.CLOSE:
                this.closeDetail();
                break;
            case DetailOperationsConstants.UPDATE:
                this.updateElement(fileElement);
                break;
            case DetailOperationsConstants.DELETE:
                this.deleteElement();
                break;
            case DetailOperationsConstants.DOWNLOAD:
                this.downloadElement();
                break;
            case DetailOperationsConstants.COPYLINK:
                this.copyPublicLink();
                break;
            case DetailOperationsConstants.MOVETOPARENT:
                this.moveToParent();
                break;
            case DetailOperationsConstants.MOVETOFOLDER:
                this.moveToFolder(fileElement);
                break;
        }
    }

    closeDetail(): void {
        this.matDrawer.close();
    }

    isMultimediaFile(fileElement: FileElement): boolean {
        return (
            fileElement.mimeType?.includes(FileTypesConstants.image) ||
            fileElement.mimeType?.includes(FileTypesConstants.video)
        );
    }

    getIconElement(fileElement: FileElement): string {
        return this.fileExplorerService.getThumbnail(this.endpoint, fileElement);
    }

    updateElement(fileElementUpdated: FileElement): void {
        fileElementUpdated.idEntity = this.fileElement.idEntity;
        fileElementUpdated.idBrand = this.fileElement.idBrand;
        fileElementUpdated.idHotel = this.fileElement.idHotel;
        fileElementUpdated.folder = this.fileElementDetail.folder;
        fileElementUpdated.relativePath = this.fileElementDetail.relativePath;
        fileElementUpdated.generatePath = this.fileElementDetail.generatePath;
        fileElementUpdated.mimeType = this.fileElementDetail.mimeType;

        this.fileExplorerService
            .updateElement(this.endpoint, this.fileElementDetail, fileElementUpdated)
            .pipe(take(1))
            .subscribe((responseForm: ResponseForm<void>) => {
                this.responseUtils.handleReponseOnlyError(responseForm, "module.fileManager.error.rename");
                if (this.responseUtils.isSuccess(responseForm)) {
                    this.fileElementDetail = fileElementUpdated;
                    this.findFileElements();
                }
            });
    }

    deleteElement(): void {
        const dialogRef = this.dialogService.openSimpleCancellableDialogWarning(
            this.translocoService.translate("dialog.secureDelete"),
            this.translocoService.translate("dialog.titleConfirm")
        );

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(response => {
                if (response && response === ResponseDialogConstants.CONFIRMED) {
                    this.fileElementDetail.idEntity = this.fileElement.idEntity;
                    this.fileElementDetail.idBrand = this.fileElement.idBrand;
                    this.fileElementDetail.idHotel = this.fileElement.idHotel;
                    this.fileElementDetail.folder = true;
                    this.fileExplorerService
                        .deleteElement(this.endpoint, this.fileElementDetail)
                        .pipe(take(1))
                        .subscribe((responseForm: ResponseForm<void>) => {
                            this.responseUtils.handleReponseOnlyError(responseForm, "module.fileManager.error.delete");
                            if (this.responseUtils.isSuccess(responseForm)) {
                                this.findFileElements();
                                this.closeDetail();
                            }
                        });
                }
            });
    }

    downloadElement(): void {
        this.fileElementDetail.idEntity = this.fileElement.idEntity;
        this.fileElementDetail.idBrand = this.fileElement.idBrand;
        this.fileElementDetail.idHotel = this.fileElement.idHotel;
        this.fileExplorerService.downloadElement(this.endpoint, this.fileElementDetail);
    }

    moveToParent(): void {
        this.moveElement(this.getParentElement(this.currentRoot));
    }

    moveToFolder(fileElement: FileElement): void {
        this.moveElement(fileElement);
    }

    private moveElement(fileElement: FileElement): void {
        fileElement.relativePath = fileElement.relativePath + (fileElement.name ?? "") + "/";
        fileElement.name = this.fileElementDetail.name;
        fileElement.folder = this.fileElementDetail.folder;

        this.fileExplorerService
            .updateElement(this.endpoint, this.fileElementDetail, fileElement)
            .pipe(take(1))
            .subscribe((responseForm: ResponseForm<void>) => {
                this.responseUtils.handleReponseOnlyError(responseForm, "module.fileManager.error.move");
                if (this.responseUtils.isSuccess(responseForm)) {
                    this.findFileElements();
                    this.closeDetail();
                }
            });
    }

    copyPublicLink(): void {
        this.clipboard.copy(
            this.fileExplorerService.getPublicLink(
                this.fileElementDetail,
                window.location.protocol,
                window.location.host
            )
        );
        this.toastrService.info(this.translocoService.translate("module.fileManager.copyClipboard"));
    }

    /** Operaciones del drop file: */

    dropped(files: NgxFileDropEntry[]): void {
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    this.addFile(file);
                });
            } else {
                this.addFolder(droppedFile.fileEntry.name);
            }
        }
    }

    fileOver(event: Event): void {}

    fileLeave(event: Event): void {}
}
