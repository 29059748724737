import { Injectable } from "@angular/core";
import { SessionStorageConstants } from "../constants/session-storage-constants";
import { CurrentInstanceSelection } from "../models/current-instance-selection.model";
import { InstanceActive } from "../models/instance-active.model";
import { CodecUtils } from "../utils/codec.utils";

@Injectable({
    providedIn: "root",
})
export class StorageService {
    getInstanceActive(): InstanceActive {
        const b64Value = sessionStorage.getItem(SessionStorageConstants.INSTANCE_ACTIVE);

        if (b64Value) {
            return Object.assign(new InstanceActive(), JSON.parse(CodecUtils.decodeBase64(b64Value)));
        }

        return null;
    }

    removeInstanceActive(): void {
        sessionStorage.removeItem(SessionStorageConstants.INSTANCE_ACTIVE);
    }

    saveCurrentInstanceSelection(value: CurrentInstanceSelection): void {
        const b64Value = btoa(JSON.stringify(value));

        sessionStorage.setItem(SessionStorageConstants.CURRENT_INSTANCE_SELECTION, b64Value);
    }

    getCurrentInstanceSelection(): CurrentInstanceSelection {
        const b64Value = sessionStorage.getItem(SessionStorageConstants.CURRENT_INSTANCE_SELECTION);

        if (b64Value) {
            return Object.assign(new CurrentInstanceSelection(), JSON.parse(CodecUtils.decodeBase64(b64Value)));
        }

        return null;
    }

    removeCurrentInstanceSelection(): void {
        sessionStorage.removeItem(SessionStorageConstants.CURRENT_INSTANCE_SELECTION);
    }

    saveLanguage(lang: string): void {
        sessionStorage.setItem(SessionStorageConstants.LANGUAGE, lang);
    }

    getLanguage(): string {
        return sessionStorage.getItem(SessionStorageConstants.LANGUAGE);
    }

    clear(): void {
        sessionStorage.clear();
    }
}
