import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { ReplaySubject } from "rxjs";
import { StorageService } from "../services/storage.service";

@Injectable({
    providedIn: "root",
})
export class LanguageListener {
    langChange$: ReplaySubject<string>;

    constructor(private translocoService: TranslocoService, private storageService: StorageService) {
        this.langChange$ = new ReplaySubject<string>(1);
        this.translocoService.langChanges$.subscribe((activeLang: string) => {
            this.load(activeLang);
            this.storageService.saveLanguage(activeLang);
        });
    }

    private load(lang: string): void {
        this.translocoService.load(lang).subscribe(() => {
            this.langChange$.next(lang);
        });
    }
}
