import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { take } from "rxjs";
import { CodeModuleConstants } from "../constants/code-module-constants";
import { RouteConstants, WelcomeRouteConstants } from "../constants/route-constants";
import { InstanceActive } from "../models/instance-active.model";
import { InstanceActiveService } from "../services/instance-active.service";
import { UserDetailsService } from "../services/user-details.service";

@Injectable({
    providedIn: "root",
})
export class InstanceActiveListener {
    private currentInstanceActive: InstanceActive;
    private routeHome: string = "";
    private homeShowed: boolean = false;
    private evaluateInstance: boolean = true;

    constructor(
        private instanceActiveService: InstanceActiveService,
        private router: Router,
        private userDetailsService: UserDetailsService
    ) {
        this.loadHome();
        //Si se recarga la pantalla no es necesario volver a cargar su home o evaluar la instancia activa
        this.router.events.pipe(take(1)).subscribe(event => {
            if (event instanceof NavigationStart) {
                if (event.url !== RouteConstants.LANDING_HOME) {
                    this.homeShowed = true;
                    this.evaluateInstance = false;
                }
            }
            this.redirect();
        });
    }

    private redirect(): void {
        this.instanceActiveService.instanceActiveChanges$.subscribe((instanceActive: InstanceActive) => {
            if (
                this.isDifferentRoute(
                    ...[
                        RouteConstants.ROUTE_CONFIG_EMAIL_TEMPLATE,
                        RouteConstants.ROUTE_CONFIG_CURRENCY,
                        RouteConstants.ROUTE_CONFIG_FAQ,
                        RouteConstants.ROUTE_CONFIG_MANUAL,
                        RouteConstants.ROUTE_CONFIG_PMS,
                        RouteConstants.ROUTE_CONFIG_UPDATER,
                        RouteConstants.ROUTE_CONFIG_UTILS,
                    ]
                )
            ) {
                if (!this.redirectUserHome() && this.evaluateInstance) {
                    if (instanceActive?.companyActive) {
                        if (
                            this.isCompanyChange(instanceActive) &&
                            this.isDifferentRoute(...[WelcomeRouteConstants.COMPANY])
                        ) {
                            this.router.navigateByUrl(WelcomeRouteConstants.COMPANY);
                        }
                        if (instanceActive.brandActive) {
                            this.redirectByBrandOrHotel(
                                instanceActive,
                                this.userDetailsService.isUserNoc()
                                    ? RouteConstants.MODULE_HOME_NOC
                                    : RouteConstants.MODULE_HOME
                            );
                        } else {
                            this.router.navigateByUrl(WelcomeRouteConstants.COMPANY);
                        }
                    } else {
                        this.router.navigateByUrl(RouteConstants.LANDING_HOME);
                    }
                }
            }
            this.currentInstanceActive = instanceActive;
            this.evaluateInstance = true;
        });
    }

    /**
     * Indicada una ruta de redirección, procederá a redirigir a esta, en función del cambio que se produzca por marca u hotel,
     * y de la pantalla en la que se encuentre.
     * @param instanceActive: InstanceActive
     * @param routeToRedirect: string con la ruta a la que redirigir
     */
    private redirectByBrandOrHotel(instanceActive: InstanceActive, routeToRedirect: string): void {
        if (this.isBrandChange(instanceActive)) {
            if (this.isDifferentRoute(...[routeToRedirect])) {
                this.router.navigateByUrl(routeToRedirect);
            }
        }
        if (this.isDifferentRoute(...[RouteConstants.ROUTE_ANALYTICS])) {
            if (instanceActive.hotelActive) {
                if (
                    (this.isHotelChange(instanceActive) &&
                        this.isDifferentRoute(
                            ...[
                                RouteConstants.ROUTE_MODULE,
                                RouteConstants.ROUTE_HOTEL_STRUCTURE,
                                RouteConstants.ROUTE_CONFIG_EMAIL_TEMPLATE,
                            ]
                        )) ||
                    (!this.isDifferentRoute(...[RouteConstants.ROUTE_MODULE]) && !this.isModuleActive(instanceActive))
                ) {
                    this.router.navigateByUrl(routeToRedirect);
                }
            } else {
                this.router.navigateByUrl(routeToRedirect);
            }
        }
    }

    /**
     * Carga la ruta home correspondiente para el usuario logueado. Si no tiene ninguna, asigna la ruta home.
     */
    private loadHome(): void {
        const route = Object.values(WelcomeRouteConstants)
            .find((route: string) => route.includes(this.userDetailsService.currentUser?.homeUrl))
            ?.toString();
        this.routeHome = route ?? RouteConstants.LANDING_HOME;
    }

    /**
     * Redirige a la home del usuario, retornando true. En caso contrario, retorna false.
     * @returns
     */
    private redirectUserHome(): boolean {
        if (!this.homeShowed && !this.userDetailsService.isUserAdmin() && !this.userDetailsService.isUserNoc()) {
            this.homeShowed = true;
            this.router.navigateByUrl(this.routeHome);
            return true;
        }
        return false;
    }

    /**
     * Permite comprobar que ninguna de las rutas que recibe como parámetro coincide con la ruta actual. En caso de que haya alguna
     * coincidencia, el método retornará false.
     * @param routes
     * @returns
     */
    private isDifferentRoute(...routes: string[]): boolean {
        return routes.every((route: string) => !this.router.url.includes(route));
    }

    /**
     * Estos métodos permiten comprobar si ha cambiado la compañía, marca u hotel respectivamente
     * @param instanceActive
     * @returns
     */

    private isCompanyChange(instanceActive: InstanceActive): boolean {
        return (
            !this.currentInstanceActive ||
            this.currentInstanceActive?.companyActive?.id !== instanceActive?.companyActive?.id
        );
    }

    private isBrandChange(instanceActive: InstanceActive): boolean {
        return (
            !this.currentInstanceActive ||
            this.currentInstanceActive?.brandActive?.id !== instanceActive?.brandActive?.id
        );
    }

    private isHotelChange(instanceActive: InstanceActive): boolean {
        return (
            !this.currentInstanceActive ||
            this.currentInstanceActive?.hotelActive?.id !== instanceActive?.hotelActive?.id
        );
    }

    /**
     * Determina si el módulo está activo para la ruta actual
     * @param instanceActive
     * @returns
     */
    private isModuleActive(instanceActive: InstanceActive): boolean {
        const url: string[] = this.router.url.split("/");
        const module: string = url[url.findIndex(path => path === RouteConstants.ROUTE_MODULE) + 1];

        switch (module.toUpperCase()) {
            case CodeModuleConstants.HOTSPOT:
                return instanceActive.hotspot;
            case CodeModuleConstants.INTERACTIVE_TV:
                return instanceActive.iptv;
            case CodeModuleConstants.BOOKING:
                return instanceActive.booking;
            case CodeModuleConstants.CAST:
                return instanceActive.cast;
            case CodeModuleConstants.ROOM_XPERIENCE:
                return instanceActive.smartroom;
            case CodeModuleConstants.NETWORK_OPERATION:
                return instanceActive.networkOperation;
            case CodeModuleConstants.SRVMGM:
                return instanceActive.service;
            case CodeModuleConstants.NETFLOW:
                return instanceActive.netflow;
            case CodeModuleConstants.HARDWARE_MONITOR:
                return instanceActive.hardwareMonitor;
            default:
                return false;
        }
    }
}
