export enum DetailOperationsConstants {
    CLOSE = "closeDetail",
    UPDATE = "updateElement",
    DELETE = "deleteElement",
    DOWNLOAD = "downloadElement",
    THUMBNAIL = "getIconElement",
    COPYLINK = "copyPublicLink",
    MOVETOPARENT = "moveToParent",
    MOVETOFOLDER = "moveToFolder",
}
