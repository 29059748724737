import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { ConfigurationService } from "../config/configuration.service";

@Injectable()
export class GrafanaAPIInterceptor implements HttpInterceptor {
    constructor(private configService: ConfigurationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        const newReq = req.clone({
            headers: req.headers.set("Authorization", "Bearer " + this.configService.getConfig().grafana.apiKey),
        });

        // Response
        return next.handle(newReq).pipe(
            catchError(error => {
                return throwError(() => error);
            })
        );
    }
}
