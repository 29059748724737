import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ConfigurationService } from "./core/config/configuration.service";
import { InstanceActiveListener } from "./core/listeners/instance-active.listener";
import { IdleService } from "./core/services/idle.service";
import { InstanceActiveService } from "./core/services/instance-active.service";
import { KeycloakService } from "keycloak-angular";

@UntilDestroy()
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    title = "Icosaedro";

    /**
     * Constructor
     */
    constructor(
        private _instanceActiveService: InstanceActiveService,
        private keycloakService: KeycloakService,
        private idleService: IdleService,
        private configService: ConfigurationService,
        private instanceActiveListener: InstanceActiveListener, //NO BORRAR, NECESARIO PARA QUE CARGUE EL InstanceActiveListener al lanzar la aplicación
        private router: Router
    ) {}

    @HostListener("window:beforeunload", ["$event"])
    unloadHandler(_event: Event): void {
        this._instanceActiveService.persistCurrentSelection();
    }

    ngOnInit(): void {
        this._instanceActiveService.loadCurrentSelectionFromStorage();
        this._instanceActiveService.loadInstanceFromStorage();

        this.idleService
            .startWatching(this.configService.getConfig().maxSessionTimeoutSeconds)
            .pipe(untilDestroyed(this))
            .subscribe(async (isTimeout: boolean) => {
                if (isTimeout) {
                    const loggedIn = await this.keycloakService.isLoggedIn();

                    if (loggedIn) {
                        this.router.navigateByUrl("/logout");
                    }
                }
            });

        /*window.addEventListener("keyup", disableF5);
        window.addEventListener("keydown", disableF5);

        function disableF5(e) {
            if ((e.which || e.keyCode) == 116) e.preventDefault();
        };*/
    }
}
