/* eslint-disable prettier/prettier */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { MenuItem, PrimeIcons } from "primeng/api";
import { SplitButton } from "primeng/splitbutton";

UntilDestroy();
@Component({
    selector: "app-table-autorefresh",
    templateUrl: "./table-autorefresh.component.html",
    styleUrls: ["./table-autorefresh.component.scss"]
})
export class TableAutoRefreshComponent implements OnInit, OnDestroy {


    private readonly OFF_ICON_CLASS = "icon-off";
    private readonly SELECTED_ICON_CLASS = "time-selected";

    @Input() menuItems: MenuItem[] = [];
    @Input() timeTriggers: string[] = ["off", "10s", "30s", "1m", "5m"];
    @Input() selectedTrigger: string = "off";

    @Output() timerEmitterEvent = new EventEmitter<any>();

    @ViewChild(SplitButton) splitButton: SplitButton;

    iconClass = "pi pi-refresh";
    @Input() disabled = false;


    private refreshDate: number | null = null;

    ngOnInit(): void {
        for (const timeString of this.timeTriggers) {
            let menuItem: MenuItem = null;

            if (timeString === "off") {
                menuItem = {
                    label: timeString,
                    icon: PrimeIcons.TIMES,
                    command: this.onOffClick.bind(this),
                    styleClass: this.OFF_ICON_CLASS,
                };
            } else {
                const millis = this.parseTimeString(timeString);

                if (millis != 0) {
                    menuItem = {
                        label: timeString,
                        icon: PrimeIcons.CLOCK,
                        command: this.onTimerTriggerClick.bind(this),
                    };
                }
            }

            if (this.selectedTrigger === menuItem.label) {
                menuItem.styleClass += this.SELECTED_ICON_CLASS;
            }

            if (menuItem) {
                this.menuItems.push(menuItem);
            }
        }
    }

    ngOnDestroy(): void {
        this.stopInterval();
    }


    sendRefresh(): void {
        this.timerEmitterEvent.emit();
    }


    public startInterval(): void {
        const millis = this.parseTimeString(this.selectedTrigger);

        if (millis > 0) {
            this.refreshDate = window.setInterval(() => this.sendRefresh(), millis);
        }
    }

    public stopInterval(): void {
        if (this.refreshDate) {
            window.clearInterval(this.refreshDate);
        }
        this.refreshDate = null;
    }

    public startSpin(): void {
        this.iconClass += " pi-spin";
    }

    public stopSpin(): void {
        this.iconClass = "pi pi-refresh";
    }

    public isStarted(): boolean {
        return this.refreshDate != null;
    }

    private parseTimeString(timeStr: string): number {
        if (timeStr === "off") {
            return 0;
        }

        return timeStr.match(/\d+\s?\w/g).reduce((acc, cur, i) => {
            let multiplier = 1000;
            switch (cur.slice(-1)) {
                case "h":
                    multiplier *= 60;
                case "m":
                    multiplier *= 60;
                case "s":
                    return (parseInt(cur) ? parseInt(cur) : 0) * multiplier + acc;
            }
            return acc;
        }, 0);
    }

    private onOffClick(e: any): void {
        const selected = e.item as MenuItem;
        this.stopInterval();
        this.selectedTrigger = selected.label;
        this.stopSpin();

        const offItem = this.menuItems.find(mi => mi.label === "off");

        if (offItem) {
            offItem.styleClass = this.OFF_ICON_CLASS + " " + this.SELECTED_ICON_CLASS;
        }

        this.menuItems
            .filter(mi => mi.label !== "off")
            .forEach(mi => { mi.iconClass = ""; })
    }

    private onTimerTriggerClick(e: any): void {
        const selected = e.item as MenuItem;

        this.selectedTrigger = selected.label;

        this.stopInterval();
        this.startInterval();
        this.startSpin();

        const offItem = this.menuItems.find(mi => mi.label === "off");

        if (offItem) {
            offItem.styleClass = this.OFF_ICON_CLASS;
        }

        for (const mi of this.menuItems) {
            if (mi.label === this.selectedTrigger) {
                mi.styleClass = this.SELECTED_ICON_CLASS;
            } else {
                mi.styleClass = "";
            }
        }
    }
}
