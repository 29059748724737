import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { UserDetails } from "app/core/models/auth/user-details.model";
import { cloneDeep } from "lodash";
import { map, Observable, of, ReplaySubject } from "rxjs";
import { ConfigurationService } from "../config/configuration.service";
import { RoleConstants } from "../constants/role-constants";
import { HttpClientMaster } from "../http/http-client-master";
import { FuseConfigService } from "../../../@fuse/services/config";

@Injectable({
    providedIn: "root",
})
export class UserDetailsService {
    private _userDetails: UserDetails;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClientMaster,
        private _configService: ConfigurationService,
        private translocoService: TranslocoService,
        private _fuseConfigService: FuseConfigService
    ) {}

    private _user: ReplaySubject<UserDetails> = new ReplaySubject<UserDetails>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: UserDetails) {
        // Store the value
        this._userDetails = value;
        this._user.next(value);
        this.checkLayoutByUser();
    }

    /*get user() : UserDetails {
        return this._userDetails;
    }*/

    get user$(): Observable<UserDetails> {
        return this._user.asObservable();
    }

    get currentUser(): UserDetails {
        return cloneDeep(this._userDetails);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    isUserLoaded(): boolean {
        return !!this._userDetails;
    }

    /**
     * Get the current logged in user data
     */
    get(): Observable<UserDetails> {
        return this._httpClient.get<UserDetails>(this._configService.getConfig().rootUrl + "/auth/sessionContext").pipe(
            map(userDetails => {
                if (userDetails) {
                    //Propiedades que se usan en fuse
                    //No se cambian para no cambiar los layouts y poder actualizar en un futuro fuse
                    userDetails.avatar = userDetails.profilePhoto;
                    userDetails.status = "online";
                    userDetails.email = userDetails.mail;

                    userDetails.profilePhoto = null;
                }
                return userDetails;
            })
        );
    }

    hasPermission(permission: string): boolean {
        return this.hasAllPermissions(permission);
    }

    hasAllPermissions(...permissions: string[]): boolean {
        const authorities = this.getAuthoritiesFlat();
        const isAdmin = authorities.some(auth => RoleConstants.ADMIN.toString() === auth);

        if (isAdmin) {
            return true;
        }

        return permissions.every(permission => authorities.some(auth => permission === auth));
    }

    hasAllPermissionsAsync(...permissions: string[]): Observable<boolean> {
        return of(this.hasAllPermissions(...permissions));
    }

    hasAnyPermission(...permissions: string[]): boolean {
        const authorities = this.getAuthoritiesFlat();
        const isAdmin = authorities.some(auth => RoleConstants.ADMIN.toString() === auth);

        if (isAdmin) {
            return true;
        }

        return permissions.some(permission => authorities.some(auth => permission === auth));
    }

    hasAnyPermissionByModule(module: string): boolean {
        const authorities = this.getAuthoritiesFlat();
        const isAdmin = authorities.some(auth => RoleConstants.ADMIN.toString() === auth);

        if (isAdmin) {
            return true;
        }

        return authorities.some(auth => auth.includes(module));
    }

    hasAllRoles(...roles: string[]): boolean {
        const authorities = this.getAuthoritiesFlat();
        return roles.every(role => authorities.some(auth => role === auth));
    }

    hasRole(role: string): boolean {
        return this.hasAllRoles(role);
    }

    isUserNoc(): boolean {
        return this.hasRole(RoleConstants.ROLE_NOC.valueOf());
    }

    isUserAdmin(): boolean {
        return this.hasRole(RoleConstants.ADMIN.valueOf());
    }

    private getAuthoritiesFlat(): Array<string> {
        if (this._userDetails) {
            return this._userDetails.authorities.map(authority => authority["authority"]);
        }

        return [];
    }

    private checkLayoutByUser(): void {
        if (!this.hasPermission(RoleConstants.ADMIN) && this.hasRole("ROLE_DMR")) {
            this._fuseConfigService.config = { layout: "simple" };
        }
    }
}
