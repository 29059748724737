import { Injectable } from "@angular/core";
import { throwError, Observable, firstValueFrom } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { FileElement } from "../models/file-element.model";
import { ResponseForm } from "../models/response-form.model";
import { UriUtils } from "../utils/uri.utils";
import { ConfigurationService } from "../config/configuration.service";
import { HttpClientMaster } from "../http/http-client-master";

@Injectable({
    providedIn: "root",
})
export class FileExplorerService {
    readonly OperationFileExplorer = {
        ADD_FOLDER_ELEMENT: "addFolderElement",
        ADD_FILE_ELEMENT: "addFileElement",
        UPDATE_ELEMENT: "renameElement",
        DELETE_ELEMENT: "deleteElement",
        FIND_ELEMENTS: "listElement",
        DOWNLOAD_ELEMENT: "downloadElement",
        THUMBNAIL_ELEMENT: "thumbnailElement",
    };

    constructor(private configService: ConfigurationService, private httpClient: HttpClientMaster) {}

    addFolder(endpoint: string, fileElement: FileElement): Observable<ResponseForm<void>> {
        return this.httpClient.post<ResponseForm<void>>(
            this.buildUrl(endpoint, "/" + this.OperationFileExplorer.ADD_FOLDER_ELEMENT),
            fileElement
        );
    }

    addFile(endpoint: string, fileElement: FileElement, file: File): Observable<ResponseForm<void>> {
        const formData = new FormData();
        formData.append("file", file, fileElement.name);
        formData.append("idEntity", fileElement.idEntity ? String(fileElement.idEntity) : "");
        formData.append("idBrand", fileElement.idBrand ? String(fileElement.idBrand) : "");
        formData.append("idHotel", fileElement.idHotel ? String(fileElement.idHotel) : "");
        formData.append("name", fileElement.name);
        formData.append("folder", String(fileElement.folder));
        formData.append("relativePath", fileElement.relativePath);

        return this.httpClient.post<ResponseForm<void>>(
            this.buildUrl(endpoint, "/" + this.OperationFileExplorer.ADD_FILE_ELEMENT),
            formData
        );
    }

    deleteElement(endpoint: string, fileElement: FileElement): Observable<ResponseForm<void>> {
        return this.httpClient.post<ResponseForm<void>>(
            this.buildUrl(endpoint, "/" + this.OperationFileExplorer.DELETE_ELEMENT),
            fileElement
        );
    }

    updateElement(endpoint: string, elementOld: FileElement, element: FileElement): Observable<ResponseForm<void>> {
        let elements: FileElement[] = [elementOld, element];
        return this.httpClient.post<ResponseForm<void>>(
            this.buildUrl(endpoint, "/" + this.OperationFileExplorer.UPDATE_ELEMENT),
            elements
        );
    }

    findFileElementsByElement(endpoint: string, fileElement: FileElement): Observable<ResponseForm<FileElement[]>> {
        return this.httpClient.post<ResponseForm<FileElement[]>>(
            this.buildUrl(endpoint, "/" + this.OperationFileExplorer.FIND_ELEMENTS),
            fileElement
        );
    }

    downloadElement(endpoint: string, fileElement: FileElement): void {
        firstValueFrom(
            this.httpClient.post(
                this.buildUrl(endpoint, "/" + this.OperationFileExplorer.DOWNLOAD_ELEMENT),
                fileElement,
                {
                    headers: new HttpHeaders({ "Content-Type": "application/json" }),
                    responseType: "blob",
                }
            )
        )
            .then(blobData => {
                let blob = new Blob([blobData], { type: blobData.type });
                let uri = window.URL.createObjectURL(blob);
                let anchor = document.createElement("a");
                anchor.download = fileElement.name;
                anchor.href = uri;
                anchor.click();
            })
            .catch(err => console.error("download error = ", err));
    }

    getThumbnail(endpoint: string, fileElement: FileElement): string {
        if (fileElement) {
            const param = Object.entries(fileElement);
            const url = new URL(this.buildUrl(endpoint, "/" + this.OperationFileExplorer.THUMBNAIL_ELEMENT));
            param.forEach(item => {
                url.searchParams.set(item[0], item[1]);
            });
            return url.toString();
        }
        return "";
    }

    isAllowType(type: string): boolean {
        if (!this.configService.getConfig().allowedFileTypesToUpload.find((item: string) => item === type)) {
            return false;
        }
        return true;
    }

    isAllowSize(type: string, size: number): boolean {
        if (type.startsWith("image/") && size > this.configService.getConfig().allowedSizeImageToUploadBytes) {
            return false;
        } else if (type.startsWith("video/") && size > this.configService.getConfig().allowedSizeVideoToUploadBytes) {
            return false;
        }

        return true;
    }

    getPublicLink(element: FileElement, protocol: string, host: string): string {
        const publicLink =
            protocol + "//" + host + "/sfiles" + element.generatePath + element.relativePath + element.name;
        return publicLink;
    }

    private buildUrl(endpoint: string, ...segments: string[]): string {
        return UriUtils.appendParts(this.configService.getConfig().rootUrl, endpoint, ...segments);
    }
}
