import { HttpClientModule } from "@angular/common/http";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { RouteReuseStrategy } from "@angular/router";
import { IconsModule } from "app/core/icons/icons.module";
import { TranslocoCoreModule } from "app/core/transloco/transloco.module";
import { HttpClientGrafanaApi } from "./http/http-client-grafana-api";
import { HttpClientMaster } from "./http/http-client-master";
import { CacheReuseStrategy } from "./routing/cache-route-reuse-strategy";

@NgModule({
    imports: [
        IconsModule, //
        TranslocoCoreModule,
        HttpClientModule,
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: CacheReuseStrategy,
        },
        HttpClientMaster,
        ...HttpClientMaster.getInterceptorProviders(),
        HttpClientGrafanaApi,
        ...HttpClientGrafanaApi.getInterceptorProviders(),
    ],
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error("CoreModule has already been loaded. Import this module in the AppModule only.");
        }
    }
}
