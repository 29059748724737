import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatSlideToggle, MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Observable, take } from "rxjs";
import { ResponseDialogConstants } from "../../../core/constants/response-dialog-constants";
import { DialogService } from "../../../core/services/dialog.service";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "app-slide-toggle-module",
    templateUrl: "./slide-toggle-module.component.html",
    styleUrls: ["./slide-toggle-module.component.scss"],
})
export class SlideToggleModuleComponent implements AfterViewInit {
    @ViewChild("slideToggle") slideToggle: MatSlideToggle;

    @Input() title: string;
    @Input() tooltip: string;
    @Input() tooltipDisabled: string;
    @Input() icon: string;
    @Input() enabledChecked: boolean;
    @Input() forcedCheckedToggle$: Observable<boolean>;
    @Input() showToggle: boolean = false;
    @Input() routerLinkUrl: string;

    @Output() changeToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private dialogService: DialogService, private translocoService: TranslocoService) {}

    ngAfterViewInit(): void {
        this.forcedCheckedToggle$?.pipe(untilDestroyed(this)).subscribe((checkedToggle: boolean) => {
            this.enabledChecked = checkedToggle;
            if (this.slideToggle) {
                this.slideToggle.checked = checkedToggle;
            }
        });
    }

    onChangeToggle(matSlideToggleChange: MatSlideToggleChange): void {
        let nameModule = "";
        if (this.title) {
            nameModule = this.translocoService.translate(this.title);
        }
        const dialogRef = this.dialogService.openSimpleCancellableDialog(
            this.translocoService.translate("module.titleChangeModuleConfirmBody", { nameModule }),
            this.translocoService.translate("module.titleChangeModuleConfirm")
        );

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(response => {
                if (response && response === ResponseDialogConstants.CONFIRMED) {
                    this.enabledChecked = matSlideToggleChange.checked;
                    this.changeToggle.emit(matSlideToggleChange.checked);
                } else if (response && response === ResponseDialogConstants.CANCELLED) {
                    this.slideToggle.checked = !matSlideToggleChange.checked;
                }
            });
    }
}
