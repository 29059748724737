import { ComponentRef } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Data,
    DetachedRouteHandle,
    RouteReuseStrategy,
    RouterStateSnapshot,
} from "@angular/router";
import { RouteStorageObject } from "./route-storage-object";

//https://stackoverflow.com/questions/41280471/how-to-implement-routereusestrategy-shoulddetach-for-specific-routes-in-angular
//https://itnext.io/cache-components-with-angular-routereusestrategy-3e4c8b174d5f
//https://www.reddit.com/r/Angular2/comments/rdsgkg/can_you_dynamically_destroy_a_component_used/
//https://github.com/angular/angular/issues/44383
//https://javascript.plainenglish.io/routereusestrategy-simplified-in-angular-2e358db618d9

export class CacheReuseStrategy implements RouteReuseStrategy {
    /**
     * Object which will store DetachedRouteHandle indexed by keys
     * The keys will all be a path (as in route.routeConfig.path)
     * This allows us to see if we've got a route stored for the requested path
     */
    storedRoutes = new Map<string, RouteStorageObject>();

    currRoute: ActivatedRouteSnapshot;
    futureRoute: ActivatedRouteSnapshot;

    /**
     * Decides when the route should be stored
     * If the route should be stored, I believe the boolean is indicating to a controller whether or not to fire this.store
     * _When_ it is called though does not particularly matter, just know that this determines whether or not we store the route
     * An idea of what to do here: check the route.routeConfig.path to see if it is a path you would like to store
     *
     * @param route This is, at least as I understand it, the route that the user is currently on, and we would like to know if we want to store it
     * @returns boolean indicating that we want to (true) or do not want to (false) store that route
     */
    /**
     * Cuando se sale de un ruta se llama a este método el cual si devuelve TRUE se ejecutara el método store()
     */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const ret =
            !!route.routeConfig &&
            !!route.routeConfig.component &&
            this.isListRoute(route) &&
            this.isSameParent(this.currRoute, this.futureRoute);

        if (!this.isSameParent(this.currRoute, this.futureRoute)) {
            this.clearAllStoredHandlers();
        }

        return ret;
    }

    /**
     * Constructs object of type `RouteStorageObject` to store, and then stores it for later attachment
     *
     * @param route This is stored for later comparison to requested routes, see `this.shouldAttach`
     * @param handle Later to be retrieved by this.retrieve, and offered up to whatever controller is using this class
     */
    /**
     * En este método realizamos el guardado de las instancias de las rutas que queremos reutilizar.
     * Se llama si shouldDetach devuelve true.
     */
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        //f (!!handle) {
        const path = this.getFullRouteUrl(route);
        this.clearStoredHandlers(route);
        if (!!handle) {
            const componentRef = (handle as any).componentRef as ComponentRef<any>;

            if (componentRef.instance?.onStore && typeof componentRef.instance?.onStore === "function") {
                componentRef.instance.onStore();
            }

            this.storedRoutes.set(path, new RouteStorageObject(route, handle));
        } else {
            this.storedRoutes.delete(path);
        }

        //}
    }

    /**
     * Determines whether or not there is a stored route and, if there is, whether or not it should be rendered in place of requested route
     *
     * @param route The route the user requested
     * @returns boolean indicating whether or not to render the stored route
     */
    /**
     * Si ingresamos a una ruta este método se ejecutará y si devuelve TRUE se ejecutara el método retrieve()
     */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getFullRouteUrl(route);
        const canAttach = !!route.routeConfig && this.storedRoutes.has(path) && !!route.component;

        // this decides whether the route already stored should be rendered in place of the requested route, and is the return value
        // at this point we already know that the paths match because the storedResults key is the route.routeConfig.path
        // so, if the route.params and route.queryParams also match, then we should reuse the component
        if (canAttach) {
            const routeStorageObj = this.storedRoutes.get(path);

            const paramsMatch: boolean = this.compareObjects(route.params, routeStorageObj.getRoute().params);
            const queryParamsMatch: boolean = this.compareObjects(
                route.queryParams,
                routeStorageObj.getRoute().queryParams
            );

            /*console.log(
                "deciding to attach...",
                route,
                "does it match?",
                routeStorageObj.getRoute(),
                "return: ",
                paramsMatch && queryParamsMatch
            );*/
            return paramsMatch && queryParamsMatch;
        } else {
            return false;
        }
    }

    /**
     * Finds the locally stored instance of the requested route, if it exists, and returns it
     *
     * @param route New route the user has requested
     * @returns DetachedRouteHandle object which can be used to render the component
     */
    /**
     *  Este método retornaria la instancia de la ruta guardada anteriormente, si la instancia no fue guardada retornara un valor NULL
     */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        const path = this.getFullRouteUrl(route);
        // return null if the path does not have a routerConfig OR if there is no stored route for that routerConfig
        if (!route.routeConfig || !route.component || !this.storedRoutes.has(path)) {
            return null;
        }

        const routeStorageObj = this.storedRoutes.get(path);

        //console.log("retrieving", "return: ", routeStorageObj?.getHandle());

        /** returns handle when the route.routeConfig.path is already stored */

        const handle = routeStorageObj.getHandle() as any;
        if (!!handle) {
            const componentRef = handle.componentRef as ComponentRef<any>;

            if (
                componentRef.instance?.onRetrieve &&
                typeof componentRef.instance?.onRetrieve === "function" &&
                !routeStorageObj.getNotified()
            ) {
                componentRef.instance.onRetrieve();
                routeStorageObj.setNotified(true);
            }
        }

        return handle;
    }

    /**
     * Determines whether or not the current route should be reused
     *
     * @param future The route the user is going to, as triggered by the router
     * @param curr The route the user is currently on
     * @returns boolean basically indicating true if the user intends to leave the current route
     */
    /**
     * Se ejecuta cada vez que cambia una ruta, determina si se reutilizará la ruta, si el método retorna TRUE
     * los demas métodos no seran ejecutados, pero si retorna FALSE los demas métodos seran ejecutados.
     * future es la ruta que dejamos, curr es la ruta a la que vamos.
     */
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        this.futureRoute = future;
        this.currRoute = curr;

        const shouldReuse = future.routeConfig === curr.routeConfig;

        return shouldReuse;
    }

    /**
     * This nasty bugger finds out whether the objects are _traditionally_ equal to each other, like you might assume someone else would have put
     * this function in vanilla JS already
     * One thing to note is that it uses coercive comparison (==) on properties which both objects have, not strict comparison (===)
     * Another important note is that the method only tells you if `compare` has all equal parameters to `base`, not the other way around
     *
     * @param base The base object which you would like to compare another object to
     * @param compare The object to compare to base
     * @returns boolean indicating whether or not the objects have all the same properties and those properties are ==
     */
    private compareObjects(base: any, compare: any): boolean {
        // loop through all properties in base object
        for (const baseProperty in base) {
            // determine if comparrison object has that property, if not: return false
            if (compare.hasOwnProperty(baseProperty)) {
                switch (typeof base[baseProperty]) {
                    // if one is object and other is not: return false
                    // if they are both objects, recursively call this comparison function
                    case "object":
                        if (
                            typeof compare[baseProperty] !== "object" ||
                            !this.compareObjects(base[baseProperty], compare[baseProperty])
                        ) {
                            return false;
                        }
                        break;
                    // if one is function and other is not: return false
                    // if both are functions, compare function.toString() results
                    case "function":
                        if (
                            typeof compare[baseProperty] !== "function" ||
                            base[baseProperty].toString() !== compare[baseProperty].toString()
                        ) {
                            return false;
                        }
                        break;
                    // otherwise, see if they are equal using coercive comparison
                    default:
                        if (base[baseProperty] !== compare[baseProperty]) {
                            return false;
                        }
                }
            } else {
                return false;
            }
        }

        // returns true only after false HAS NOT BEEN returned through all loops
        return true;
    }

    private getLastSegment(route: ActivatedRouteSnapshot): string {
        const lastPart = this.getFullRouteUrlPaths(route).slice(-1);

        return lastPart.length ? lastPart[0] : "";
    }

    private getParentSegments(route: ActivatedRouteSnapshot): string {
        const paths = this.getFullRouteUrlPaths(route);

        return paths.slice(0, -1).filter(Boolean).join("/");
    }

    private isListRoute(route: ActivatedRouteSnapshot): boolean {
        const lastPath = this.getLastSegment(route);

        return lastPath === "list";
    }

    private getFullRouteUrl(route: ActivatedRouteSnapshot): string {
        return this.getFullRouteUrlPaths(route).filter(Boolean).join("/");
    }

    private getFullRouteUrlPaths(route: ActivatedRouteSnapshot): string[] {
        /*const paths = this._getRouteUrlPaths(route);
        return route.parent ? [...this.getFullRouteUrlPaths(route.parent), ...paths] : paths;*/

        const routerState = (route as any)["_routerState"] as RouterStateSnapshot;

        return routerState.url.split("/");
    }

    private _getRouteUrlPaths(route: ActivatedRouteSnapshot): string[] {
        return route.url.map(urlSegment => urlSegment.path);

        /*const routerState = (route as any)["_routerState"] as RouterStateSnapshot;

        return routerState.url.split("/");*/
    }

    private getRouteData(route: ActivatedRouteSnapshot): Data {
        return route.routeConfig && (route.routeConfig.data as Data);
    }

    private clearStoredHandlers(route: ActivatedRouteSnapshot): void {
        const routePath = this.getFullRouteUrl(route);
        this.storedRoutes.forEach((value: RouteStorageObject, key: string) => {
            const itRoute = value.getRoute();

            const itRoutePath = this.getFullRouteUrl(itRoute);

            if (itRoutePath !== routePath) {
                const handle = value.getHandle() as any;
                const componentRef = handle.componentRef as ComponentRef<any>;

                componentRef.destroy();

                this.storedRoutes.delete(key);
            }
        });
    }

    private clearAllStoredHandlers(): void {
        this.storedRoutes.forEach((value: RouteStorageObject, key: string) => {
            const handle = value.getHandle() as any;
            const componentRef = handle.componentRef as ComponentRef<any>;

            componentRef.destroy();

            this.storedRoutes.delete(key);
        });
    }

    private isSameParent(currRoute: ActivatedRouteSnapshot, futureRoute: ActivatedRouteSnapshot): boolean {
        if (!currRoute || !futureRoute) {
            return false;
        }

        const isFutureRouteList = this.isListRoute(futureRoute);
        const isCurrRouteList = this.isListRoute(currRoute);

        const futurePath = this.getFullRouteUrl(futureRoute);
        const currPath = this.getFullRouteUrl(currRoute);

        if (isFutureRouteList && isCurrRouteList) {
            return false;
        } else if (isFutureRouteList) {
            const futureParentPath = this.getParentSegments(futureRoute);

            return currPath.startsWith(futureParentPath);
        } else {
            const currParentPath = this.getParentSegments(currRoute);

            return futurePath.startsWith(currParentPath);
        }
    }
}
