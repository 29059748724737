<!-- Main -->
<div class="flex flex-col flex-auto">
    <!-- Header -->
    <div
        class="flex flex-col sm:flex-row items-start sm:items-center sm:justify-between p-6 sm:py-12 md:px-8 border-b bg-card dark:bg-transparent"
    >
        <!-- Title -->
        <div>
            <div class="text-4xl font-extrabold tracking-tight leading-none">
                {{ "fileExplorer.title" | transloco }}
            </div>
            <div class="flex items-center mt-0.5 font-medium text-secondary">
                <ng-container *ngIf="!canNavigate">
                    {{ folderElements?.length }} {{ "fileExplorer.folders" | transloco }},
                    {{ fileElements?.length }}
                    {{ "fileExplorer.files" | transloco }}
                </ng-container>
                <!-- Breadcrumbs -->
                <ng-container *ngIf="canNavigate">
                    <div class="flex items-center space-x-2">
                        <a
                            [ngClass]="
                                !addingFile
                                    ? 'text-primary cursor-pointer'
                                    : 'text-primary cursor-default pointer-events-none opacity-50'
                            "
                            (click)="navigateTo('/')"
                        >
                            {{ "fileExplorer.home" | transloco }}
                        </a>
                        <div class="">/</div>
                        <ng-container *ngFor="let path of getCurrentPath(); let i = index; let last = last">
                            <ng-container *ngIf="path">
                                <a
                                    [ngClass]="
                                        !addingFile
                                            ? 'text-primary cursor-pointer'
                                            : 'text-primary cursor-default pointer-events-none opacity-50'
                                    "
                                    (click)="navigateTo((getCurrentPath()[i - 1] ?? '') + '/' + path, path)"
                                >
                                    {{ path }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="!last">
                                <div class="">/</div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- Actions -->
        <div class="grid grid-cols-2 gap-4 mt-4">
            <!-- Upload button and create folder button -->
            <button
                mat-flat-button
                class="hidden xs:block"
                type="button"
                [color]="'primary'"
                [disabled]="addingFile"
                (click)="fileInput.click(); closeDetail()"
            >
                <input
                    type="file"
                    #fileInput
                    name="files"
                    title="Load File"
                    (change)="addFile($event.target['files'][0])"
                    style="display: none"
                />
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="hidden xs:block ml-2 mr-1">{{ "fileExplorer.uploadFile" | transloco }}</span>
            </button>
            <button
                mat-icon-button
                type="button"
                [color]="'primary'"
                class="block xs:hidden"
                [disabled]="addingFile"
                (click)="fileInput.click(); closeDetail()"
            >
                <input
                    type="file"
                    #fileInput
                    name="files"
                    title="Load File"
                    (change)="addFile($event.target['files'][0])"
                    style="display: none"
                />
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            </button>
            <button
                class="hidden xs:block"
                mat-flat-button
                type="button"
                [color]="'primary'"
                [disabled]="addingFile"
                (click)="addFolder()"
            >
                <mat-icon [svgIcon]="'heroicons_outline:folder'"></mat-icon>
                <span class="hidden xs:block ml-2 mr-1">{{ "fileExplorer.createFolder" | transloco }}</span>
            </button>
            <button
                mat-icon-button
                type="button"
                [color]="'primary'"
                class="block xs:hidden"
                [disabled]="addingFile"
                (click)="addFolder()"
            >
                <mat-icon [svgIcon]="'heroicons_outline:folder'"></mat-icon>
            </button>
        </div>
    </div>

    <p-blockUI [target]="panelExplorer" [blocked]="addingFile">
        <mat-spinner [diameter]="25"></mat-spinner>
        <span class="ml-2 text-xl text-white">{{ "fileExplorer.uploadingFiles" | transloco }}</span>
    </p-blockUI>
    <p-panel #panelExplorer [showHeader]="false">
        <!-- Items list -->
        <ngx-file-drop
            class="file-drop grow"
            [disabled]="addingFile"
            (onFileDrop)="dropped($event)"
            (onFileOver)="fileOver($event)"
            (onFileLeave)="fileLeave($event)"
        >
            <ng-template ngx-file-drop-content-tmp>
                <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent">
                    <!-- Drawer -->
                    <mat-drawer
                        class="w-full sm:w-100 dark:bg-gray-900"
                        [mode]="'side'"
                        [opened]="false"
                        [position]="'end'"
                        [disableClose]="true"
                        #matDrawer
                    >
                        <file-explorer-detail
                            [file]="fileElementDetail"
                            [image]="isMultimediaFile(fileElementDetail) ? this.getIconElement(fileElementDetail) : ''"
                            [folders]="folderElements"
                            (applyOperation)="processOperationDetail($event.operation, $event.fileElement)"
                        ></file-explorer-detail>
                    </mat-drawer>

                    <mat-drawer-content class="flex flex-col bg-gray-100 dark:bg-transparent sm:justify-center">
                        <ng-container *ngIf="fileElements?.length || folderElements?.length; else noItems">
                            <div class="p-6 md:p-8 space-y-8">
                                <!-- Folders -->
                                <div *ngIf="folderElements.length">
                                    <div class="font-medium">{{ "fileExplorer.folders" | transloco }}</div>
                                    <div class="flex flex-wrap -m-2 mt-2">
                                        <ng-container *ngFor="let folder of folderElements; let i = index">
                                            <div [ngClass]="!addingFile ? 'file-container rounded-2xl' : 'rounded-2xl'">
                                                <div class="relative w-40 h-40 m-2 p-4 shadow rounded-2xl bg-card file">
                                                    <a
                                                        class="btn absolute z-20 top-1.5 right-1.5 w-8 h-8 min-h-8"
                                                        (click)="fileElementDetail = folder; matDrawer.open()"
                                                        mat-icon-button
                                                    >
                                                        <mat-icon
                                                            [ngClass]="{
                                                                'icon-size-5': true,
                                                                'opacity-50': addingFile
                                                            }"
                                                            [svgIcon]="'heroicons_solid:eye'"
                                                            [matTooltip]="'common.view' | transloco"
                                                        ></mat-icon>
                                                    </a>
                                                    <a
                                                        class="z-10 absolute inset-0 flex flex-col p-4 cursor-pointer"
                                                        (click)="navigateToFolder(folder)"
                                                    >
                                                        <div class="aspect-w-9 aspect-h-6">
                                                            <div class="flex items-center justify-center">
                                                                <mat-icon
                                                                    class="icon-size-14 text-hint"
                                                                    [svgIcon]="'heroicons_outline:folder'"
                                                                ></mat-icon>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="flex flex-col flex-auto justify-center text-center text-sm font-medium"
                                                        >
                                                            <div class="truncate" [matTooltip]="folder.name">
                                                                {{ folder.name }}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- Files -->
                                <div *ngIf="fileElements.length">
                                    <div class="font-medium">{{ "fileExplorer.files" | transloco }}</div>
                                    <div class="flex flex-wrap -m-2 mt-2">
                                        <ng-container *ngFor="let file of fileElements; let i = index">
                                            <div
                                                [ngClass]="!addingFile ? 'file-container rounded-2xl' : 'rounded-2xl'"
                                                (click)="selectFile($event, file, i)"
                                            >
                                                <div
                                                    class="flex flex-col w-40 h-40 m-2 p-4 shadow rounded-2xl cursor-pointer bg-card file"
                                                >
                                                    <div class="aspect-w-9 aspect-h-6">
                                                        <div class="flex items-center justify-center">
                                                            <a
                                                                class="btn absolute z-20 top-1.5 right-1.5 w-0 h-0 min-h-0 detail"
                                                                (click)="fileElementDetail = file; matDrawer.open()"
                                                                mat-icon-button
                                                            >
                                                                <mat-icon
                                                                    [ngClass]="{
                                                                        'icon-size-5': true,
                                                                        'opacity-50': addingFile
                                                                    }"
                                                                    [svgIcon]="'heroicons_solid:eye'"
                                                                    [matTooltip]="'common.view' | transloco"
                                                                ></mat-icon>
                                                            </a>
                                                            <!-- Icons -->
                                                            <div class="relative">
                                                                <img
                                                                    class="icon"
                                                                    *ngIf="isMultimediaFile(file)"
                                                                    src="{{ getIconElement(file) }}"
                                                                />
                                                                <ng-container *ngIf="!isMultimediaFile(file)">
                                                                    <mat-icon
                                                                        class="icon-size-14 text-hint"
                                                                        [svgIcon]="'heroicons_outline:document'"
                                                                    ></mat-icon>
                                                                    <div
                                                                        class="absolute left-0 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white"
                                                                        [class.bg-red-600]="
                                                                            file.mimeType
                                                                                .toLowerCase()
                                                                                .includes(fileTypes.pdf)
                                                                        "
                                                                        [class.bg-blue-600]="
                                                                            file.mimeType
                                                                                .toLowerCase()
                                                                                .includes(fileTypes.doc)
                                                                        "
                                                                        [class.bg-green-600]="
                                                                            file.mimeType
                                                                                .toLowerCase()
                                                                                .includes(fileTypes.xls)
                                                                        "
                                                                        [class.bg-gray-600]="
                                                                            file.mimeType
                                                                                .toLowerCase()
                                                                                .includes(fileTypes.txt)
                                                                        "
                                                                    >
                                                                        {{
                                                                            file.mimeType
                                                                                .slice(file.mimeType.indexOf("/") + 1)
                                                                                .toUpperCase()
                                                                        }}
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="flex flex-col flex-auto justify-center text-center text-sm font-medium"
                                                    >
                                                        <div class="truncate" [matTooltip]="file.name">
                                                            {{ file.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-drawer-content>
                </mat-drawer-container>

                <!-- No items template -->
                <ng-template #noItems>
                    <div class="flex flex-auto flex-col items-center justify-center bg-gray-100 dark:bg-transparent">
                        <mat-icon
                            class="icon-size-24 opacity-30"
                            [svgIcon]="'heroicons_outline:folder-open'"
                        ></mat-icon>
                        <div class="mt-4 text-2xl font-semibold tracking-tight text-secondary">
                            {{ "fileExplorer.noItems" | transloco }}
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </ngx-file-drop>
    </p-panel>
</div>
