<div class="toolbar-monaco" fxLayout="row">
    <mat-label class="title">{{ title | transloco }} {{ required ? "*" : "" }}</mat-label>
    <div class="expand-monaco-container">
        <button
            mat-icon-button
            type="button"
            class="expand-monaco"
            color="primary"
            matTooltip="{{ 'common.expand' | transloco }}"
            (click)="expand()"
        >
            <mat-icon>zoom_out_map</mat-icon>
        </button>
    </div>
</div>
<ngx-monaco-editor
    id="monacoEditor"
    #monacoEditor
    [options]="options"
    [(ngModel)]="model"
    name="monacoEditor"
    (ngModelChange)="modelChange()"
></ngx-monaco-editor>
<!-- <mat-icon class="icon-resize">unfold_more</mat-icon> -->
