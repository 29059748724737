import { OwlDateTimeIntl } from "ng-pick-datetime-ex";
import { Inject, Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LanguageListener } from "../../../core/listeners/language.listener";

@UntilDestroy()
@Injectable()
export class DateTimeIntl extends OwlDateTimeIntl {
    constructor(@Inject(TranslocoService) private _translocoService, private _languageListener: LanguageListener) {
        super();

        this._languageListener.langChange$.pipe(untilDestroyed(this)).subscribe(() => {
            this.getLang();
        });
    }

    public getLang(): void {
        this.upSecondLabel = this._translocoService.translate("datetime.upSecondLabel");
        this.downSecondLabel = this._translocoService.translate("datetime.downSecondLabel");
        this.upMinuteLabel = this._translocoService.translate("datetime.upMinuteLabel");
        this.downMinuteLabel = this._translocoService.translate("datetime.downMinuteLabel");
        this.upHourLabel = this._translocoService.translate("datetime.upHourLabel");
        this.downHourLabel = this._translocoService.translate("datetime.downHourLabel");
        this.prevMonthLabel = this._translocoService.translate("datetime.prevMonthLabel");
        this.nextMonthLabel = this._translocoService.translate("datetime.nextMonthLabel");
        this.prevYearLabel = this._translocoService.translate("datetime.prevYearLabel");
        this.nextYearLabel = this._translocoService.translate("datetime.nextYearLabel");
        this.prevMultiYearLabel = this._translocoService.translate("datetime.prevMultiYearLabel");
        this.nextMultiYearLabel = this._translocoService.translate("datetime.nextMultiYearLabel");
        this.switchToMonthViewLabel = this._translocoService.translate("datetime.switchToMonthViewLabel");
        this.switchToMultiYearViewLabel = this._translocoService.translate("datetime.switchToMultiYearViewLabel");
        this.cancelBtnLabel = this._translocoService.translate("datetime.cancelBtnLabel");
        this.setBtnLabel = this._translocoService.translate("datetime.setBtnLabel");
        this.rangeFromLabel = this._translocoService.translate("datetime.rangeFromLabel");
        this.rangeToLabel = this._translocoService.translate("datetime.rangeToLabel");
        this.hour12AMLabel = this._translocoService.translate("datetime.hour12AMLabel");
        this.hour12PMLabel = this._translocoService.translate("datetime.hour12PMLabel");
    }
}
