import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTabsModule } from "@angular/material/tabs";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTreeModule } from "@angular/material/tree";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatRadioModule } from "@angular/material/radio";
import { RouterModule } from "@angular/router";
import { FuseAlertModule } from "@fuse/components/alert";
import { FuseCardModule } from "@fuse/components/card";
import { FuseScrollbarModule } from "@fuse/directives/scrollbar";
import { FuseConfirmationModule } from "@fuse/services/confirmation";
import { TranslocoModule } from "@ngneat/transloco";
import { ImageCropperModule } from "ngx-image-cropper";
import { Error404Component } from "./components/error-404/error-404.component";
import { Error500Component } from "./components/error-500/error-500.component";
import { GenericSearchComponent } from "./components/generic-search/generic-search.component";
import { ImageUploadCropperComponent } from "./components/image-upload-cropper/image-upload-cropper.component";
import { InstanceSelectorComponent } from "./components/instance-selector/instance-selector.component";
import { MaintenanceComponent } from "./components/maintenance/maintenance.component";
import { PanelComponent } from "./components/panel/panel.component";
import { HasAnyPermissionDirective } from "./directives/has-any-permission.directive";
import { MEPCollapseOnBlurDirective } from "./directives/mep-collapse-on-blur.directive";

import { TreeModule } from "primeng/tree";
import { TableModule } from "primeng/table";
import { CheckboxModule } from "primeng/checkbox";
import { TriStateCheckboxModule } from "primeng/tristatecheckbox";
import { PickListModule } from "primeng/picklist";
import { BlockUIModule } from "primeng/blockui";
import { PanelModule } from "primeng/panel";
import { MenuModule } from "primeng/menu";
import { AccordionModule } from "primeng/accordion";
import { ToggleButtonModule } from "primeng/togglebutton";
import { DialogModule } from "primeng/dialog";
import { EditorModule as PrimeEditorModule } from "primeng/editor";
import { ForbiddenComponent } from "./components/forbidden/forbidden.component";
import { DateTimeModule } from "./modules/datetime/datetime.module";
import { DividerComponent } from "./components/divider/divider.component";
import { SlideToggleModuleComponent } from "./components/slide-toggle-module/slide-toggle-module.component";
import { IsHotelActiveDirective } from "./directives/is-hotel-active.directive";
import { SafePipe } from "./pipes/safe.pipe";
import { MonacoEditorComponent } from "./components/monaco-editor/monaco-editor.component";
import { MonacoEditorModule } from "ngx-monaco-editor";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { SelectButtonModule } from "primeng/selectbutton";
import { DynamicMacDirective } from "./directives/dynamic-mac.directive";
import { ColorPipe } from "./pipes/color.pipe";
import { NgxFileDropModule } from "ngx-file-drop";
import { MatGridListModule } from "@angular/material/grid-list";
import { FileExplorerComponent } from "./components/file-explorer/views/file-explorer/file-explorer.component";
import { FileExplorerDetailComponent } from "./components/file-explorer/components/file-explorer-detail/file-explorer-detail.component";
import { FileExplorerDialogComponent } from "./components/file-explorer/components/file-explorer-dialog/file-explorer-dialog.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { TimezoneSelectorComponent } from "./components/timezone-selector/timezone-selector.component";
import { QRCodeModule } from "angularx-qrcode";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { DeviceInfoPipe } from "./pipes/device-info.pipe";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { MatSelectFilterModule } from "mat-select-filter";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { DropdownModule } from "primeng/dropdown";
import { TabViewModule } from "primeng/tabview";
import { SplitButtonModule } from "primeng/splitbutton";
import { TableAutoRefreshComponent } from "./components/table-autorefresh/table-autorefresh.component";
import { CalendarModule } from "primeng/calendar";

const materialModules = [
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatDividerModule,
    MatTabsModule,
    MatStepperModule,
    MatTreeModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatPasswordStrengthModule,
    MatRadioModule,

    ImageCropperModule,
    NgxFileDropModule,
    MatGridListModule,
    FontAwesomeModule,
];

const primengModules = [
    TreeModule,
    TableModule,
    CheckboxModule,
    TriStateCheckboxModule,
    ToggleButtonModule,
    DialogModule,
    PrimeEditorModule,
    ButtonModule,
    RippleModule,
    SelectButtonModule,
    PanelModule,
    PickListModule,
    BlockUIModule,
    MenuModule,
    AccordionModule,
    DropdownModule,
    TabViewModule,
    SplitButtonModule,
    CalendarModule
];

const sharedComponents = [
    Error404Component,
    Error500Component,
    MaintenanceComponent,
    InstanceSelectorComponent,
    GenericSearchComponent,
    PanelComponent,
    ImageUploadCropperComponent,
    ForbiddenComponent,
    DividerComponent,
    MonacoEditorComponent,
    SlideToggleModuleComponent,

    HasAnyPermissionDirective,
    IsHotelActiveDirective,
    MEPCollapseOnBlurDirective,
    SlideToggleModuleComponent,
    DynamicMacDirective,

    SafePipe,
    ColorPipe,
    FileExplorerComponent,
    FileExplorerDetailComponent,
    FileExplorerDialogComponent,
    TimezoneSelectorComponent,
    DeviceInfoPipe,
    TableAutoRefreshComponent,
];

const fuseModules = [FuseCardModule, FuseAlertModule, FuseScrollbarModule, FuseConfirmationModule];

@NgModule({
    declarations: [...sharedComponents],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        RouterModule,
        ClipboardModule,
        QRCodeModule,

        DateTimeModule,

        PdfViewerModule,

        ...fuseModules,

        ...materialModules,

        ...primengModules,

        NgxMaskDirective,
        NgxMaskPipe,

        MonacoEditorModule.forRoot(),

        MatSelectFilterModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        QRCodeModule,

        DateTimeModule,

        PdfViewerModule,

        ...fuseModules,

        ...sharedComponents,

        ...materialModules,

        ...primengModules,

        NgxMaskDirective,
        NgxMaskPipe,

        SlideToggleModuleComponent,

        MonacoEditorModule,
        MatSelectFilterModule,
    ],

    providers: [provideNgxMask()],
})
export class SharedModule { }
