import { Component, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Dimensions, ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";

@Component({
    selector: "image-upload-cropper",
    templateUrl: "image-upload-cropper.component.html",
    styleUrls: ["./image-upload-cropper.component.scss"],
})
export class ImageUploadCropperComponent {
    imageChangedEvent: any = "";
    croppedImage: string = "";
    canvasRotation: number = 0;
    rotation: number = 0;
    scale: number = 1;
    showCropper: boolean = false;
    containWithinAspectRatio: boolean = false;
    transform: ImageTransform = {};
    title: string;

    constructor(
        public dialogRef: MatDialogRef<ImageUploadCropperComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data?.title) {
            this.title = data.title;
        }
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
    }

    imageLoaded(): void {
        this.showCropper = true;
    }

    cropperReady(sourceImageDimensions: Dimensions): void {}

    loadImageFailed(): void {}

    rotateLeft(): void {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight(): void {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate(): void {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH,
        };
    }

    flipHorizontal(): void {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH,
        };
    }

    flipVertical(): void {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV,
        };
    }

    zoomOut(): void {
        this.scale -= 0.1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    zoomIn(): void {
        this.scale += 0.1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    save(): void {
        this.dialogRef.close({
            data: this.croppedImage,
        });
    }
}
