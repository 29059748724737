import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { take } from "rxjs";
import { AvailableLangs, LangDefinition, TranslocoService } from "@ngneat/transloco";
import { FuseNavigationService, FuseVerticalNavigationComponent } from "@fuse/components/navigation";
import { LocaleService } from "app/core/services/locale.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MatPaginatorIntl } from "@angular/material/paginator";

@UntilDestroy()
@Component({
    selector: "languages",
    templateUrl: "./languages.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: "languages",
})
export class LanguagesComponent implements OnInit {
    availableLangs: LangDefinition[];
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _localeService: LocaleService,
        private _matPaginatorIntl: MatPaginatorIntl
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs() as LangDefinition[];

        // Subscribe to language changes
        this._translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe(activeLang => {
            // Get the active lang
            this.activeLang = activeLang;

            // Update paginator
            this._translocoService
                .load(activeLang)
                .pipe(take(1))
                .subscribe(data => {
                    this.updateMatPaginator();
                });
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {};

        for (const loc of this._localeService.locales) {
            this.flagCodes[loc.locale] = loc.flag;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        // Set the active lang
        this.activeLang = lang;
        this._translocoService.setActiveLang(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private updateMatPaginator(): void {
        this._matPaginatorIntl.firstPageLabel = this._translocoService.translate("paginator.first_page");
        this._matPaginatorIntl.lastPageLabel = this._translocoService.translate("paginator.last_page");
        this._matPaginatorIntl.nextPageLabel = this._translocoService.translate("paginator.next_page");
        this._matPaginatorIntl.previousPageLabel = this._translocoService.translate("paginator.previous_page");
        this._matPaginatorIntl.itemsPerPageLabel = this._translocoService.translate("paginator.items_per_page");
        this._matPaginatorIntl.getRangeLabel = this.getRangeLabel;
        this._matPaginatorIntl.changes.next();
    }

    private getRangeLabel(page, pageSize, length): string {
        length = Math.max(length, 0);
        let startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        startIndex++;

        return this._translocoService.translate("paginator.range", { startIndex, endIndex, length });
    }
}
