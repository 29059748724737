import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { FileElement } from "app/core/models/file-element.model";
import { DialogService } from "app/core/services/dialog.service";
import { ComponentType } from "ngx-toastr";

@Component({
    selector: "file-explorer-dialog",
    templateUrl: "./file-explorer-dialog.component.html",
    styleUrls: ["./file-explorer-dialog.component.scss"],
    animations: fuseAnimations,
})
export class FileExplorerDialogComponent implements OnInit {
    fileSelected: FileElement;
    dialogRef: MatDialogRef<any>;

    value: string = "";

    @Input() label: string;
    @Input() disabled: boolean;
    @Input() model: any;
    @Input() endpoint: string;
    @Input() required: boolean = false;
    @Input() fileInput: FileElement;
    @Output() fileEmited: EventEmitter<FileElement> = new EventEmitter();

    @ViewChild("dialogFileExplorer") fileExplorerDialog: ComponentType<any>;

    constructor(private dialogService: DialogService) {}

    ngOnInit(): void {
        this.loadValueFile(this.fileInput);
    }

    upload(event: Event): void {
        event.stopPropagation();

        this.fileEmited.emit(this.fileSelected);
        this.loadValueFile(this.fileSelected);
        this.dialogRef.close();
    }

    openFileExplorerDialog(): void {
        this.dialogRef = this.dialogService.openComponentDialog(this.fileExplorerDialog, {
            width: "85%",
            disableClose: true,
        });
    }

    clearInput(): void {
        this.value = "";
        this.fileEmited.next(null);
    }

    private loadValueFile(file: FileElement): void {
        if (file) {
            this.value = file.relativePath + file.name;
        }
    }
}
