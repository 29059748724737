import { Directive, HostListener } from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";

@Directive({
    selector: "mat-expansion-panel[collapseOnBlur]",
})
export class MEPCollapseOnBlurDirective {
    private matExpansionPanel: MatExpansionPanel;

    constructor(el: MatExpansionPanel) {
        this.matExpansionPanel = el;
    }

    @HostListener("document:click", ["$event"])
    onClick(e: Event): void {
        this.closeSearchOnBlur(e);
    }

    @HostListener("document:keyup.Enter", ["$event"])
    @HostListener("document:submit", ["$event"])
    onSearch(e: Event | KeyboardEvent): void {
        this.closeOnSearch(e);
    }

    private closeSearchOnBlur(e: Event): void {
        if (this.matExpansionPanel.expanded && e.target instanceof Element) {
            const elTarget = e.target as Element;

            const parent = elTarget.closest(".mat-expansion-panel");

            //TODO: averiguar como identificar si he pulsado en un combo dentro del buscador generico
            if (
                (!parent &&
                    elTarget &&
                    (elTarget.tagName === "MAT-OPTION" ||
                        (elTarget.parentElement && elTarget.parentElement.tagName === "MAT-OPTION") ||
                        (elTarget.parentElement &&
                            elTarget.parentElement.parentElement &&
                            elTarget.parentElement.parentElement.tagName === "MAT-OPTION") ||
                        (elTarget.parentElement &&
                            elTarget.parentElement.parentElement &&
                            elTarget.parentElement.parentElement.parentElement &&
                            elTarget.parentElement.parentElement.parentElement.tagName === "MAT-OPTION") ||
                        (elTarget.tagName !== "svg" && elTarget.className.startsWith("owl")) ||
                        (elTarget.tagName !== "svg" && elTarget.parentElement.className.startsWith("owl")) ||
                        elTarget.className.includes("cdk-overlay-transparent-backdrop"))) ||
                (elTarget.className && elTarget.className.includes("mat-filter-input"))
            ) {
                return;
            }

            if (!parent) {
                this.matExpansionPanel.close();
            }
        }
    }

    private closeOnSearch(e: Event | KeyboardEvent): void {
        if (e.target instanceof Element && e.target.className.startsWith("owl")) {
            return;
        } else {
            this.matExpansionPanel.close();
        }
    }
}
