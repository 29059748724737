import { Injectable } from "@angular/core";
import { Locale } from "locale-enum";
import { LocaleModel } from "../models/locale.model";

@Injectable({
    providedIn: "root",
})
export class LocaleService {
    /**
     * Store locale and flag image name
     */
    private _locales: LocaleModel[];

    constructor() {
        this._locales = [
            {
                locale: Locale.es.toString(),
                flag: "ES",
                nameKey: "module.changelog.es",
            },
            {
                locale: Locale.en.toString(),
                flag: "US",
                nameKey: "module.changelog.en",
            },
        ];
    }

    get locales(): LocaleModel[] {
        return this._locales;
    }
}
