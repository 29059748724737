<mat-form-field class="w-full">
    <mat-label>{{ "module.smartroom.thermostat.timezone" | transloco }}</mat-label>
    <input
        (keyup)="onKeyUp($event)"
        [matAutocomplete]="auto"
        [value]="timezone"
        [disabled]="disabled"
        matInput
        placeholder="{{ 'module.smartroom.thermostat.timezone' | transloco }}"
        [required]="required"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTimezone($event.option.value)">
        <mat-option *ngFor="let timezone of filteredOptionsTimezoneList" [value]="timezone">
            {{ timezone }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
