import { HttpBackend, HttpClient, HttpInterceptor } from "@angular/common/http";
import { Injectable, InjectionToken, Injector, Provider } from "@angular/core";
import { GrafanaAPIInterceptor } from "../interceptors/grafana-api.interceptor";
import { HandlerService } from "./http-handler";

const HTTP_INTERCEPTORS_GRAFANA = new InjectionToken<HttpInterceptor[]>("HTTP_INTERCEPTORS_GRAFANA");

@Injectable({
    providedIn: "root",
})
export class HttpClientGrafanaApi extends HttpClient {
    constructor(backend: HttpBackend, private injector: Injector) {
        super(new HandlerService(backend, injector, HTTP_INTERCEPTORS_GRAFANA));
    }

    static getInterceptorProviders(): Provider[] {
        return [
            {
                provide: HTTP_INTERCEPTORS_GRAFANA,
                useClass: GrafanaAPIInterceptor,
                multi: true,
            },
        ];
    }
}
