import { Injectable } from "@angular/core";
import { FuseNavigationItem, FuseNavigationService } from "@fuse/components/navigation";
import { TranslocoService } from "@ngneat/transloco";
import { cloneDeep } from "lodash";
import { Observable, ReplaySubject } from "rxjs";
import { CodeModuleConstants } from "../constants/code-module-constants";
import { RoleConstants } from "../constants/role-constants";
import { NavigationData, NavigationDataNOC } from "../data/navigation-data";
import { InstanceActive } from "../models/instance-active.model";
import { Navigation } from "../models/navigation.model";
import { AuthService } from "./auth.service";
import { InstanceActiveService } from "./instance-active.service";
import { LoggerService } from "./logger.service";
import { UserDetailsService } from "./user-details.service";
import { LanguageListener } from "../listeners/language.listener";

/**
 * Menu
 */
@Injectable({
    providedIn: "root",
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    private shouldHideCache: Map<string, boolean>;

    private instanceActive!: InstanceActive;

    private renderedFirstTime: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _translocoService: TranslocoService,
        private _logger: LoggerService,
        private _userDetailsService: UserDetailsService,
        private _authService: AuthService,
        private _instanceActiveService: InstanceActiveService,
        private _languageListener: LanguageListener,
        private _fuseNavigationService: FuseNavigationService
    ) {
        this.shouldHideCache = new Map();

        this.listenEvents();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        this.updateNavigation();

        return this.navigation$;
    }

    updateNavigation(): void {
        const items = this.buildNavigation();
        this._fuseNavigationService.storeNavigation("mainNavigation", items);

        this._navigation.next({
            compact: items,
            default: items,
            futuristic: items,
            horizontal: items,
        });
    }

    clearCache(): void {
        this.shouldHideCache.clear();
    }

    getCurrentNavigation(): FuseNavigationItem[] {
        return this._fuseNavigationService.getNavigation("mainNavigation") ?? [];
    }

    private listenEvents(): void {
        this._instanceActiveService.instanceActive$.subscribe(instanceActive => {
            //this._logger.debug("[NavigationService] Obtenido instancia activa", instanceActive);
            this.instanceActive = instanceActive;

            if (this.instanceActive && this.renderedFirstTime) {
                this.clearCache();
                this.updateNavigation();
            }
        });

        // Subscribe to language changes to change menu language
        this._languageListener.langChange$.subscribe(() => {
            this.updateNavigation();

            if (!this.renderedFirstTime) {
                this.renderedFirstTime = true;
            }
        });
    }

    private buildNavigation(): FuseNavigationItem[] {
        this._logger.debug(
            `[NavigationService] Reconstruyendo menu para el lenguage ${this._translocoService.getActiveLang()}`
        );

        /*const menu: FuseNavigationItem[] = this._userDetailsService.hasRole(RoleConstants.ROLE_NOC.valueOf())
            ? cloneDeep(NavigationData)
            : cloneDeep(NavigationData);
        */

        const menu: FuseNavigationItem[] = cloneDeep(NavigationData);

        menu.forEach(navigationItem => this.enhanceNavigationItem(navigationItem));

        return menu;
    }

    private enhanceNavigationItem(navigationItem: FuseNavigationItem): void {
        if (navigationItem.title) {
            navigationItem.title = this._translocoService.translate(navigationItem.title);
        }

        if (navigationItem.meta?.brandRequired && !this.instanceActive?.brandActive) {
            navigationItem.disabled = true;
            navigationItem.tooltip = this._translocoService.translate("error.brandNotSelected");
        }

        if (navigationItem.meta?.hotelRequired && !this.instanceActive?.hotelActive) {
            navigationItem.disabled = true;
            navigationItem.tooltip = this._translocoService.translate("error.hotelNotSelected");
        }

        navigationItem.hidden = (): boolean => this.shouldHideMenu(navigationItem);

        if (navigationItem.children && navigationItem.children.length) {
            navigationItem.children.forEach(child => this.enhanceNavigationItem(child));
        }

        if (navigationItem.badge && navigationItem.badge.title) {
            navigationItem.badge.title = this._translocoService.translate(navigationItem.badge.title);
        }
    }

    private shouldHideMenu(navigationItem: FuseNavigationItem): boolean {
        if (this.shouldHideCache.has(navigationItem.id)) {
            return this.shouldHideCache.get(navigationItem.id);
        }

        let shouldHide = true;

        if (
            (navigationItem.type === "group" || navigationItem.type === "collapsable") &&
            navigationItem.children &&
            navigationItem.children.length
        ) {
            shouldHide = !navigationItem.children.some(child => this.shouldHideMenu(child) === false);
            //shouldHide = false;
        } else if (navigationItem.type === "basic") {
            if (navigationItem.externalLink) {
                shouldHide = !this.canSeeExternalLink(navigationItem);
            } else {
                const permissions = this._authService.getPermissionsForUrl(navigationItem.link);
                let allowed = false;
                if (permissions && permissions.length) {
                    allowed = this._userDetailsService.hasAnyPermission(...permissions);
                }

                shouldHide = !(allowed && this.requireModuleActive(navigationItem));
            }
        }

        this.shouldHideCache.set(navigationItem.id, shouldHide);

        return shouldHide;
    }

    private canSeeExternalLink(navigationItem: FuseNavigationItem): boolean {
        if (navigationItem.id === "smartRetia") {
            return this._userDetailsService.hasAllPermissions(RoleConstants.SMARTRETIA_READ.valueOf());
        }

        return false;
    }

    private requireModuleActive(item: FuseNavigationItem): boolean {
        const menuTitleClasses = item.classes?.title;

        if (!menuTitleClasses) {
            //Si no requiere modulo
            return true;
        }

        if (this.instanceActive) {
            switch (menuTitleClasses) {
                case CodeModuleConstants.HOTSPOT:
                    return this.instanceActive.hotspot;
                case CodeModuleConstants.ROOM_XPERIENCE:
                    return this.instanceActive.smartroom;
                case CodeModuleConstants.INTERACTIVE_TV:
                    return this.instanceActive.iptv;
                case CodeModuleConstants.SRVMGM:
                    return this.instanceActive.service;
                case CodeModuleConstants.CAST:
                    return this.instanceActive.cast;
                case CodeModuleConstants.BOOKING:
                    return this.instanceActive.booking;
                case CodeModuleConstants.ANALYTICS:
                    return !!this.instanceActive.brandActive || !!this.instanceActive.hotelActive;
                case CodeModuleConstants.NETFLOW:
                    return this.instanceActive.netflow;
                default:
                    return true;
            }
        }

        return false;
    }
}
