import { UrlSegment } from "@angular/router";

export class UriUtils {
    constructor() {}

    static appendParts(host: string, ...parts: string[]): string {
        let builtUrl = host;

        if (builtUrl.endsWith("/")) {
            builtUrl = builtUrl.slice(0, -1);
        }

        parts.forEach(part => {
            if (!part.startsWith("/")) {
                part = "/" + part;
            }

            builtUrl += part;
        });

        return builtUrl;
    }

    static getLocationQueryParameters(): Array<{ name: string; value: string }> {
        let result = [];
        location.search
            .slice(1)
            .split("&")
            .forEach(function (item) {
                let tmp = item.split("=");

                result.push({
                    name: tmp[0],
                    value: tmp.length > 1 ? decodeURIComponent(tmp[1]) : null,
                });
            });
        return result;
    }

    static findParameterName(parameterName: string): { name: string; value: string } {
        return UriUtils.getLocationQueryParameters().find(param => param.name === parameterName);
    }

    static appendParameter(url: URL | string, name: string, value: string): URL {
        const theUrl = new URL(url);

        theUrl.searchParams.append(name, value);

        return theUrl;
    }

    static appendParameters(url: URL | string, nameValues: Array<{ name: string; value: string }>): URL {
        const theUrl = new URL(url);

        for (let nameValue of nameValues) {
            theUrl.searchParams.append(nameValue.name, nameValue.value);
        }

        return theUrl;
    }

    static extractPaths(url: string, segments?: UrlSegment[]): Array<String> {
        const re = /\//gi;

        //remove params if it exists for evaluate permissions
        if (url && url != "") {
            url = url.split("?").filter(item => item.trim() !== "")[0];
        }

        if (segments) {
            url = this.getAbsolutePath(url, segments);
        }

        return url.split(re).filter(item => item.trim() !== "");
    }

    private static getAbsolutePath(parent: string, segments: UrlSegment[]): string {
        let basePath = parent.split("/").filter(item => item.trim() !== "");

        basePath = basePath.concat(segments.map(s => s.path));

        return "/" + basePath.join("/");
    }
}
