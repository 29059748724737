import { Route } from "@angular/router";
import { LayoutComponent } from "app/layout/layout.component";
import { LogoutComponent } from "./modules/auth/components/logout/logout.component";
import { Error404Component } from "./shared/components/error-404/error-404.component";
import { ForbiddenComponent } from "./shared/components/forbidden/forbidden.component";
import { RouteConstants } from "./core/constants/route-constants";
import { CodeModuleConstants } from "./core/constants/code-module-constants";

export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: "", pathMatch: "full", redirectTo: "home" },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: "signed-in-redirect", pathMatch: "full", redirectTo: "home" },
    {
        path: "",
        component: LayoutComponent,
        children: [
            {
                path: "home",
                loadChildren: () => import("app/modules/landing/home/home.module").then(m => m.LandingHomeModule),
            },
        ],
    },

    {
        path: "user",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/user/user.module").then(m => m.UserModule),
    },

    {
        path: "company",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/company/company.module").then(m => m.CompanyModule),
    },

    {
        path: "brand",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/brand/brand.module").then(m => m.BrandModule),
    },

    {
        path: "hotel",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/hotel/hotel.module").then(m => m.HotelModule),
    },

    {
        path: "hotel-structure",
        component: LayoutComponent,
        loadChildren: () =>
            import("app/modules/hotel-structure/hotel-structure.module").then(m => m.HotelStructureModule),
    },

    {
        path: "email-template",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/email-template/email-template.module").then(m => m.EmailTemplateModule),
    },

    {
        path: "currency",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/currency/currency.module").then(m => m.CurrencyModule),
    },
    {
        path: "faq",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/faq/faq.module").then(m => m.FaqModule),
    },

    {
        path: "manual",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/manual/manual.module").then(m => m.ManualModule),
    },
    {
        path: "api-pricing-plan",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/pricing-plan/pricing-plan.module").then(m => m.PricingPlanModule),
    },
    {
        path: "pms",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/pms/pms.module").then(m => m.PmsModule),
    },
    {
        path: "updater",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/updater/updater.module").then(m => m.UpdaterModule),
    },
    {
        path: "utils",
        component: LayoutComponent,
        children: [
            {
                path: "mail",
                loadChildren: () =>
                    import("app/modules/utils/modules/mail/utils-mail.module").then(m => m.UtilsMailModule),
            },
            {
                path: "module-info",
                loadChildren: () =>
                    import("app/modules/utils/modules/module-info/module-info.module").then(m => m.ModuleInfoModule),
            },
            {
                path: "permissions",
                loadChildren: () =>
                    import("app/modules/utils/modules/permission/permission.module").then(m => m.PermissionModule),
            },
            {
                path: "changelog",
                loadChildren: () =>
                    import("app/modules/utils/modules/changelog-admin/changelog-admin.module").then(
                        m => m.ChangelogAdminModule
                    ),
            },
            {
                path: "country",
                loadChildren: () =>
                    import("app/modules/utils/modules/country/utils-country.module").then(m => m.UtilsCountryModule),
            },
            {
                path: "mac-info",
                loadChildren: () =>
                    import("app/modules/utils/modules/mac-info/utils-mac-info.module").then(m => m.UtilsMacInfoModule),
            },
            {
                path: "hw-monitor",
                loadChildren: () =>
                    import("app/modules/utils/modules/hw-monitor/hwMonitor.module").then(m => m.HwMonitorModule),
            },
            {
                path: "system-audit",
                loadChildren: () =>
                    import("app/modules/utils/modules/system-audit/utils-system-audit.module").then(
                        m => m.UtilsSystemAuditModule
                    ),
            },
            {
                path: "logging",
                loadChildren: () =>
                    import("app/modules/utils/modules/logging/logging.module").then(m => m.LoggingModule),
            },
            {
                path: "alert",
                loadChildren: () => import("app/modules/utils/modules/alert/alert.module").then(m => m.AlertModule),
            },
        ],
    },
    {
        path: "module",
        component: LayoutComponent,
        children: [
            { path: "", pathMatch: "full", redirectTo: "home" },
            {
                path: "home",
                data: {
                    fullPath: RouteConstants.MODULE_HOME,
                },
                loadChildren: () => import("app/modules/module-home/module-home.module").then(m => m.ModuleHomeModule),
            },

            /** IPTV: */
            {
                /**HOME */
                path: RouteConstants.ROUTE_INTERACTIVE_TV,
                data: {
                    fullPath: RouteConstants.MODULE_INTERACTIVE_TV,
                },
                loadChildren: () =>
                    import("app/modules/iptv/modules/home/iptv-home.module").then(m => m.IptvHomeModule),
            },
            {
                path: RouteConstants.ROUTE_INTERACTIVE_TV + "/setting",
                data: {
                    module: CodeModuleConstants.INTERACTIVE_TV,
                    fullPath: RouteConstants.MODULE_INTERACTIVE_TV + "/setting",
                },
                loadChildren: () =>
                    import("app/modules/module-setting/module-setting.module").then(m => m.ModuleSettingModule),
            },
            {
                path: RouteConstants.ROUTE_INTERACTIVE_TV + "/device-data",
                data: {
                    fullPath: RouteConstants.MODULE_INTERACTIVE_TV + "/device-data",
                },
                loadChildren: () =>
                    import("app/modules/iptv/modules/device-data/device-data.module").then(m => m.DeviceDataModule),
            },

            /** HOTSPOT: */
            {
                /** HOME */
                path: RouteConstants.ROUTE_HOTSPOT,
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT,
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/home/hotspot-home.module").then(m => m.HotspotHomeModule),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/setting",
                data: {
                    module: CodeModuleConstants.HOTSPOT,
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/setting",
                },
                loadChildren: () =>
                    import("app/modules/module-setting/module-setting.module").then(m => m.ModuleSettingModule),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/whitelist",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/whitelist",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/whitelist/whitelist.module").then(m => m.WhitelistModule),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/blacklist",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/blacklist",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/blacklist/blacklist.module").then(m => m.BlacklistModule),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/card",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/card",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/card/hotspot-card.module").then(m => m.HotspotCardModule),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/email-validation",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/email-validation",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/email-validation/email-validation.module").then(
                        m => m.EmailValidationModule
                    ),
            },
            // {
            //     path: RouteConstants.ROUTE_HOTSPOT + "/devices",
            //     data: {
            //         fullPath: RouteConstants.MODULE_HOTSPOT + "/devices",
            //     },
            //     loadChildren: () =>
            //         import("app/modules/hotspot/modules/devices/devices.module").then(m => m.DevicesModule),
            // },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/status",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/status",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/status/hotspot-status.module").then(m => m.HotspotStatusModule),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/profile",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/profile",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/profile/hotspot-profile.module").then(
                        m => m.HotspotProfileModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/server",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/server",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/server/server.module").then(m => m.ServerModule),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/card-transaction",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/card-transaction",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/card-transaction/card-transaction.module").then(
                        m => m.CardTransactionModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/card-template",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/card-template",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/card-template/hotspot-card-template.module").then(
                        m => m.HotspotCardTemplateModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/cms/ssid",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/cms/ssid",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/hotspot-cms-ssid/hotspot-cms-ssid.module").then(
                        m => m.HotspotCmsModuleModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/cms/template",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/cms/template",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/hotspot-cms-template/hotspot-cms-template.module").then(
                        m => m.HotspotCmsTemplateModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/cms/setting",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/cms/setting",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/hotspot-cms-setting/hotspot-cms-setting.module").then(
                        m => m.HotspotCmsSettingModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/cms/literals",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/cms/literals",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/hotspot-cms-literal/hotspot-cms-literal.module").then(
                        m => m.HotspotCmsLiteralModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/nas-radius",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/nas-radius",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/nas-radius/nas-radius.module").then(m => m.NasRadiusModule),
            },
            {
                path: RouteConstants.ROUTE_HOTSPOT + "/router",
                data: {
                    fullPath: RouteConstants.MODULE_HOTSPOT + "/router",
                },
                loadChildren: () =>
                    import("app/modules/hotspot/modules/router/hotspot-router.module").then(m => m.HotspotRouterModule),
            },

            /** ROOM XPERIENCE: */
            {
                /**HOME */
                path: RouteConstants.ROUTE_ROOM_XPERIENCE,
                data: {
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE,
                },
                loadChildren: () =>
                    import("app/modules/room-xperience/modules/home/room-xperience-home.module").then(
                        m => m.RoomXperienceHomeModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/global-setting",
                data: {
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/global-setting",
                },
                loadChildren: () =>
                    import(
                        "app/modules/room-xperience/modules/room-xperience-global-setting/room-xperience-global-setting.module"
                    ).then(m => m.RoomXperienceGlobalSettingModule),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/setting",
                data: {
                    module: CodeModuleConstants.CLEAN_ENERGY,
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/setting",
                },
                loadChildren: () =>
                    import("app/modules/module-setting/module-setting.module").then(m => m.ModuleSettingModule),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/room-status",
                data: {
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/room-status",
                },
                loadChildren: () =>
                    import("app/modules/room-xperience/modules/room-status/room-status.module").then(
                        m => m.RoomStatusModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/room-status-mqtt",
                data: {
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/room-status-mqtt",
                },
                loadChildren: () =>
                    import("app/modules/room-xperience/modules/room-status-mqtt/room-status-mqtt.module").then(
                        m => m.RoomStatusMqttModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/thermostat",
                data: {
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/thermostat",
                },
                loadChildren: () =>
                    import("app/modules/room-xperience/modules/thermostat/thermostat.module").then(
                        m => m.ThermostatModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/logo",
                data: {
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/logo",
                },
                loadChildren: () =>
                    import("app/modules/room-xperience/modules/logo/logo.module").then(m => m.LogoModule),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/server-config",
                data: {
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/server-config",
                },
                loadChildren: () =>
                    import("app/modules/room-xperience/modules/server-config/server-config.module").then(
                        m => m.ServerConfigModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/translation",
                data: {
                    module: CodeModuleConstants.CLEAN_ENERGY,
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/translation",
                },
                loadChildren: () =>
                    import("app/modules/module-translation/module-translation.module").then(
                        m => m.ModuleTranslationModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/room-maintenance",
                data: {
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/room-maintenance",
                },
                loadChildren: () =>
                    import(
                        "app/modules/room-xperience/modules/room-xperience-room-maintenance/room-xperience-room-maintenance.module"
                    ).then(m => m.RoomXperienceRoomMaintenanceModule),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/room-service",
                data: {
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/room-service",
                },
                loadChildren: () =>
                    import(
                        "app/modules/room-xperience/modules/room-xperience-room-service/room-xperience-room-service.module"
                    ).then(m => m.RoomXperienceRoomServiceModule),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/controller",
                data: {
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/controller",
                },
                loadChildren: () =>
                    import("app/modules/room-xperience/modules/controller/controller.module").then(
                        m => m.ControllerModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_ROOM_XPERIENCE + "/check-installation",
                data: {
                    fullPath: RouteConstants.MODULE_ROOM_XPERIENCE + "/check-installation",
                },
                loadChildren: () =>
                    import(
                        "app/modules/room-xperience/modules/room-xperience-check-installation/room-xperience-check-installation.module"
                    ).then(m => m.RoomXperienceCheckInstallationModule),
            },

            /** SRVMGM: */
            {
                /** HOME */
                path: RouteConstants.ROUTE_SRVMGM,
                data: {
                    fullPath: RouteConstants.MODULE_SRVMGM,
                },
                loadChildren: () =>
                    import("app/modules/srvmgm/modules/srvmgm-home/srvmgm-home.module").then(m => m.SrvmgmHomeModule),
            },
            {
                path: RouteConstants.ROUTE_SRVMGM + "/setting",
                data: {
                    module: CodeModuleConstants.SRVMGM,
                    fullPath: RouteConstants.MODULE_SRVMGM + "/setting",
                },
                loadChildren: () =>
                    import("app/modules/module-setting/module-setting.module").then(m => m.ModuleSettingModule),
            },
            {
                path: RouteConstants.ROUTE_SRVMGM + "/media",
                data: {
                    fullPath: RouteConstants.MODULE_SRVMGM + "/media",
                },
                loadChildren: () =>
                    import("app/modules/srvmgm/modules/media-list/media-list.module").then(m => m.MediaListModule),
            },
            {
                path: RouteConstants.ROUTE_SRVMGM + "/channel",
                data: {
                    fullPath: RouteConstants.MODULE_SRVMGM + "/channel",
                },
                loadChildren: () =>
                    import("app/modules/srvmgm/modules/media-list/media-list.module").then(m => m.MediaListModule),
            },
            {
                path: RouteConstants.ROUTE_SRVMGM + "/app",
                data: {
                    fullPath: RouteConstants.MODULE_SRVMGM + "/app",
                },
                loadChildren: () =>
                    import("app/modules/srvmgm/modules/media-list/media-list.module").then(m => m.MediaListModule),
            },
            {
                path: RouteConstants.ROUTE_SRVMGM + "/url",
                data: {
                    fullPath: RouteConstants.MODULE_SRVMGM + "/url",
                },
                loadChildren: () =>
                    import("app/modules/srvmgm/modules/media-list/media-list.module").then(m => m.MediaListModule),
            },
            {
                path: RouteConstants.ROUTE_SRVMGM + "/page",
                data: {
                    fullPath: RouteConstants.MODULE_SRVMGM + "/page",
                },
                loadChildren: () => import("app/modules/srvmgm/modules/page/page.module").then(m => m.PageModule),
            },
            {
                path: RouteConstants.ROUTE_SRVMGM + "/translation",
                data: {
                    module: CodeModuleConstants.SRVMGM,
                    fullPath: RouteConstants.MODULE_SRVMGM + "/translation",
                },
                loadChildren: () =>
                    import("app/modules/module-translation/module-translation.module").then(
                        m => m.ModuleTranslationModule
                    ),
            },

            /** CAST: */
            {
                /** HOME */
                path: RouteConstants.ROUTE_CAST,
                data: {
                    fullPath: RouteConstants.MODULE_CAST,
                },
                loadChildren: () =>
                    import("app/modules/cast/module/cast-home/cast-home.module").then(m => m.CastHomeModule),
            },
            {
                path: RouteConstants.ROUTE_CAST + "/setting",
                data: {
                    module: CodeModuleConstants.CAST,
                    fullPath: RouteConstants.MODULE_CAST + "/setting",
                },
                loadChildren: () =>
                    import("app/modules/module-setting/module-setting.module").then(m => m.ModuleSettingModule),
            },
            {
                path: RouteConstants.ROUTE_CAST + "/chromecast-config",
                data: {
                    fullPath: RouteConstants.MODULE_CAST + "/chromecast-config",
                },
                loadChildren: () =>
                    import("app/modules/cast/module/chromecast-config/chromecast-config.module").then(
                        m => m.ChromecastConfigModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_CAST + "/chromecast",
                data: {
                    fullPath: RouteConstants.MODULE_CAST + "/chromecast",
                },
                loadChildren: () =>
                    import("app/modules/cast/module/chromecast/chromecast.module").then(m => m.ChromecastModule),
            },
            {
                path: RouteConstants.ROUTE_CAST + "/data-usage",
                data: {
                    fullPath: RouteConstants.MODULE_CAST + "/data-usage",
                },
                loadChildren: () =>
                    import("app/modules/cast/module/data-usage/data-usage.module").then(m => m.DataUsageModule),
            },
            {
                path: RouteConstants.ROUTE_CAST + "/guest-device",
                data: {
                    fullPath: RouteConstants.MODULE_CAST + "/guest-device",
                },
                loadChildren: () =>
                    import("app/modules/cast/module/guest-device/guest-device.module").then(m => m.GuestDeviceModule),
            },

            /** NETFLOW: */
            {
                /**HOME */
                path: RouteConstants.ROUTE_NETFLOW,
                data: {
                    fullPath: RouteConstants.MODULE_NETFLOW,
                },
                loadChildren: () =>
                    import("app/modules/netflow/modules/home/netflow-home.module").then(m => m.NetflowHomeModule),
            },
            {
                path: RouteConstants.ROUTE_NETFLOW + "/setting",
                data: {
                    module: CodeModuleConstants.NETFLOW,
                    fullPath: RouteConstants.MODULE_NETFLOW + "/setting",
                },
                loadChildren: () =>
                    import("app/modules/module-setting/module-setting.module").then(m => m.ModuleSettingModule),
            },

            /** BOOKING: */
            {
                /** HOME */
                path: RouteConstants.ROUTE_BOOKING,
                data: {
                    fullPath: RouteConstants.ROUTE_BOOKING,
                },
                loadChildren: () =>
                    import("app/modules/booking/modules/booking-home/booking-home.module").then(
                        m => m.BookingHomeModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_BOOKING + "/global-setting",
                data: {
                    module: CodeModuleConstants.BOOKING,
                    fullPath: RouteConstants.ROUTE_BOOKING + "/global-setting",
                },
                loadChildren: () =>
                    import("app/modules/module-setting/module-setting.module").then(m => m.ModuleSettingModule),
            },
            {
                path: RouteConstants.ROUTE_BOOKING + "/hotel-setting",
                data: {
                    module: CodeModuleConstants.BOOKING,
                    fullPath: RouteConstants.ROUTE_BOOKING + "/hotel-setting",
                },
                loadChildren: () =>
                    import("app/modules/booking/modules/booking-hotel-setting/booking-hotel-setting.module").then(
                        m => m.BookingHotelSettingModule
                    ),
            },
            {
                path: RouteConstants.ROUTE_BOOKING + "/hotel-info",
                data: {
                    module: CodeModuleConstants.BOOKING,
                    fullPath: RouteConstants.ROUTE_BOOKING + "/hotel-info",
                },
                loadChildren: () =>
                    import("app/modules/booking/modules/booking-hotel-info/booking-hotel-info.module").then(
                        m => m.BookingHotelInfoModule
                    ),
            },

            /** NOC: */
            {
                /** HOME */
                path: "home-noc",
                data: {
                    fullPath: "/module/home-noc",
                },
                loadChildren: () => import("app/modules/noc/modules/home/home-noc.module").then(m => m.HomeNocModule),
            },
        ],
    },

    {
        path: "help-center",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/help-center/help-center.module").then(m => m.HelpCenterModule),
    },

    {
        path: "changelog",
        component: LayoutComponent,
        loadChildren: () => import("app/modules/changelog/changelog.module").then(m => m.ChangelogModule),
    },

    {
        path: "welcome",
        component: LayoutComponent,
        children: [
            {
                path: "company",
                loadChildren: () =>
                    import("app/modules/welcome/company/welcome-company.module").then(m => m.WelcomeCompanyModule),
            },
            {
                path: "brand",
                loadChildren: () =>
                    import("app/modules/welcome/brand/welcome-brand.module").then(m => m.WelcomeBrandModule),
            },
            {
                path: "hotel",
                loadChildren: () =>
                    import("app/modules/welcome/hotel/welcome-hotel.module").then(m => m.WelcomeHotelModule),
            },
        ],
    },

    /*{
        path: "analytics/hotspot/email-marketing",
        component: LayoutComponent,
        loadChildren: () =>
            import("app/modules/analytics/ico-hotspot/email-marketing/email-marketing.module").then(
                m => m.EmailMarketingModule
            ),
    },

    {
        path: "analytics/hotspot/stat-sales",
        component: LayoutComponent,
        loadChildren: () =>
            import("app/modules/analytics/ico-hotspot/stat-sales/stat-sales.module").then(m => m.StatSalesModule),
    },*/

    {
        path: "analytics/hotspot/traffic-bi",
        component: LayoutComponent,
        loadChildren: () =>
            import("app/modules/analytics/ico-hotspot/traffic-bi/traffic-bi.module").then(m => m.TrafficBIModule),
    },

    {
        path: "analytics/roomxperience/room-efficiency",
        component: LayoutComponent,
        loadChildren: () =>
            import("app/modules/analytics/ico-roomxperience/room-efficiency/room-efficiency.module").then(
                m => m.RoomEfficiencyModule
            ),
    },

    /*{
        path: "analytics/roomxperience/room-maintenance",
        component: LayoutComponent,
        loadChildren: () =>
            import("app/modules/analytics/ico-roomxperience/room-maintenance/room-maintenance.module").then(
                m => m.RoomMaintenanceModule
            ),
    },

    {
        path: "analytics/hotspot/data-analytic",
        component: LayoutComponent,
        loadChildren: () =>
            import("app/modules/analytics/ico-hotspot/data-analytic/data-analytic.module").then(
                m => m.DataAnalyticModule
            ),
    },

    {
        path: "analytics/hotspot/wifi-global-data",
        component: LayoutComponent,
        loadChildren: () =>
            import("app/modules/analytics/ico-hotspot/wifi-global-data/wifi-global-data.module").then(
                m => m.WifiGlobalDataModule
            ),
    },*/

    // Auth routes for guests
    {
        path: "",
        component: LayoutComponent,
        data: {
            layout: "empty",
        },
        children: [
            //{ path: "logout", component: LogoutComponent, canActivate: [AuthGuard] },
            { path: "logout", component: LogoutComponent },
        ],
    },

    { path: "forbidden", component: ForbiddenComponent },

    //Wild Card Route for 404 request
    { path: "**", pathMatch: "full", component: Error404Component },
];
