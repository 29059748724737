import { TranslocoService } from "@ngneat/transloco";
import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AfterViewInit } from "@angular/core";

@Component({
    selector: "monaco-editor",
    templateUrl: "./monaco-editor.component.html",
    styleUrls: ["./monaco-editor.component.scss"],
})
export class MonacoEditorComponent implements OnDestroy, AfterViewInit {
    @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(): void {
        if (this.expanded) {
            this.monacoEditor.nativeElement.classList.remove(this.classEditorFullscreen);
            this.changeZIndexMenuAndToolbar("1000", "4");
            this.expanded = false;
        }
    }
    @ViewChild("monacoEditor", { read: ElementRef }) monacoEditor: ElementRef;

    @Input() title: string;
    @Input() options: any;
    @Input() model: any;
    @Input() height: any;
    @Input() name: any;
    @Input() required: boolean = false;

    @Output() changeEditorEmitter = new EventEmitter<any>();

    private classEditorFullscreen: string = "editor-fullscreen";
    private tagSidebar: string = "fuse-vertical-navigation";
    // private tagToolbar: string = "fuse-vertical-navigation";
    private expanded: boolean = false;

    constructor(
        private translocoService: TranslocoService,
        private toastrService: ToastrService,
        private renderer: Renderer2
    ) {}

    ngAfterViewInit(): void {
        if (this.height) {
            this.renderer.setStyle(this.monacoEditor.nativeElement, "height", this.height + "vh");
        }
    }

    ngOnDestroy(): void {
        this.changeEditorEmitter.unsubscribe();
    }

    public modelChange(): void {
        this.changeEditorEmitter.emit(this.model);
    }

    public expand(): void {
        // set fullscreen
        this.monacoEditor.nativeElement.classList.add(this.classEditorFullscreen);
        this.changeZIndexMenuAndToolbar("-1", "-1");
        this.toastrService.warning(this.translocoService.translate("alert.editorPressEsc"));
        this.expanded = true;
    }

    private changeZIndexMenuAndToolbar(zIndexSidebar: string, zIndexToolbar: string): void {
        let fuseSidebar = document.getElementsByTagName(this.tagSidebar)[0] as HTMLElement;
        fuseSidebar.style.zIndex = zIndexSidebar;
        // let appToolbar = document.getElementsByTagName(this.tagToolbar)[0] as HTMLElement;
        // appToolbar.style.zIndex = zIndexToolbar;
    }
}
