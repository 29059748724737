import { HttpClientModule, HttpRequest } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, RouterModule } from "@angular/router";
import { FuseModule } from "@fuse";
import { FuseConfigModule } from "@fuse/services/config";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import { appConfig } from "app/core/config/app.config";
import { CoreModule } from "app/core/core.module";
import { LayoutModule } from "app/layout/layout.module";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { ToastrModule } from "ngx-toastr";
import { ConfigurationService } from "./core/config/configuration.service";
import { IcosaedroConfig } from "./core/models/icosaedro-config.model";
import { AuthService } from "./core/services/auth.service";
import { LoggerService } from "./core/services/logger.service";
import { KeycloakAngularModule, KeycloakService, KeycloakEvent, KeycloakEventType } from "keycloak-angular";
import { AuthModule } from "./modules/auth/auth.module";
import { RoleBasedPreloader } from "./role-based.preloading";
import cookie from "cookiejs";

const routerConfig: ExtraOptions = {
    //preloadingStrategy: PreloadAllModules,
    preloadingStrategy: RoleBasedPreloader,
    scrollPositionRestoration: "disabled",
    //initialNavigation: "enabledNonBlocking",
};

function initializeAll(
    configService: ConfigurationService,
    keycloak: KeycloakService,
    loggerService: LoggerService,
    authService: AuthService
): () => Promise<any> {
    return async () => {
        //Se hace aqui en lugar de un listener porque si no, no recibe eventos
        keycloak.keycloakEvents$.subscribe((keycloakEvent: KeycloakEvent) => {
            if (keycloakEvent.type == KeycloakEventType.OnTokenExpired) {
                console.debug("El token ha expirado, actualizando");
                keycloak.updateToken().then(async updated => {
                    console.debug("El token ha sido actualizado?: " + updated);
                });
            } else if (keycloakEvent.type === KeycloakEventType.OnReady) {
                console.debug("KC esta Ready?", keycloakEvent.args);
            }
        });

        return configService
            .load()
            .then((icoConfig: IcosaedroConfig) => {
                return keycloak
                    .init({
                        config: {
                            url: icoConfig.keycloak.url,
                            realm: icoConfig.keycloak.realm,
                            clientId: icoConfig.keycloak.clientId,
                        },
                        initOptions: {
                            onLoad: "check-sso",
                            checkLoginIframe: true,
                            silentCheckSsoRedirectUri: window.location.origin + "/assets/silent-check-sso.html",
                        },
                        shouldAddToken: (req: HttpRequest<unknown>): boolean => {
                            const { method, url } = req;

                            const isGetRequest = "GET" === method.toUpperCase();
                            const acceptablePaths = ["/assets", "assets/", "./assets", "/logout", "/forbidden"];
                            const isAcceptablePathMatch = acceptablePaths.some(path => url.includes(path));

                            return !(isGetRequest && isAcceptablePathMatch);
                        },
                    })
                    .then(authenticated => {
                        loggerService.debug("El usuario ya se encuentra autenticado? " + authenticated);

                        if (!authenticated) {
                            keycloak.clearToken();
                        }

                        return authenticated;
                    })
                    .catch(error => {
                        loggerService.error("No se pudo autenticar el usuario", error);
                        return error;
                    });
            })
            .then(async authenticated => {
                if (authenticated) {
                    try {
                        await authService.loadCurrentUser();
                    } catch (e) {
                        loggerService.error("Error al cargar el usuario", e);
                    }
                }

                return authenticated;
            })
            .catch(error => {
                loggerService.error("No se pudo cargar la configuracion", error);
                return error;
            });
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        KeycloakAngularModule,

        // Fuse, FuseConfig
        FuseModule,
        FuseConfigModule.forRoot(appConfig),

        LoggerModule.forRoot({
            level: NgxLoggerLevel.DEBUG,
        }),

        ToastrModule.forRoot({
            closeButton: true,
        }),

        // Core module of your application
        CoreModule,
        AuthModule,

        // Layout module of your application
        LayoutModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAll,
            deps: [ConfigurationService, KeycloakService, LoggerService, AuthService],
            multi: true,
        },
    ],
})
export class AppModule { }
