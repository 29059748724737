/**
 * Este json contiene todas las rutas del aplicativo que estén securizadas.
 * SOLO las rutas que requieren un permiso para ser accesibles.
 */

import { AppPermissionItem } from "../models/app-permission-item.model";

export const AppPermissions: AppPermissionItem[] = [
    {
        segment: "user",
        permissions: ["USER_LIST"],
        children: [
            {
                segment: "list",
                permissions: ["USER_LIST"],
                children: [],
            },
            {
                segment: "view",
                permissions: ["USER_READ"],
                children: [],
            },
            {
                segment: "edit",
                permissions: ["USER_UPDATE"],
                children: [],
            },
            {
                segment: "add",
                permissions: ["USER_CREATE"],
                children: [],
            },
            {
                segment: "profile",
                permissions: ["USER_READ", "USER_UPDATE"],
                children: [],
            },
        ],
    },
    {
        segment: "company",
        permissions: ["COMPANY_LIST"],
        children: [
            {
                segment: "list",
                permissions: ["COMPANY_LIST"],
                children: [],
            },
            {
                segment: "add",
                permissions: ["COMPANY_CREATE"],
                children: [],
            },
            {
                segment: "edit",
                permissions: ["COMPANY_UPDATE"],
                children: [],
            },
            {
                segment: "view",
                permissions: ["COMPANY_READ"],
                children: [],
            },
        ],
    },
    {
        segment: "brand",
        permissions: ["BRAND_LIST"],
        children: [
            {
                segment: "list",
                permissions: ["BRAND_LIST"],
                children: [],
            },
            {
                segment: "add",
                permissions: ["BRAND_CREATE"],
                children: [],
            },
            {
                segment: "edit",
                permissions: ["BRAND_UPDATE"],
                children: [],
            },
            {
                segment: "view",
                permissions: ["BRAND_READ"],
                children: [],
            },
        ],
    },
    {
        segment: "hotel",
        permissions: ["HOTEL_LIST"],
        children: [
            {
                segment: "list",
                permissions: ["HOTEL_LIST"],
                children: [],
            },
            {
                segment: "add",
                permissions: ["HOTEL_CREATE"],
                children: [],
            },
            {
                segment: "edit",
                permissions: ["HOTEL_UPDATE"],
                children: [],
            },
            {
                segment: "view",
                permissions: ["HOTEL_READ"],
                children: [],
            },
        ],
    },
    {
        segment: "hotel-structure",
        permissions: null,
        children: [
            {
                segment: "building",
                permissions: ["ARQ_HOTEL_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["ARQ_HOTEL_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["ARQ_HOTEL_CREATE"],
                        children: [],
                    },
                    {
                        segment: "edit",
                        permissions: ["ARQ_HOTEL_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "view",
                        permissions: ["ARQ_HOTEL_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "floor",
                permissions: ["ARQ_HOTEL_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["ARQ_HOTEL_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["ARQ_HOTEL_CREATE"],
                        children: [],
                    },
                    {
                        segment: "edit",
                        permissions: ["ARQ_HOTEL_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "view",
                        permissions: ["ARQ_HOTEL_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "room",
                permissions: ["ARQ_HOTEL_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["ARQ_HOTEL_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["ARQ_HOTEL_CREATE"],
                        children: [],
                    },
                    {
                        segment: "edit",
                        permissions: ["ARQ_HOTEL_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "view",
                        permissions: ["ARQ_HOTEL_READ"],
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        segment: "currency",
        permissions: ["CURRENCY_LIST"],
        children: [
            {
                segment: "list",
                permissions: ["CURRENCY_LIST"],
                children: [],
            },
            {
                segment: "add",
                permissions: ["CURRENCY_CREATE"],
                children: [],
            },
            {
                segment: "edit",
                permissions: ["CURRENCY_UPDATE"],
                children: [],
            },
            {
                segment: "view",
                permissions: ["CURRENCY_READ"],
                children: [],
            },
        ],
    },
    {
        segment: "module",
        permissions: [],
        children: [
            {
                segment: "home",
                permissions: ["MODULE_LIST", "TOOLS_NOC_LIST", "TOOLS_RST_LIST"],
                children: [],
            },
            {
                segment: "interactive-tv",
                permissions: [],
                children: [
                    {
                        segment: "setting",
                        permissions: ["INTERACTIVE_TV_SETTINGS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["INTERACTIVE_TV_SETTINGS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["INTERACTIVE_TV_SETTINGS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["INTERACTIVE_TV_SETTINGS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["INTERACTIVE_TV_SETTINGS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "device-data",
                        permissions: ["INTERACTIVE_TV_DEVICE_STATS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["INTERACTIVE_TV_DEVICE_STATS_LIST"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["INTERACTIVE_TV_DEVICE_STATS_UPDATE"],
                                children: [],
                            },
                        ],
                    },
                ],
            },
            {
                segment: "hotspot",
                permissions: [],
                children: [
                    {
                        segment: "setting",
                        permissions: ["HOTSPOT_SETTINGS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["HOTSPOT_SETTINGS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["HOTSPOT_SETTINGS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["HOTSPOT_SETTINGS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["HOTSPOT_SETTINGS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "blacklist",
                        permissions: ["HOTSPOT_BLACKLIST_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["HOTSPOT_BLACKLIST_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["HOTSPOT_BLACKLIST_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["HOTSPOT_BLACKLIST_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["HOTSPOT_BLACKLIST_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "whitelist",
                        permissions: ["HOTSPOT_WHITELIST_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["HOTSPOT_WHITELIST_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["HOTSPOT_WHITELIST_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["HOTSPOT_WHITELIST_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["HOTSPOT_WHITELIST_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "card",
                        permissions: ["HOTSPOT_CARD_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["HOTSPOT_CARD_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["HOTSPOT_CARD_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["HOTSPOT_CARD_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["HOTSPOT_CARD_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "profile",
                        permissions: ["HOTSPOT_PROFILE_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["HOTSPOT_PROFILE_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["HOTSPOT_PROFILE_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["HOTSPOT_PROFILE_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["HOTSPOT_PROFILE_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "status",
                        permissions: ["HOTSPOT_STATUS_READ"],
                    },
                    {
                        segment: "email-validation",
                        permissions: ["HOTSPOT_EMAIL_VALIDATION_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["HOTSPOT_EMAIL_VALIDATION_LIST"],
                                children: [],
                            },
                        ],
                    },
                    // {
                    //     segment: "devices",
                    //     permissions: ["HOTSPOT_DEVICES_LIST"],
                    //     children: [
                    //         {
                    //             segment: "list",
                    //             permissions: ["HOTSPOT_DEVICES_LIST"],
                    //             children: [],
                    //         },
                    //     ],
                    // },
                    {
                        segment: "server",
                        permissions: ["HOTSPOT_SERVER_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["HOTSPOT_SERVER_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["HOTSPOT_SERVER_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["HOTSPOT_SERVER_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["HOTSPOT_SERVER_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "card-transaction",
                        permissions: ["HOTSPOT_CARD_TRANSACTION_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["HOTSPOT_CARD_TRANSACTION_LIST"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["HOTSPOT_CARD_TRANSACTION_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "card-template",
                        permissions: ["HOTSPOT_CARD_TEMPLATE_READ"],
                        children: [],
                    },
                    {
                        segment: "cms",
                        permissions: ["HOTSPOT_CMS_LIST"],
                        children: [
                            {
                                segment: "ssid",
                                children: [
                                    {
                                        segment: "list",
                                        permissions: ["HOTSPOT_CMS_SSID_LIST"],
                                        children: [],
                                    },
                                    {
                                        segment: "add",
                                        permissions: ["HOTSPOT_CMS_SSID_CREATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "edit",
                                        permissions: ["HOTSPOT_CMS_SSID_UPDATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "view",
                                        permissions: ["HOTSPOT_CMS_SSID_READ"],
                                        children: [],
                                    },
                                ],
                            },
                            {
                                segment: "template",
                                children: [
                                    {
                                        segment: "list",
                                        permissions: ["HOTSPOT_CMS_TEMPLATE_LIST"],
                                        children: [],
                                    },
                                    {
                                        segment: "add",
                                        permissions: ["HOTSPOT_CMS_TEMPLATE_CREATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "edit",
                                        permissions: ["HOTSPOT_CMS_TEMPLATE_UPDATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "view",
                                        permissions: ["HOTSPOT_CMS_TEMPLATE_READ"],
                                        children: [],
                                    },
                                ],
                            },
                            {
                                segment: "literals",
                                children: [
                                    {
                                        segment: "list",
                                        permissions: ["HOTSPOT_CMS_LITERALS_LIST"],
                                        children: [],
                                    },
                                    {
                                        segment: "add",
                                        permissions: ["HOTSPOT_CMS_LITERALS_CREATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "edit",
                                        permissions: ["HOTSPOT_CMS_LITERALS_UPDATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "view",
                                        permissions: ["HOTSPOT_CMS_LITERALS_READ"],
                                        children: [],
                                    },
                                ],
                            },
                            {
                                segment: "setting",
                                children: [
                                    {
                                        segment: "list",
                                        permissions: ["HOTSPOT_CMS_SETTING_LIST"],
                                        children: [],
                                    },
                                    {
                                        segment: "add",
                                        permissions: ["HOTSPOT_CMS_SETTING_CREATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "edit",
                                        permissions: ["HOTSPOT_CMS_SETTING_UPDATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "view",
                                        permissions: ["HOTSPOT_CMS_SETTING_READ"],
                                        children: [],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        segment: "nas-radius",
                        permissions: ["HOTSPOT_NAS_RADIUS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["HOTSPOT_NAS_RADIUS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["HOTSPOT_NAS_RADIUS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["HOTSPOT_NAS_RADIUS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["HOTSPOT_NAS_RADIUS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "router",
                        permissions: ["HOTSPOT_ROUTER_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["HOTSPOT_ROUTER_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["HOTSPOT_ROUTER_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["HOTSPOT_ROUTER_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["HOTSPOT_ROUTER_READ"],
                                children: [],
                            },
                        ],
                    },
                ],
            },
            {
                segment: "room-xperience",
                permissions: [],
                children: [
                    {
                        segment: "global-setting",
                        permissions: ["CLEAN_ENERGY_GLOBAL_SETTINGS_READ"],
                        children: [
                            {
                                segment: "view",
                                permissions: ["CLEAN_ENERGY_SETTINGS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "setting",
                        permissions: ["CLEAN_ENERGY_SETTINGS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["CLEAN_ENERGY_SETTINGS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["CLEAN_ENERGY_SETTINGS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["CLEAN_ENERGY_SETTINGS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["CLEAN_ENERGY_SETTINGS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "thermostat",
                        permissions: ["CLEAN_ENERGY_MQTT_THERMOSTAT_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["CLEAN_ENERGY_MQTT_THERMOSTAT_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["CLEAN_ENERGY_MQTT_THERMOSTAT_CREATE"],
                                children: [],
                            },
                            {
                                segment: "update",
                                permissions: ["CLEAN_ENERGY_MQTT_THERMOSTAT_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "detail",
                                permissions: ["CLEAN_ENERGY_MQTT_THERMOSTAT_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "logo",
                        permissions: ["CLEAN_ENERGY_MQTT_LOGO_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["CLEAN_ENERGY_MQTT_LOGO_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["CLEAN_ENERGY_MQTT_LOGO_CREATE"],
                                children: [],
                            },
                            {
                                segment: "update",
                                permissions: ["CLEAN_ENERGY_MQTT_LOGO_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "detail",
                                permissions: ["CLEAN_ENERGY_MQTT_LOGO_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "server-config",
                        permissions: ["CLEAN_ENERGY_MQTT_SERVER_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["CLEAN_ENERGY_MQTT_SERVER_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["CLEAN_ENERGY_MQTT_SERVER_CREATE"],
                                children: [],
                            },
                            {
                                segment: "update",
                                permissions: ["CLEAN_ENERGY_MQTT_SERVER_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "detail",
                                permissions: ["CLEAN_ENERGY_MQTT_SERVER_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "controller",
                        permissions: ["CLEAN_ENERGY_CONTROLLER_LIST"],
                        children: [
                            {
                                segment: "command",
                                children: [
                                    {
                                        segment: "list",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_LIST"],
                                        children: [],
                                    },
                                    {
                                        segment: "add",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_CREATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "edit",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_UPDATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "view",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_READ"],
                                        children: [],
                                    },
                                    {
                                        segment: "clone",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_CREATE"],
                                        children: [],
                                    },
                                ],
                            },
                            {
                                segment: "signal",
                                children: [
                                    {
                                        segment: "list",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_LIST"],
                                        children: [],
                                    },
                                    {
                                        segment: "add",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_CREATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "edit",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_UPDATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "view",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_READ"],
                                        children: [],
                                    },
                                    {
                                        segment: "clone",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_CREATE"],
                                        children: [],
                                    },
                                ],
                            },
                            {
                                segment: "signal-mask",
                                children: [
                                    {
                                        segment: "list",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_LIST"],
                                        children: [],
                                    },
                                    {
                                        segment: "add",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_CREATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "edit",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_UPDATE"],
                                        children: [],
                                    },
                                    {
                                        segment: "view",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_READ"],
                                        children: [],
                                    },
                                    {
                                        segment: "clone",
                                        permissions: ["CLEAN_ENERGY_CONTROLLER_CREATE"],
                                        children: [],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        segment: "translation",
                        permissions: ["CLEAN_ENERGY_TRANSLATION_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["CLEAN_ENERGY_TRANSLATION_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["CLEAN_ENERGY_TRANSLATION_CREATE"],
                                children: [],
                            },
                            {
                                segment: "update",
                                permissions: ["CLEAN_ENERGY_TRANSLATION_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "detail",
                                permissions: ["CLEAN_ENERGY_TRANSLATION_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "room-status",
                        permissions: ["CLEAN_ENERGY_ROOM_STATUS_LIST"],
                        children: [],
                    },
                    {
                        segment: "room-status-mqtt",
                        permissions: ["CLEAN_ENERGY_MQTT_ROOM_STATUS_LIST"],
                        children: [],
                    },
                    {
                        segment: "room-maintenance",
                        permissions: ["CLEAN_ENERGY_ROOM_MAINTENANCE_LIST"],
                        children: [],
                    },
                    {
                        segment: "room-service",
                        permissions: ["CLEAN_ENERGY_ROOM_DND_MUR_LIST"],
                        children: [
                            {
                                segment: "update",
                                permissions: ["CLEAN_ENERGY_ROOM_DND_MUR_UPDATE"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "check-installation",
                        permissions: ["CLEAN_ENERGY_CHECK_INSTALLATION_LIST"],
                        children: [
                            {
                                segment: "update",
                                permissions: ["CLEAN_ENERGY_CHECK_INSTALLATION_UPDATE"],
                                children: [],
                            },
                        ],
                    },
                ],
            },
            {
                segment: "srvmgm",
                permissions: [],
                children: [
                    {
                        segment: "setting",
                        permissions: ["SRVMGM_SETTINGS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["SRVMGM_SETTINGS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["SRVMGM_SETTINGS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["SRVMGM_SETTINGS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["SRVMGM_SETTINGS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "url",
                        permissions: ["SRVMGM_MANAGE_MEDIAS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "channel",
                        permissions: ["SRVMGM_MANAGE_MEDIAS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "app",
                        permissions: ["SRVMGM_MANAGE_MEDIAS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["SRVMGM_MANAGE_MEDIAS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "media",
                        permissions: ["SRVMGM_LIST_MEDIAS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["SRVMGM_LIST_MEDIAS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["SRVMGM_LIST_MEDIAS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["SRVMGM_LIST_MEDIAS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["SRVMGM_LIST_MEDIAS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "page",
                        permissions: ["SRVMGM_PAGES_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["SRVMGM_PAGES_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["SRVMGM_PAGES_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["SRVMGM_PAGES_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["SRVMGM_PAGES_READ"],
                                children: [],
                            },
                            {
                                segment: "clone",
                                permissions: ["SRVMGM_PAGES_CREATE"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "translation",
                        permissions: ["SRVMGM_TRANSLATION_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["SRVMGM_TRANSLATION_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["SRVMGM_TRANSLATION_CREATE"],
                                children: [],
                            },
                            {
                                segment: "update",
                                permissions: ["SRVMGM_TRANSLATION_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "detail",
                                permissions: ["SRVMGM_TRANSLATION_READ"],
                                children: [],
                            },
                        ],
                    },
                ],
            },
            {
                segment: "cast",
                permissions: [],
                children: [
                    {
                        segment: "setting",
                        permissions: ["CAST_SETTINGS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["CAST_SETTINGS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["CAST_SETTINGS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["CAST_SETTINGS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["CAST_SETTINGS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "chromecast-config",
                        permissions: ["CAST_CHROMECAST_CONFIG_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["CAST_CHROMECAST_CONFIG_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["CAST_CHROMECAST_CONFIG_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["CAST_CHROMECAST_CONFIG_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["CAST_CHROMECAST_CONFIG_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "chromecast",
                        permissions: ["CAST_CHROMECAST_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["CAST_CHROMECAST_LIST"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["CAST_CHROMECAST_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "data-usage",
                        permissions: ["CAST_DATA_USAGE_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["CAST_DATA_USAGE_LIST"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "guest-device",
                        permissions: ["CAST_GUEST_DEVICE_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["CAST_GUEST_DEVICE_LIST"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["CAST_GUEST_DEVICE_READ"],
                                children: [],
                            },
                        ],
                    },
                ],
            },
            {
                segment: "netflow",
                permissions: [],
                children: [
                    {
                        segment: "setting",
                        permissions: ["NETFLOW_SETTINGS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["NETFLOW_SETTINGS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["NETFLOW_SETTINGS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["NETFLOW_SETTINGS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["NETFLOW_SETTINGS_READ"],
                                children: [],
                            },
                        ],
                    },
                ],
            },
            {
                segment: "booking",
                permissions: [],
                children: [
                    {
                        segment: "global-setting",
                        permissions: ["BOOKING_GLOBAL_SETTINGS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["BOOKING_GLOBAL_SETTINGS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["BOOKING_GLOBAL_SETTINGS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["BOOKING_GLOBAL_SETTINGS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["BOOKING_GLOBAL_SETTINGS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "hotel-setting",
                        permissions: ["BOOKING_SETTINGS_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["BOOKING_SETTINGS_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["BOOKING_SETTINGS_CREATE"],
                                children: [],
                            },
                            {
                                segment: "edit",
                                permissions: ["BOOKING_SETTINGS_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "view",
                                permissions: ["BOOKING_SETTINGS_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "hotel-info",
                        permissions: ["BOOKING_CHECKING_INFO_LIST"],
                        children: [
                            {
                                segment: "view",
                                permissions: ["BOOKING_CHECKING_INFO_READ"],
                                children: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        segment: "analytics",
        permissions: [],
        children: [
            {
                segment: "roomxperience",
                permissions: [],
                children: [
                    {
                        segment: "room-efficiency",
                        permissions: ["ROOM_EFFICIENCY_READ"],
                    },
                    {
                        segment: "room-maintenance",
                        permissions: ["ROOM_MAINTENANCE_READ"],
                    },
                ],
            },
            {
                segment: "hotspot",
                permissions: [],
                children: [
                    {
                        segment: "stat-sales",
                        permissions: ["STAT_SALES_READ"],
                    },
                    {
                        segment: "traffic-bi",
                        permissions: ["TRAFFIC_BI_READ"],
                    },
                    {
                        segment: "network-health",
                        permissions: ["NETWORK_HEALTH_READ"],
                    },
                    {
                        segment: "data-analytic",
                        permissions: ["DATA_ANALYTIC_READ"],
                    },
                    {
                        segment: "email-marketing",
                        permissions: ["EMAIL_MARKETING_READ"],
                    },
                    {
                        segment: "wifi-global-data",
                        permissions: ["WIFI_GLOBAL_DATA_READ"],
                    },
                ],
            },
        ],
    },
    {
        segment: "email-template",
        permissions: ["EMAIL_TEMPLATE_LIST"],
        children: [
            {
                segment: "list",
                permissions: ["EMAIL_TEMPLATE_LIST"],
                children: [],
            },
            {
                segment: "add",
                permissions: ["EMAIL_TEMPLATE_CREATE"],
                children: [],
            },
            {
                segment: "edit",
                permissions: ["EMAIL_TEMPLATE_UPDATE"],
                children: [],
            },
            {
                segment: "view",
                permissions: ["EMAIL_TEMPLATE_READ"],
                children: [],
            },
        ],
    },
    {
        segment: "faq",
        permissions: ["FAQ_ENTRY_LIST"],
        children: [
            {
                segment: "list",
                permissions: ["FAQ_ENTRY_LIST"],
                children: [],
            },
            {
                segment: "add",
                permissions: ["FAQ_ENTRY_CREATE"],
                children: [],
            },
            {
                segment: "edit",
                permissions: ["FAQ_ENTRY_UPDATE"],
                children: [],
            },
            {
                segment: "view",
                permissions: ["FAQ_ENTRY_READ"],
                children: [],
            },
        ],
    },
    {
        segment: "manual",
        permissions: ["MANUAL_LIST"],
        children: [
            {
                segment: "list",
                permissions: ["MANUAL_LIST"],
                children: [],
            },
            {
                segment: "add",
                permissions: ["MANUAL_CREATE"],
                children: [],
            },
            {
                segment: "update",
                permissions: ["MANUAL_UPDATE"],
                children: [],
            },
            {
                segment: "detail",
                permissions: ["MANUAL_READ"],
                children: [],
            },
        ],
    },
    {
        segment: "api-pricing-plan",
        permissions: ["PRICING_PLAN_LIST"],
        children: [
            {
                segment: "list",
                permissions: ["PRICING_PLAN_LIST"],
                children: [],
            },
            {
                segment: "add",
                permissions: ["PRICING_PLAN_CREATE"],
                children: [],
            },
            {
                segment: "edit",
                permissions: ["PRICING_PLAN_UPDATE"],
                children: [],
            },
            {
                segment: "view",
                permissions: ["PRICING_PLAN_READ"],
                children: [],
            },
        ],
    },
    {
        segment: "pms",
        permissions: [],
        children: [
            {
                segment: "group",
                permissions: ["PMS_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["PMS_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["PMS_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["PMS_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["PMS_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "functionality",
                permissions: ["PMS_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["PMS_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["PMS_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["PMS_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["PMS_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "bean",
                permissions: ["PMS_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["PMS_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["PMS_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["PMS_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["PMS_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "relationship",
                permissions: ["PMS_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["PMS_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["PMS_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["PMS_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["PMS_READ"],
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        segment: "updater",
        permissions: [],
        children: [
            {
                segment: "module-version",
                permissions: ["UPDATER_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["UPDATER_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["UPDATER_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["UPDATER_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["UPDATER_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "git",
                permissions: ["UPDATER_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["UPDATER_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["UPDATER_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["UPDATER_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["UPDATER_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "configuration-hotel",
                permissions: ["UPDATER_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["UPDATER_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["UPDATER_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["UPDATER_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["UPDATER_READ"],
                        children: [],
                    },
                    {
                        segment: "info",
                        permissions: ["UPDATER_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "executions",
                permissions: ["UPDATER_LIST"],
                children: [],
            },
            {
                segment: "server",
                permissions: ["UPDATER_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["UPDATER_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["UPDATER_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["UPDATER_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["UPDATER_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "checker",
                permissions: ["UPDATER_READ"],
                children: [],
            },
            {
                segment: "files",
                permissions: ["UPDATER_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["UPDATER_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["UPDATER_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["UPDATER_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["UPDATER_READ"],
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        segment: "utils",
        permissions: [],
        children: [
            {
                segment: "mail",
                permissions: ["CONFIG_EMAIL_READ"],
                children: [],
            },
            {
                segment: "module-info",
                permissions: ["MODULE_INFO_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["MODULE_INFO_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["MODULE_INFO_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["MODULE_INFO_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["MODULE_INFO_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "permissions",
                permissions: ["PERMISSIONS_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["PERMISSIONS_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["PERMISSIONS_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["PERMISSIONS_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["PERMISSIONS_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "changelog",
                permissions: ["CHANGELOG_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["CHANGELOG_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["CHANGELOG_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["CHANGELOG_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["CHANGELOG_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "country",
                permissions: ["COUNTRY_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["COUNTRY_LIST"],
                        children: [],
                    },
                    {
                        segment: "add",
                        permissions: ["COUNTRY_CREATE"],
                        children: [],
                    },
                    {
                        segment: "update",
                        permissions: ["COUNTRY_UPDATE"],
                        children: [],
                    },
                    {
                        segment: "detail",
                        permissions: ["COUNTRY_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "mac-info",
                permissions: ["MAC_CHECKER_READ"],
                children: [],
            },
            {
                segment: "logging",
                permissions: ["LOGGING_LIST"],
                children: [],
            },
            {
                segment: "hw-monitor",
                permissions: [],
                children: [
                    {
                        segment: "parameters",
                        permissions: ["HW_MONITOR_CHECKER_LIST"],
                        children: [
                            {
                                segment: "list",
                                permissions: ["HW_MONITOR_CHECKER_LIST"],
                                children: [],
                            },
                            {
                                segment: "add",
                                permissions: ["HW_MONITOR_CHECKER_CREATE"],
                                children: [],
                            },
                            {
                                segment: "update",
                                permissions: ["HW_MONITOR_CHECKER_UPDATE"],
                                children: [],
                            },
                            {
                                segment: "detail",
                                permissions: ["HW_MONITOR_CHECKER_READ"],
                                children: [],
                            },
                        ],
                    },
                    {
                        segment: "checker",
                        permissions: ["HW_MONITOR_CHECKER_READ"],
                    },
                ],
            },
            {
                segment: "system-audit",
                permissions: ["SYSTEM_AUDIT_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["SYSTEM_AUDIT_LIST"],
                        children: [],
                    },
                    {
                        segment: "view",
                        permissions: ["SYSTEM_AUDIT_READ"],
                        children: [],
                    },
                ],
            },
            {
                segment: "alert",
                permissions: ["ALERT_LIST"],
                children: [
                    {
                        segment: "list",
                        permissions: ["ALERT_LIST"],
                        children: [],
                    },
                ],
            },
        ],
    },
];
