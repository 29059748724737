<div mat-dialog-title>
    <h2 class="title-text">{{ title | transloco }}</h2>

    <button mat-icon-button mat-dialog-close>
        <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div>
        <div class="flex items-center space-x-6 pt-3">
            <label class="block">
                <span class="sr-only">Choose profile photo</span>
                <input type="file" class="upload-btn" (change)="fileChangeEvent($event)" />
            </label>
        </div>

        <div class="flex flex-row gap-x-2 mt-4">
            <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [containWithinAspectRatio]="containWithinAspectRatio"
                [resizeToWidth]="256"
                [cropperMinWidth]="128"
                [onlyScaleDown]="true"
                [roundCropper]="true"
                [canvasRotation]="canvasRotation"
                [transform]="transform"
                [alignImage]="'left'"
                [style.display]="showCropper ? null : 'none'"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady($event)"
                (loadImageFailed)="loadImageFailed()"
            ></image-cropper>

            <div class="flex flex-col items-center" *ngIf="showCropper">
                <div class="flex flex-row gap-x-2 bg-gray-100 rounded mb-4">
                    <button
                        mat-icon-button
                        matTooltip="{{ 'module.user.rotateLeft' | transloco }}"
                        (click)="rotateLeft()"
                    >
                        <mat-icon class="text-secondary" svgIcon="mat_solid:rotate_left"></mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        matTooltip="{{ 'module.user.rotateRight' | transloco }}"
                        (click)="rotateRight()"
                    >
                        <mat-icon class="text-secondary" svgIcon="mat_solid:rotate_right"></mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        matTooltip="{{ 'module.user.flipHorizontal' | transloco }}"
                        (click)="flipHorizontal()"
                    >
                        <mat-icon class="text-secondary" svgIcon="mat_solid:flip"></mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        matTooltip="{{ 'module.user.flipVertical' | transloco }}"
                        (click)="flipVertical()"
                    >
                        <mat-icon
                            class="text-secondary"
                            svgIcon="mat_solid:flip"
                            style="transform: rotate(90deg)"
                        ></mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="{{ 'module.user.zoomIn' | transloco }}" (click)="zoomIn()">
                        <mat-icon class="text-secondary" svgIcon="mat_solid:zoom_in"></mat-icon>
                    </button>

                    <button mat-icon-button matTooltip="{{ 'module.user.zoomOut' | transloco }}" (click)="zoomOut()">
                        <mat-icon class="text-secondary" svgIcon="mat_solid:zoom_out"></mat-icon>
                    </button>
                </div>
                <img [src]="croppedImage" class="h-60 w-60 object-cover rounded-full" />
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-raised-button [mat-dialog-close]="true">
        {{ "dialog.close" | transloco }}
    </button>

    <button mat-raised-button color="primary" type="button" (click)="save()" [disabled]="!croppedImage">
        {{ "dialog.save" | transloco }}
    </button>
</mat-dialog-actions>
