export class FileElement {
    idEntity: number;
    idHotel: number;
    idBrand: number;
    folder: boolean;
    name: string;
    relativePath: string;
    mimeType: string;
    generatePath: string;
    urlEdited: boolean;
}
