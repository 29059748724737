import { StateConstants } from "../constants/state-constants";
import { ToastrService } from "ngx-toastr";
import { TranslocoService } from "@ngneat/transloco";
import { ResponseForm } from "../models/response-form.model";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class ResponseUtils {
    constructor(private toastrService: ToastrService, private translocoService: TranslocoService) {}

    handleReponse(responseForm: ResponseForm<any>, successMsg: string, errorMsg?: string): void {
        if (this.isSuccess(responseForm)) {
            this.handleReponseOnlySuccess(responseForm, successMsg);
        } else if (this.isError(responseForm)) {
            this.handleReponseOnlyError(responseForm, errorMsg);
        }
    }

    handleReponseOnlySuccess(responseForm: ResponseForm<any>, successMsg: string): void {
        if (responseForm?.state === StateConstants.SUCCESS) {
            this.toastrService.success(this.translocoService.translate(successMsg));
        }
    }

    handleReponseOnlyError(responseForm: ResponseForm<any>, errorMsg?: string): void {
        if (responseForm?.state === StateConstants.ERROR) {
            if (errorMsg) {
                errorMsg = this.translocoService.translate(errorMsg);
            }
            this.toastrService.error(errorMsg ?? responseForm.errorMessage);
        }
    }

    isSuccess(responseForm: ResponseForm<any>): boolean {
        let isSuccess = false;
        if (responseForm?.state === StateConstants.SUCCESS) {
            isSuccess = true;
        }
        return isSuccess;
    }

    isError(responseForm: ResponseForm<any>): boolean {
        let isError = false;
        if (responseForm?.state === StateConstants.ERROR) {
            isError = true;
        }
        return isError;
    }
}
