import { Directive, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Hotel } from "app/core/models/hotel.model";
import { InstanceActive } from "app/core/models/instance-active.model";
import { InstanceActiveService } from "app/core/services/instance-active.service";

@UntilDestroy()
@Directive({
    selector: "[isHotelActive]",
})
export class IsHotelActiveDirective implements OnInit {
    constructor(
        private instanceActiveService: InstanceActiveService,
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>
    ) {
        this.execute(this.instanceActiveService.instanceActive.hotelActive);
    }

    ngOnInit(): void {
        this.instanceActiveService.instanceActiveChanges$
            .pipe(untilDestroyed(this))
            .subscribe((instanceActive: InstanceActive) => {
                this.execute(instanceActive.hotelActive);
            });
    }

    private execute(hotel: Hotel): void {
        this.viewContainerRef.clear();
        if (hotel && hotel.id) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}
