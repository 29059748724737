import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ConfigurationService } from "app/core/config/configuration.service";
import { GrafanaService } from "app/core/services/grafana.service";
import { InstanceActiveService } from "app/core/services/instance-active.service";
import { NavigationService } from "app/core/services/navigation.service";
import { StorageService } from "app/core/services/storage.service";
import { KeycloakService } from "keycloak-angular";
import { firstValueFrom } from "rxjs";
import cookie from "cookiejs";

@UntilDestroy()
@Component({
    selector: "logout",
    templateUrl: "./logout.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {
    countdown: number = 5;
    countdownMapping: any = {
        "=1": "# second",
        other: "# seconds",
    };

    /**
     * Constructor
     */
    constructor(
        private keycloakService: KeycloakService,
        private _router: Router,
        private _storageService: StorageService,
        private _navigationService: NavigationService,
        private _instanceActiveService: InstanceActiveService,
        private configService: ConfigurationService,
        private grafanaService: GrafanaService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        // Sign out

        this._storageService.clear();
        this._navigationService.clearCache();
        this._instanceActiveService.clear();

        await firstValueFrom(this.grafanaService.logout()).catch(err => {
            console.log(err);
        });
        await this.keycloakService.logout(this.getCurrentUrlBase());
        cookie.remove("grafana_session");

        // Redirect after the countdown
        /*timer(1000, 1000)
            .pipe(
                finalize(() => {
                    this._router.navigateByUrl("/home");
                }),
                takeWhile(() => this.countdown > 0),
                untilDestroyed(this),
                tap(() => this.countdown--)
            )
            .subscribe();*/
    }

    private getCurrentUrlBase(): string {
        let url = location.protocol + "//" + location.host;
        if (location.port) {
            url = url + ":" + location.port;
        }
        return url;
    }
}
