import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
    selector: "[appDynamicMac]",
})
export class DynamicMacDirective {
    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

    @Input() set appDynamicMac(mac: any) {
        if (mac && mac.length > 16 && new RegExp("^.[AaEe62]:.*$").test(mac)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
