import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject, take } from "rxjs";

@UntilDestroy()
@Component({
    selector: "generic-search",
    templateUrl: "./generic-search.component.html",
    styleUrls: ["./generic-search.component.scss"],
})
export class GenericSearchComponent implements OnInit {
    @Input() public value: Subject<string>;
    @Output() public enterSearch = new EventEmitter<string>();

    data: string = "";

    /**
     * Constructor
     */
    constructor() {}

    ngOnInit(): void {
        this.value.pipe(untilDestroyed(this)).subscribe(data => {
            this.data = data;
        });
    }

    search(): void {
        this.enterSearch.emit(this.data);
    }
}
