import { HttpBackend, HttpClient, HttpInterceptor } from "@angular/common/http";
import { Injectable, InjectionToken, Injector, Provider } from "@angular/core";
import { FuseLoadingInterceptor } from "@fuse/services/loading/loading.interceptor";
import { KeycloakBearerInterceptor } from "keycloak-angular";
import { AuthMasterInterceptor } from "../interceptors/auth-master.interceptor";
import { HandlerService } from "./http-handler";

const HTTP_INTERCEPTORS_MASTER = new InjectionToken<HttpInterceptor[]>("HTTP_INTERCEPTORS_MASTER");

@Injectable({
    providedIn: "root",
})
export class HttpClientMaster extends HttpClient {
    constructor(backend: HttpBackend, private injector: Injector) {
        super(new HandlerService(backend, injector, HTTP_INTERCEPTORS_MASTER));
    }

    static getInterceptorProviders(): Provider[] {
        return [
            {
                provide: HTTP_INTERCEPTORS_MASTER,
                useClass: KeycloakBearerInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS_MASTER,
                useClass: AuthMasterInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS_MASTER,
                useClass: FuseLoadingInterceptor,
                multi: true,
            },
        ];
    }
}
