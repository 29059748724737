import { Pipe, PipeTransform } from "@angular/core";
import {
    faAndroid,
    faApple,
    faChrome,
    faEdge,
    faFacebook,
    faFirefoxBrowser,
    faGoogle,
    faInternetExplorer,
    faLinux,
    faOpera,
    faPlaystation,
    faSafari,
    faWindows,
    IconDefinition,
} from "@fortawesome/free-brands-svg-icons";
import { faExclamationTriangle, faLink, faQuestionCircle, faUnlink } from "@fortawesome/free-solid-svg-icons";

@Pipe({
    name: "deviceInfo",
})
export class DeviceInfoPipe implements PipeTransform {
    transform(value: string): IconDefinition {
        return this.getIcon(value);
    }

    getIcon(value: string): IconDefinition {
        // default unknown faQuestionCircle
        let icon: IconDefinition = faQuestionCircle;
        if (value !== null) {
            value = value.toLocaleLowerCase();
            switch (true) {
                // Operating System
                case value.indexOf("android") !== -1:
                    icon = faAndroid;
                    break;
                case value.indexOf("ios") !== -1 ||
                    value.indexOf("macos") !== -1 ||
                    value.indexOf("ipados") !== -1 ||
                    value.indexOf("applemail") !== -1:
                    icon = faApple;
                    break;
                case value.indexOf("win") !== -1:
                    icon = faWindows;
                    break;
                case value.indexOf("linux") !== -1:
                    icon = faLinux;
                    break;
                // Web Browser
                case value.indexOf("safari") !== -1:
                    icon = faSafari;
                    break;
                case value.indexOf("chrome") !== -1:
                    icon = faChrome;
                    break;
                case value.indexOf("explorer") !== -1 || value.indexOf("ie") !== -1:
                    icon = faInternetExplorer;
                    break;
                case value.indexOf("firefox") !== -1:
                    icon = faFirefoxBrowser;
                    break;
                case value.indexOf("edge") !== -1:
                    icon = faEdge;
                    break;
                case value.indexOf("google") !== -1:
                    icon = faGoogle;
                    break;
                case value.indexOf("playstation") !== -1:
                    icon = faPlaystation;
                    break;
                case value.indexOf("opera") !== -1:
                    icon = faOpera;
                    break;
                case value.indexOf("facebook") !== -1:
                    icon = faFacebook;
                    break;
                // States
                case value.indexOf("connected") !== -1:
                    icon = faLink;
                    break;
                case value.indexOf("finished") !== -1:
                    icon = faUnlink;
                    break;
                case value.indexOf("error") !== -1:
                    icon = faExclamationTriangle;
                    break;
            }
        }
        return icon;
    }
}
