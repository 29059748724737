<div class="w-full generic-search-input-container">
    <input
        matInput
        placeholder="{{ 'module.common.search' | transloco }}"
        [(ngModel)]="data"
        name="value"
        #value="ngModel"
        type="text"
        class="generic-search-input w-full"
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        (keydown.enter)="search()"
    />
</div>
