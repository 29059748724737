import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UserDetailsService } from "app/core/services/user-details.service";

@UntilDestroy()
@Directive({
    selector: "[hasAnyPermission]",
})
export class HasAnyPermissionDirective implements OnInit {
    private permissions: string[] = [];

    constructor(
        private userDetailsService: UserDetailsService,
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>
    ) {}

    @Input()
    set hasAnyPermission(permissions: string | string[]) {
        this.permissions = this.permissions.concat(permissions);
    }

    ngOnInit(): void {
        this.userDetailsService.user$.pipe(untilDestroyed(this)).subscribe(() => {
            this.execute();
        });
    }

    private execute(): void {
        if (this.userDetailsService.hasAnyPermission(...this.permissions)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }
}
